import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../assets/icons/icon-back.svg'
import styles from './style.module.css'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => { }, []);
  const [credentials, setCredentials] = useState({ name: "",quantity:"" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/inventory/createInventory`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          quantity:credentials.quantity,
          site:isAuthenticated.site,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      setCredentials({
        name: '',
        quantity:''
      });
      notification.success({
        message: 'Success',
        description: 'Product Created Successfully',
        duration: 3
      });
    } else {
      notification.error({
        message: 'Failed',
        description: json.error,
        duration: 3
      });
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/product/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Create Product</h3>
                  <p>
                    Enter the credentials to add product to the
                    system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter the precise  name of product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        name="name"
                        placeholder="Enter Name.."
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
               <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Quantity</h4>
                      <p>Enter the quantity of product</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="quantity"
                        name="quantity"
                        placeholder="Enter Quantity.."
                        value={credentials.quantity}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
