import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { notification } from "antd";
import { Button, Popconfirm, message } from "antd";
import styles from "./style.module.css";
import SearchIcon from "../../../images/search.png";
import FilterIcon from "../../../images/filter.png";
import InputMask from "react-input-mask";
import HashLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import Images from "../modal/Images";
import RemoveImg from "../../../images/remove.png";
import UploadIcon from "../../../images/icon-upload.png";
import scandetail from "../../../images/scan-detailss.png";
const EditContainer = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [imageField, setimageField] = useState(false);
  const [images, setImages] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [credentials, setCredentials] = useState({
    _id: "",
    details: "",
    images: "",
  });
  const [phone, setPhone] = useState("");
  // const handleInput = (e) => {
  //   setCredentials({ ...credentials, [e.target.name]: e.target.value });
  // };
  const handleFileChange = (index, image) => {
    // const selectedImages = Array.from(image);
    const updatedTextAreas = [...images];
    updatedTextAreas[index] = image;
    setImages(updatedTextAreas);
    // setImages([...images, ...selectedImages]);
  };
  const removeImage = (indexToRemove) => {
    const updatedImages = images.filter((_, index) => index !== indexToRemove);
    setImages(updatedImages);
  };
  const [searchStatus, setSearchStatus] = useState("all");
  const [filters, setFilters] = useState({ status: "all", date: "", name: "" });
  const [textAreas, setTextAreas] = useState([]);
  const handleInput = (index, value) => {
    const updatedTextAreas = [...textAreas];
    updatedTextAreas[index] = value;
    setTextAreas(updatedTextAreas);
  };
  const addPic = () => {
    setimageField(true);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/container/getDropOffBox/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.containers.length > 0) {
        setData(json.containers);
        setTextAreas(json.containers.map((item) => item.details));
      }
      if (json.firstContainer) {
        console.log(json.firstContainer);
        setCredentials(json.firstContainer);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const submitData = async (key, value) => {
    // let headers = new Headers();
    // headers.append("Content-Type", "application/json");
    // headers.append("Access-Control-Allow-Origin", "*");
    // headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    // Create a new FormData object to send both form data and images
    const formData = new FormData();
    formData.append("id", value);
    formData.append("details", textAreas[key]);
    if (images[key]) {
      formData.append(`file`, images[key]);
    }
    // // Append images to the FormData object
    // images.forEach((image, index) => {
    //   formData.append(`file`, image);
    // });
    const response = await fetch(`${url}/api/container/updateContainerItem`, {
      method: "POST",
      body: formData,
    });
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
      fetchData();
      setimageField(false);
      notification.success({
        message: "Success",
        description: json.message,
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: "failed to update container item",
        duration: 3,
      });
    }
  };
  // update data
  // const submitData= async (key,value)=>{
  //   console.log(key+' value '+textAreas[key]);
  // }
  const iocnStyle = {
    display: isSubmit ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.body_wrap}>
      <div className="container-detail-body">
        <div className="row">
          <div className="col-md-7">
            <div className="dropbox-wrap-body">
              <div className="dropbox-wrap">
                {data.length > 0 &&
                  data.map((item, key) => (
                    <>
                      <div
                        className="accordion mt-3"
                        id={`accordionExample${item._id}`}
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className={`accordion-button ${isCollapsed ? "" : "active"
                                }`}
                              style={{
                                color: "#fff",
                                backgroundColor: "#012269",
                              }}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${item._id}`}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {item.unique_number}
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${item._id}`}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="accordion-details">
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="form-floating">
                                      <textarea
                                        className="form-control"
                                        placeholder=" Write Your Message..."
                                        id="floatingTextarea2"
                                        style={{ height: 300 }}
                                        value={textAreas[key]}
                                        onChange={(e) => {
                                          handleInput(
                                            key,
                                            e.target.value
                                          );
                                        }}
                                      />
                                      <label htmlFor="floatingTextarea2">
                                        Write Your Item Details...
                                      </label>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-12">
                                        <div>
                                          {(!item.images || imageField) && (
                                            <input
                                              className="form-control"
                                              type="file"
                                              id="formFileMultiple"
                                              onChange={(e) => {
                                                handleFileChange(key, e.target.files[0]);
                                              }}
                                              required
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-4">
                                        {item.images && (
                                          <button
                                            onClick={addPic}
                                            className="btn btn-prim">
                                            Add Pic
                                          </button>
                                        )}
                                      </div>
                                      <div className="col-md-8">
                                        <button
                                          className="btn btn-prim"
                                          onClick={() =>
                                            submitData(
                                              key,
                                              item._id
                                            )
                                          }
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="scan-selected-imges">
                                      {item.images ? (
                                        <img
                                          src={`${item.images}`}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          src={`/uploads/container.jpg`}
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="scan-detail-image">
              <img src={scandetail} alt="scandetail" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditContainer;
