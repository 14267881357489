import React, { useEffect, useState } from "react";
import styles from './style.module.css';
import pickupImg from '../../../images/pickupCustomer.png';
import Complete from '../../../images/complete.svg'
import CalcLeft from '../../../images/calc-left.png'
import CalDel from '../../../images/cal-del.png'
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button, Popconfirm, message } from 'antd';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
const PickupOrder = () => {
    let navigate = useNavigate();
    const url = process.env.REACT_APP_APP_BACK_URL;
    const isAuthenticated = useSelector((state) => state.user);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [code, setCode] = useState('');
    // Function to handle digit button click
    const handleDigitClick = (digit) => {
        setCode((prevCode) => prevCode + digit);
    };
    // Function to handle the delete button click
    const handleDeleteDigit = () => {
        setCode((prevCode) => prevCode.slice(0, -1));
    };
    const HandleSelectedOrderNumber = async () => {
        if (code === '') {
            notification.error({
                message: 'Failed',
                description: 'Please enter your pickup code',
                duration: 3
            });
            return;
        }
        formSubmit();
    };
    const formSubmit = async () => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        setIsFormSubmit(true);
        const response = await fetch(
            `${url}/api/pickup/code/${code}`,
            {
                mode: "cors",
                method: "GET",
                headers: headers,
            }
        );
        const json = await response.json();
        console.log(json);
        setIsFormSubmit(false);
        if (json.success) {
            navigate(`/pickup/order/${json.pickup._id}`);
        } else {
            notification.error({
                message: 'Failed',
                description: json.error,
                duration: 3
            });
        }
    };
    const [isActive, setIsActive] = useState(false);
    const toggleActive = () => {
        setIsActive(!isActive);
    };
    // Define the CSS classes based on the isActive state
    const divClassName = isActive ? 'sidebar active' : 'sidebar';
    return (
        <div className="general-dashboard">
            <div className="container-fluid">
                <div className="dashboard-top-row">
                    <div className="row">
                        <div className="col-lg-4 my-auto">
                            <div className="user-wrap">
                                <h3>Pickup Order</h3>
                                <p>
                                    Enter your pickup code for your pickup
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 text-center">
                        </div>
                    </div>
                </div>
                <div className="calc-wrap-main">
                    <div className="row text-center">
                        <div className="col-lg-6 my-auto">
                            <img src={CalcLeft} alt="" />
                        </div>
                        <div className="col-lg-6 my-auto">
                            <div className="calc-wrap">
                                <div className="cal-screen">
                                    <input type="text" name="" id="result" value={code} readOnly placeholder="enter pickup code" />
                                </div>
                                <div className="keys-wrap">
                                    <div className="row ">
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('1')}>
                                                <i>1</i>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('2')}>
                                                <i>2</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('3')}>
                                                <i>3</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('4')}>
                                                <i>4</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('5')}>
                                                <i>5</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('6')}>
                                                <i>6</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('7')}>
                                                <i>7</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('8')}>
                                                <i>8</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('9')}>
                                                <i>9</i> <span>ABC</span>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <div className="empty-div-cal"></div>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-key" onClick={() => handleDigitClick('0')}>
                                                <i>0</i>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button className="btn-delt" onClick={handleDeleteDigit}>
                                                <img src={CalDel} alt="" />{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="cont-btn-wrap">
                                    <button className="continue-btn" onClick={HandleSelectedOrderNumber} disabled={isFormSubmit}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PickupOrder;
