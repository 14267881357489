import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import SearchIcon from "../../../images/search.png";
import FilterIcon from "../../../images/filter.png";
import pickupImg from "../../../images/locker_detail.png";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Button, Popconfirm, message } from "antd";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
import ContentLoader from "react-content-loader";
import BackIcon from '../../../assets/icons/icon-back.svg'
const BookingDetails = () => {
  let navigate = useNavigate();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisplay, setIsDisplay] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData()
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(
        `${url}/api/booking/getBookingDetails/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.success) {
        setIsDisplay(true);
        setIsNotFound(false);
        setCredentials({ ...json.booking });
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const HandleOpenLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    const response = await fetch(`${url}/api/locker/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });
    setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      notification.success({
        message: "Success",
        description: json.message,
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  //   const HandleOpenDoor = async (id) => {
  //     let state = "2";
  //     let headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Access-Control-Allow-Origin", "*");
  //     headers.append("Access-Control-Allow-Credentials", "true");
  //     setDoorOpen(true);
  //     const response = await fetch(`${url}/api/lockers/open`, {
  //       mode: "cors",
  //       method: "POST",
  //       headers: headers,
  //       body: JSON.stringify({
  //         id: id,
  //         state: state,
  //       }),
  //     });
  //     setDoorOpen(false);
  //     const json = await response.json();
  //     if (json.success) {
  //       notification.success({
  //         message: "Success",
  //         description: json.message,
  //         duration: 3,
  //       });
  //     } else {
  //       setDoorOpen(false);
  //       notification.error({
  //         message: "Failed",
  //         description: json.error,
  //         duration: 3,
  //       });
  //     }
  //   };
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3> Booking Detail</h3>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="row">
          <div className="col-lg-5 text-start">
            {isLoading && (
              <div className="col-12 px-4">
                <ContentLoader
                  viewBox="0 0 400 160"
                  height={160}
                  width={400}
                  backgroundColor="#142E98"
                >
                  <circle cx="150" cy="86" r="8" />
                  <circle cx="194" cy="86" r="8" />
                  <circle cx="238" cy="86" r="8" />
                </ContentLoader>
              </div>
            )}
            {isDisplay && (
              <div
                className="form-box card-box"
                style={{ padding: "0px" }}
              >
                <div className={` ${styles.pickup_order_heading2}`}>
                  <span className={styles.pickup_label2}>
                    Drop Off Booking
                  </span>
                </div>
                <div className="p-4">
                  <div className="table-responsive">
                    <table className="table table-sm table-striped">
                      <tbody>
                        <tr>
                          <td className={styles.locker_label}>
                            Booking Type #
                          </td>
                          <th className={styles.locker_label_value}>
                            {credentials.duration}
                          </th>
                        </tr>
                        <tr>
                          <td className={styles.locker_label}>
                            From
                          </td>
                          <th className={styles.locker_label_value}>
                            <badge className="badge bg-success">
                              {credentials.from}
                            </badge>
                          </th>
                        </tr>
                        <tr>
                          <td className={styles.locker_label}>
                            To
                          </td>
                          <th className={styles.locker_label_value}>
                            <badge className="badge bg-warning">
                              {credentials.to}
                            </badge>
                          </th>
                        </tr>
                        <tr>
                          <td className={styles.locker_label}>
                            Booked By
                          </td>
                          <th className={styles.locker_label_value}>
                            {credentials.user_name}
                          </th>
                        </tr>
                        <tr>
                          <td className={styles.locker_label}>
                            Payment Details #
                          </td>
                          <th className={styles.locker_label_value}>
                            ${credentials.amount}
                          </th>
                        </tr>
                        <tr>
                          <td className="text-end">
                            {" "}
                            <a href={`${credentials.receipt_url}`}>
                              View Receipt{" "}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <Link
                              className="btn btn-danger btn-sm m-1"
                              onClick={() => {
                                HandleOpenLocker(
                                  credentials.locker_id
                                );
                              }}
                              disabled={isLockerOpen}
                            >
                              {" "}
                              {isLockerOpen
                                ? "Please wait..."
                                : `Open Locker `}
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {isNotFound && (
              <div className="form-box card-box text-center mt-3 mb-3">
                <h6 className="text-danger">Sorry!</h6>
                <h6>Something went wrong</h6>
                <p>Locker Not Found</p>
              </div>
            )}
          </div>
          <div className="col-lg-7 text-end">
            <img
              src={pickupImg}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingDetails;
