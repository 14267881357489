import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import pickupImg from "../../../images/pickupCustomer.png";
import Complete from "../../../images/complete.svg";
import CalcLeft from "../../../images/calc-left.png";
import CalDel from "../../../images/cal-del.png";
import LoginImg from "../../../images/login-img.png";
import finishImg from "../../../images/pickup-finish.png";
import completeImg from "../../../images/complete.png";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Popconfirm, message } from "antd";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
import InputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/Authentication";
import dropoff5 from "../../../images/dropoff5.png";
import BackIcon from '../../../assets/icons/icon-back.svg'
const Booking = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const react_base_url = process.env.REACT_APP_BASE_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const id = isAuthenticated._id;
  const [sizes, setSizes] = useState([]);
  const [lockers, setLockers] = useState([]);
  const [orderStep, setOrderStep] = useState(true);
  const [sizeStep, setSizeStep] = useState(true);
  const [lockerStep, setLockerStep] = useState(false);
  const [paymentStep, setpaymentStep] = useState(false);
  const [amount, setAmount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [registerStep, setRegisterStep] = useState(false);
  const [finishStep, setFinishStep] = useState(false);
  const [isSizeSelected, setIsSizeSelected] = useState("");
  const [sizeLoader, setSizeLoader] = useState(true);
  const [isSizeDisplay, setIsSizeDisplay] = useState(false);
  const [sizeNotFound, setSizeNotFound] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isLoginSubmit, setIsLoginSubmit] = useState(false);
  const [isRegSubmit, setIsRegSubmit] = useState(false);
  const [firstStep, setFirstStep] = useState(true);
  const [secStep, setSecStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [fourStep, setFourStep] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLogin, setIsLogin] = useState("no");

  const [code, setCode] = useState("");
  const [site, setSite] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");

  const [credentials, setCredentials] = useState({
    size_id: "",
    locker_id: "",
    duration: "",
  });

  // Function to handle digit button click
  const handleDigitClick = (digit) => {
    setCode((prevCode) => prevCode + digit);
  };

  // Function to handle the delete button click
  const handleDeleteDigit = () => {
    setCode((prevCode) => prevCode.slice(0, -1));
  };

  useEffect(() => {
    sizeData();
  }, []);

  const sizeData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setSizeLoader(true);
      setIsSizeDisplay(false);
      setSizeNotFound(false);
      const response = await fetch(`${url}/api/locker/locker/size/available`, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setIsLogin(json.loginUser);
        setSite(json.site);
        if (json.data.length > 0) {
          setSizes(json.data);
          setSizeLoader(false);
          setIsSizeDisplay(true);
          setSizeNotFound(false);
          setFinishStep(false);
        } else {
          setSizeLoader(false);
          setIsSizeDisplay(false);
          setSizeNotFound(true);
        }
      } else {
        setSizeLoader(false);
        setIsSizeDisplay(false);
        setSizeNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleSelectedSize = async (size_id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsSizeSelected(size_id);

      const response = await fetch(`${url}/api/locker/locker/available`, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify({
          size_id: size_id,
          id: id,
        }),
        headers: headers,
      });
      const json = await response.json();
      setIsSizeSelected(false);

      if (json.success) {
        setCredentials((prevCredentials) => ({
          ...prevCredentials,
          size_id: size_id,
        }));

        if (json.lockers && json.lockers._id) {
          setCredentials((prevCredentials) => ({
            ...prevCredentials,
            locker_id: json.lockers._id,
          }));
          setLockers(json.lockers);
          setSizeStep(false);
          setLockerStep(true);
          setpaymentStep(false);
          setFinishStep(false);

          setFirstStep(true);
          setSecStep(true);
          setThirdStep(true);
          setFourStep(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleSelectedDuration = async (value) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));

      const response = await fetch(`${url}/api/locker/pricing`, {
        mode: "cors",
        method: "POST",
        body: JSON.stringify({
          size_id: credentials.size_id,
          duration: value,
        }),
        headers: headers,
      });
      const json = await response.json();
      setIsSizeSelected(false);

      if (json.success) {
        setCredentials((prevCredentials) => ({
          ...prevCredentials,
          duration: value,
        }));
        if (json.amount) {
          setAmount(json.amount);
          setSizeStep(false);
          setLockerStep(false);
          setpaymentStep(true);
          setFinishStep(false);

          setFirstStep(true);
          setSecStep(true);
          setThirdStep(true);
          setFourStep(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleSizeBck = async (locker_id) => {
    setSizeStep(true);
    setLockerStep(false);

    setFirstStep(true);
    setSecStep(false);
    setThirdStep(false);
    setFourStep(false);
  };

  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to include it in your HTML file.
      return;
    }

    // Create a token or handle payment using the PaymentMethod.
    const result = await stripe.createToken(elements.getElement(CardElement));
    payment(result.token);
    if (result.error) {
      setError(result.error.message);
    } else {
      // Send the token to your server or handle it as needed.
      // You can call a function like stripeTokenHandler(result.token);
    }
  };
  const payment = async (token) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/booking/payment/create`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        stripeToken: token.id,
        locker_size_id: credentials.size_id,
        total_amount: amount,
        duration: credentials.duration,
        id: id,
      }),
    });
    const json = await response.json();
    if (json.success) {
      setIsLoading(false);
      navigate(`/booking/details/${json.booking}`);
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  return (
    <div className="col col-12">
      <div className="general-dashboard m-4">
        <div className="container-fluid">
          <div className="dashboard-top-row">
            <div className="row">
              <div className="col-lg-4 col-sm-12 my-auto">
                <div className="user-wrap">
                  <h3>Book A Locker</h3>
                  <p>Book your locker here</p>
                </div>
              </div>
            </div>
          </div>

          {sizeStep && (
            <div className="calc-wrap-main">
              <div className="form-box card-box">
                <h4 className="fw-bold">
                  SELECT SIZE FROM AVAILABLE LOCKER
                </h4>
                <div className="row mt-3">
                  {sizeLoader && (
                    <>
                      <div className="col-12 text-center">
                        <HashLoader
                          color={`#0b0d41`}
                          loading={sizeLoader}
                          size={40}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </>
                  )}
                  {isSizeDisplay &&
                    sizes.map((item, i) => (
                      <div className="col-lg-3 col-md-3 col-sm-6">
                        <div
                          className="card"
                          style={{
                            background: "#EFF1FF",
                            borderRadius: "18px",
                            borderColor: "#EFF1FF",
                          }}
                        >
                          <div className="card-body text-center">
                            <h6>{item.size}</h6>
                            <p>
                              {item.available_lockers} Available
                            </p>
                            <button
                              type="submit"
                              className="btn btn-prim mt-2"
                              disabled={
                                item.available_lockers > 0 ||
                                  isSizeSelected
                                  ? ""
                                  : "disabled"
                              }
                              onClick={() => {
                                HandleSelectedSize(item._id);
                              }}
                            >
                              SELECT
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          {lockerStep && (
            <div className="calc-wrap-main">
              <div className="form-box card-box">
                <h4 className="fw-bold">SELECT DURATION</h4>
                <div className="row mt-3">
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div
                      className="card"
                      style={{
                        background: "#EFF1FF",
                        borderRadius: "18px",
                        borderColor: "#EFF1FF",
                      }}
                    >
                      <div className="card-body text-center">
                        <h6>Hourly</h6>

                        <button
                          type="submit"
                          className="btn btn-prim mt-2"
                          onClick={() => {
                            HandleSelectedDuration("hourly");
                          }}
                        >
                          SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div
                      className="card"
                      style={{
                        background: "#EFF1FF",
                        borderRadius: "18px",
                        borderColor: "#EFF1FF",
                      }}
                    >
                      <div className="card-body text-center">
                        <h6>Daily</h6>

                        <button
                          type="submit"
                          className="btn btn-prim mt-2"
                          onClick={() => {
                            HandleSelectedDuration("daily");
                          }}
                        >
                          SELECT
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6">
                    <div
                      className="card"
                      style={{
                        background: "#EFF1FF",
                        borderRadius: "18px",
                        borderColor: "#EFF1FF",
                      }}
                    >
                      <div className="card-body text-center">
                        <h6>Monthly</h6>

                        <button
                          type="submit"
                          className="btn btn-prim mt-2"
                          onClick={() => {
                            HandleSelectedDuration("monthly");
                          }}
                        >
                          SELECT
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 text-end mt-4">
                    <button
                      type="button"
                      className="btn btn-prim w-auto mt-2"
                      onClick={HandleSizeBck}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {paymentStep && (
            <div className="checkout-scr">
              <div className="row">
                <div className="col-md-5">
                  <div className="container-box">
                    <div className="pay-amount">
                      <h6>Amount To Pay</h6>
                      <h1>${amount}</h1>
                    </div>

                    <div className="card-info">
                      <p>Enter Your Credit Card Info To Pay</p>
                      <form
                        className="row "
                        onSubmit={handleSubmit}
                      >
                        {/* <div className="col-12">
                      <label for="inputnum4" class="form-label">
                        Card Number
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="inputnum4"
                      />
                    </div>
                    <div className="col-md-8 mt-3">
                      <label for="inputnum4" class="form-label">
                        Expire Date
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="inputnum4"
                      />
                    </div>
                    <div className="col-md-4 mt-3">
                      <label for="inputnum4" class="form-label">
                        CVV
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        id="inputnum4"
                      />
                    </div> */}
                        <CardElement
                          id="card-element"
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                        />
                        <div className="col-12 mt-3">
                          <button
                            className="btn btn-prim"
                            type="submit"
                            disabled={isLoading}
                          >
                            Book Now
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src={dropoff5} alt="Your Image" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Booking;
