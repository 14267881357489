import React, { useEffect, useState } from "react";
import styles from "./style.module.css";

import "../../../locker.css"

import dataupload from "../../../images/upload-data.png";
import dropoff1 from "../../../images/dropoff1.png";
import dropoff2 from "../../../images/dropoff2.png";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Button, Popconfirm, message } from "antd";
import HashLoader from "react-spinners/ClipLoader";
// import Images from "../modal/Images";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { notification } from "antd";
const Step1 = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(true);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [getLockerBank, setLockerBank] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  useEffect(() => {
    fetchData();
  }, []);



  // const [isActive, setIsActive] = useState(false);

  // // Function to toggle the active state
  // const toggleActive = () => {
  //   setIsActive(!isActive);
  // };

  // // Define the CSS classes based on the isActive state
  // const divClassName = isActive ? 'sidebar active' : 'sidebar';
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/l_bank/getLockerBank/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        console.log(json.locker_bank);
        setLockerBank(json.locker_bank);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleQrTypes = async (value) => {
    if (value.toLowerCase() === 'step') {
      setStep(false);
      setStep1(true);
    } else if (value.toLowerCase() === 'step1') {
      setStep1(false);
      setStep2(true);
    } else {
      const locker_bank = getLockerBank._id;
      const site = getLockerBank.site;
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/booking/drop-off/booking`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            duration: value,
            site: site,
            locker_bank: locker_bank,

          }),
        }
      );
      setIsLoading(false);
      const json = await response.json();

      if (json.success) {
        const authed = localStorage.getItem('token');
        localStorage.setItem("locker_bank", json.locker_bank);
        if (authed) {
          navigate(`/update/drop-off/${json.booking}`)
        } else {
          localStorage.setItem("booking", json.booking);
          navigate(`/login`)
        }
      } else {

        notification.error({
          message: 'Failed',
          description: json.error,
          duration: 3
        });

      }
    }

    // setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }
  return (
    <div className={styles.heading}>
      <section className={styles.sidebar_wrap}>
        <div className="body-wrap">
          <div className="container-fluid px-0">
            <div className="row">
              {/* <div className={`col col-sidebar `}>

                                <Sidebar />
                            </div> */}
              <div className="col col-dashboard">
                {/* <Header /> */}
                <div className={styles.body_wrap}>
                  <div className="row m-2"></div>

                  <div className="containerr-main">
                    <div className="container-dataa text-center">
                      {step && (
                        <>
                          <div className="image-containerr">
                            <img src={dataupload} alt="Your Image" />
                          </div>
                          <div className="button-container mb-3">
                            <button className="btn btn-prim" type="submit">
                              Rent
                            </button>
                            <button className="btn btn-prim" onClick={() => handleQrTypes('step')}>
                              Drop Off
                            </button>
                            <Link className="btn btn-prim" to={`/external/pickup/add/${id}`}>
                              Pickup Drop Off
                            </Link>
                          </div>
                        </>
                      )}
                      {step1 && (
                        <>
                          <div className="image-containerr">
                            <img src={dropoff1} alt="Your Image" />
                          </div>
                          <div className="button-container">
                            <button className="btn btn-prim">
                              Someone Else is Picking Up
                            </button>
                            <button className="btn btn-prim" onClick={() => handleQrTypes('step1')}>
                              I Want to Store This Offsite
                            </button>
                          </div>
                        </>
                      )}
                      {step2 && (
                        <>
                          <div className="image-containerr">
                            <img src={dropoff2} alt="Your Image" />
                          </div>
                          <div className="button-container mb-3">
                            <button className="btn btn-prim" onClick={() => handleQrTypes('monthly-1')}>
                              Store For 30 Days
                            </button>
                            <button className="btn btn-prim" onClick={() => handleQrTypes('monthly-2')}>
                              Store For 60 Days
                            </button>
                            <button className="btn btn-prim" onClick={() => handleQrTypes('monthly-3')}>
                              Store For 90+ Days
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Step1;
