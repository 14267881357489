import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { notification } from "antd";
import { Button, Popconfirm, message } from "antd";
import styles from "./style.module.css";
import SearchIcon from "../../../images/search.png";
import FilterIcon from "../../../images/filter.png";
import InputMask from "react-input-mask";
import HashLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import Images from "../modal/Images";
import BackIcon from '../../../assets/icons/icon-back.svg'
const Containers = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [credentials, setCredentials] = useState({
    number: "",
  });
  const [phone, setPhone] = useState("");
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 8;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchPhone, setSearchPhone] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [searchStatus, setSearchStatus] = useState("all");
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [filters, setFilters] = useState({ status: "all", date: "", name: "" });
  const handleFilter = (e) => {
    setCurrentPage(1);
    // Use the callback function to ensure that the state is updated before calling fetchData
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [e.target.name]: e.target.value,
      };
      fetchData(updatedFilters);
      return updatedFilters;
    });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsSubmit(true);
    const response = await fetch(`${url}/api/container/addContainers`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        number: credentials.number,
        locker_bank: id,
        base_url: base_url,
      }),
    });
    setIsSubmit(false);
    const json = await response.json();
    if (json.success) {
      fetchData(filters);
      setCredentials({
        number: "",
      });
      setPhone("");
      notification.success({
        message: "Success",
        description: "Container added Successfully",
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: "Failed to create appointment",
        duration: 3,
      });
    }
  };
  useEffect(() => {
    fetchData(filters);
  }, [currentPage, searchValue, searchStatus, searchPhone]);
  const fetchData = async (fetchFilters) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setNotFound(false);
      // const response = await fetch(`${url}/api/container/containerList?page=${currentPage}&limit=${itemsPerPage}&status=${fetchFilters.status}&locker_bank=${id}`,
      //   {
      //     mode: "cors",
      //     method: "GET",
      //     headers: headers,
      //   }
      // );
      const response = await fetch(`${url}/api/container/containerList?page=${currentPage}&limit=${itemsPerPage}&status=${fetchFilters.status}&number=${fetchFilters.name}&locker_bank=${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.containers.length > 0) {
        setIsDisplay(true);
      } else {
        setNotFound(true);
      }
      setData(json.containers);
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const openModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImageUrl('');
  };
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1); // Reset current page when the search value changes
  };
  const handleStatusFilterChange = (e) => {
    setSearchStatus(e.target.value);
    setCurrentPage(1); // Reset current page when the role filter changes
  };
  const handlePhoneFilterChange = (e) => {
    setSearchPhone(e.target.value);
    setCurrentPage(1); // Reset current page when the role filter changes
  };
  const handleDateFilterChange = (e) => {
    setSearchDate(e.target.value);
    setCurrentPage(1); // Reset current page when the role filter changes
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        const response = await fetch(`${url}/api/container/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });
        fetchData(); // Refresh the records list after successful deletion
        if (response.ok) {
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Appointment deleted successfully!", "success");
        } else {
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
    }
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const handleResetBtn = () => {
    const updatedFilters = {
      name: "",
      date: "all",
      status: "all",
    };
    // Update state and then call fetchData
    setFilters(updatedFilters);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedFilters);
  };
  const iocnStyle = {
    display: isSubmit ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <>
      <div className="general-dashboard">
        <div className="container-fluid">
          <div className="dashboard-top-row">
            <form onSubmit={formSubmit}>
              <div className="form-box card-box">
                <div className="row">
                  <div className="col-lg-12">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-4 col-lg-4">
                        <div className="label-wrap">
                          <h4>Container</h4>
                          <p>
                            Enter the number of container to add in
                            locker bank
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            className="form-control"
                            id="number"
                            name="number"
                            placeholder="Enter number.."
                            value={credentials.number}
                            onChange={handleInput}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                  <div className="col-lg-12 text-end">
                    <div className="form-btn-wrap ">
                      <button
                        type="submit"
                        disabled={isLoading}
                        href=""
                        className="btn btn-prim w-auto ms-5"
                      >
                        <i
                          className="fa fa-spinner fa-spin"
                          style={iocnStyle}
                        ></i>{" "}
                        Add Containers
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="general-dashboard">
        <div className="container-fluid">
          <div className="dashboard-top-row">
            <div className="row">
              <div className="col-lg-6 my-auto">
                <div className="user-wrap">
                  <h3>Containers</h3>
                  <p>List of all the container in the locker bank</p>
                </div>
              </div>
              <div className="col-lg-6 text-end">
                <div className="filter-right-sec">
                  <div className="filter-wrap">
                    <div
                      className="filter-box"
                      onClick={toggleFilterSection}
                    >
                      <img src={FilterIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isFilterVisible && (
              <>
                <motion.div
                  className={`row mt-5 filter-section`}
                  initial={{ opacity: 0 }} // Initial state (hidden)
                  animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                  transition={{ duration: 1.2, delay: 0.1 }}
                >
                  <div className="col-12">
                    <div className="filter-right-sec filter-wrap-chat">
                      <div className="filter-select d-flex align-items-center">
                        <label htmlFor="">Status</label>
                        <select
                          name="status"
                          id=""
                          value={filters.status}
                          onChange={handleFilter}
                        >
                          <option value="all">Choose Status</option>
                          <option value="available">Available</option>
                          <option value="booked">Booked</option>
                        </select>
                      </div>
                      <div className="search-wrap">
                        <input
                          type="search"
                          placeholder="number"
                          name="name"
                          value={filters.name}
                          onChange={handleFilter}
                        />
                        <div className="search-box">
                          <img src={SearchIcon} alt="" />
                        </div>
                      </div>
                      <Link
                        to=""
                        onClick={handleResetBtn}
                        className="btn btn-prim w-auto"
                      >
                        RESET
                      </Link>
                    </div>
                  </div>
                </motion.div>
              </>
            )}
          </div>
          {/* row */}
          <div className="table-wrap">
            <div className="row">
              <div className="col-12">
                <div className={styles.card_box}>
                  <div className={styles.tables_wrap}>
                    <div className="table-responsive">
                      <table className="table align-middle">
                        <thead>
                          <tr>
                            <th>Unique Number</th>
                            <th>Number</th>
                            <th>status</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading && (
                            <>
                              <tr>
                                <td
                                  colSpan={6}
                                  className="text-center"
                                  style={{
                                    background: "transparent",
                                  }}
                                >
                                  <HashLoader
                                    color={`#0b0d41`}
                                    loading={isLoading}
                                    size={40}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                          {isDisplay && data.length > 0
                            ? data.map((item, key) => (
                              <>
                                <tr key={key}>
                                  <td>
                                    <div className="usr_det_tb">
                                      <div className="img_wrap_qr_tb">
                                        {/* <img
                              className="not_prof_img"
                              src={`/${item.qrcode}`}
                              alt=""
                            /> */}
                                        {item.qrcode &&
                                          item.qrcode !== "" && (
                                            <>
                                              <img
                                                className={`not_prof_img`}
                                                src={`/${item.qrcode}`}
                                                onClick={() =>
                                                  openModal(
                                                    `/${item.qrcode}`
                                                  )
                                                }
                                                alt=""
                                              />
                                              <Images
                                                isOpen={isModalOpen}
                                                imageUrl={
                                                  selectedImageUrl
                                                }
                                                onClose={closeModal}
                                              />
                                            </>
                                          )}
                                      </div>
                                      <span>{item.unique_number}</span>
                                    </div>{" "}
                                  </td>
                                  <td>{item.number}</td>
                                  <td className="text-start">
                                    <div className="conditions-appnt">
                                      {item.status === "pending" && (
                                        <span
                                          className={`appnt-alert warning`}
                                          style={{
                                            marginLeft: "0px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {item.status}
                                        </span>
                                      )}
                                      {item.status === "available" && (
                                        <span
                                          className={`appnt-alert success`}
                                          style={{
                                            marginLeft: "0px",
                                            fontSize: "10px",
                                          }}
                                        >
                                          {item.status}
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <a href={`/${item.qrcode}`} download="containers.png" rel="noreferrer" target="_blank"
                                        className="btn btn-success tb-btn"
                                      >
                                        download qr
                                      </a>
                                      <button
                                        type="button"
                                        className="btn btn-delete tb-btn"
                                        onClick={() =>
                                          handleDelete(item._id)
                                        }
                                      >
                                        remove
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))
                            : notFound && (
                              <tr>
                                <td
                                  colSpan={5}
                                  className="text-center"
                                >
                                  <p className="text-center text-danger">
                                    No data found.
                                  </p>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                    <div className="table-num">
                      {data.length > 0 && (
                        <ReactPaginate
                          previousLabel={"<"}
                          nextLabel={">"}
                          breakLabel={"..."}
                          pageCount={totalPages}
                          forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                          onPageChange={handlePageChange}
                          containerClassName={" dig-num"}
                          pageClassName={"dig-num"}
                          previousClassName={"num-btns"}
                          nextClassName={"num-btns"}
                          disabledClassName={"pagination-disabled"}
                          activeClassName={"pagination-active"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Containers;
