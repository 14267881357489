import { createStore } from 'redux';
import rootReducer from './components/Redux/AuthReducer';

const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

const store = createStore(rootReducer, persistedState);

// Save state to local storage whenever it changes
store.subscribe(() => {

  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;
