import React, { useEffect, useState } from "react";
import styles from "./style.table.module.css";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import Images from "../modal/Images";
import BackIcon from "../../../assets/icons/icon-back.svg";
import { notification } from "antd";
const Products = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const role = isAuthenticated.role;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [onOrderdNotFound, setOnorderdNotFound] = useState(false);
  const [lockerHistoryNotFound, setLockerHistoryNotFound] = useState(false);
  const [getLockerHistory, setLockerHistory] = useState([]);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isDisplay1, setIsDisplay1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [onOrderProduct, setOnOrderProduct] = useState([]);
  const [credentials, setCredentials] = useState({ name: "" });
  const { id } = useParams();
  const itemsPerPage = 8;
  useEffect(() => {
    lockerHistory(id);
  }, [currentPage, id]);

  const handleInput = (e) => {
    setCurrentPage(1);
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      // availableProduct(updatedCredentials, id);
      return updatedCredentials;
    });
  };

  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };

  const lockerHistory = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading3(true);
      const response = await fetch(
        `${url}/api/products/lockersHistory?page=${currentPage}&limit=${itemsPerPage}&site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      setIsLoading3(false);
      if (json.history.length > 0) {
        setLockerHistoryNotFound(false);
        setIsDisplay1(true);
        setLockerHistory(json.history);
      } else {
        setIsDisplay1(false);
        setLockerHistoryNotFound(true);
        setLockerHistory([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/dashboard">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Lockers History</h3>
                  <p>
                  List of all lockers and history
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                        <th>User Name</th>
                        <th>Product</th>
                        <th>Site</th>
                        <th>Locker Number</th>
                        <th>QTY</th>
                        <th>Opening Reason</th>
                        <th>Opening Date</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                      {isLoading3 && (
                        <>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-center"
                              style={{
                                background: "transparent",
                              }}
                            >
                              <HashLoader
                                color={`#0b0d41`}
                                loading={isLoading3}
                                size={40}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </td>
                          </tr>
                        </>
                      )}
                      {isDisplay1 &&
                        getLockerHistory.map((item, key) => (
                          <tr
                            className="row-data "
                            style={{ marginBottom: "10px" }}
                          >
                            <td className="text-capitalize">
                              {item.user_name}
                            </td>
                            <td className="text-capitalize">{item.name}</td>
                            <td className="text-capitalize">{item.s_name}</td>
                            <td className="text-capitalize">{item.locker}</td>
                            <td>{item.quantity}</td>
                            <td>{item.reason}</td>
                            <td>{formattedDate(item.date)}</td>
                            <td></td>
                          </tr>
                        ))}
                      {lockerHistoryNotFound && (
                        <tr>
                          <td colSpan={8} className="text-center">
                            <p className="text-center text-danger">
                              No data found.
                            </p>
                          </td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {getLockerHistory.length > 0 && (
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={" dig-num"}
                        pageClassName={"dig-num"}
                        previousClassName={"num-btns"}
                        nextClassName={"num-btns"}
                        disabledClassName={"pagination-disabled"}
                        activeClassName={"pagination-active"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Products;
