import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../assets/icons/icon-back.svg'
import styles from './style.module.css'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
const LabelAdd = () => {
    const isAuthenticated = useSelector((state) => state.user);
    const site = isAuthenticated.site;
    const role = isAuthenticated.role;
    const url = process.env.REACT_APP_APP_BACK_URL;
    const base_url = process.env.REACT_APP_BASE_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [getSites, setSite] = useState([]);
    const [getLockerS, setLockerSize] = useState([]);
    const [getLockerB, setLockerBank] = useState([]);
    const [suplyLocker, setsuplyLocker] = useState(false);
    const [credentials, setCredentials] = useState({ name: "", date: "", to_name: "", to_phone: "", to_address: "", from_name: "", from_phone: "", from_address: "" });
    const handleInput = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
    const formSubmit = async (e) => {
        e.preventDefault();
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        setIsLoading(true);
        const response = await fetch(
            `${url}/api/shipping/label/add`,
            {
                mode: "cors",
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    name: credentials.name,
                    date: credentials.date,
                    to_name: credentials.to_name,
                    from_name: credentials.from_name,
                    to_phone: credentials.to_phone,
                    from_phone: credentials.from_phone,
                    to_address: credentials.to_address,
                    from_address: credentials.from_address,
                }),
            }
        );
        setIsLoading(false);
        const json = await response.json();
        if (json.success) {
            setCredentials({
                locker_no: "", size: "", site: "", row: "", column: "", l_bank: "", comment: "", relay: "", containers: ""
            });
            notification.success({
                message: 'Success',
                description: 'label   Created Successfully',
                duration: 3
            });
        } else {
            notification.error({
                message: 'Failed',
                description: json.error,
                duration: 3
            });
        }
    };
    const iocnStyle = {
        display: isLoading ? 'inline-block' : 'none',
    };
    const [isActive, setIsActive] = useState(false);
    // Function to toggle the active state
    const toggleActive = () => {
        setIsActive(!isActive);
    };
    // Define the CSS classes based on the isActive state
    const divClassName = isActive ? 'sidebar active' : 'sidebar';
    return (
        <div className="general-dashboard">
            <div className="container-fluid">
                <div className="dashboard-top-row add-new-top-row">
                    <div className="row">
                        <div className="col-lg-6 my-auto">
                            <div className="user-wrap user-back-wrap">
                                <Link to="/shipping/label/list">
                                    <div className="back-icon">
                                        <img src={BackIcon} alt="" />
                                    </div>
                                </Link>
                                <div className="user-back-det">
                                    <h3>Add Shipping Label</h3>
                                    <p>
                                        Enter the credentials to add new shipping label to the
                                        system
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                    </div>
                </div>
                {/* row */}
                <form onSubmit={formSubmit}>
                    <div className="form-box card-box">
                        <div className="row">
                            <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="label-wrap">
                                            <h4>Ship Name</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="inpt-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="locker_no"
                                                name="name"
                                                placeholder=""
                                                value={credentials.name}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="label-wrap">
                                            <h4>Ship Date</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="inpt-wrap">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="locker_no"
                                                name="date"
                                                value={credentials.date}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-12">
                                <h4 className="mb-4" style={{ color: "#100df5", fontSize: "25px" }}>From:</h4>
                            </div>
                            <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="label-wrap">
                                            <h4>Name</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="inpt-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="row"
                                                name="from_name"
                                                value={credentials.row}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="label-wrap">
                                            <h4>Phone</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="inpt-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="column"
                                                name="from_phone"
                                                value={credentials.from_phone}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-lg-12">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2">
                                        <div className="label-wrap">
                                            <h4>Address</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-10 col-lg-10">
                                        <div className="inpt-wrap">
                                            <textarea name="from_address" id="comment" cols="30" rows="7" value={credentials.from_address} onChange={handleInput}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-12">
                                <h4 className="mb-4" style={{ color: "#100df5", fontSize: "25px" }}>To:</h4>
                            </div>
                            <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="label-wrap">
                                            <h4>Name</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="inpt-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="row"
                                                name="to_name"
                                                value={credentials.to_name}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-lg-6">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4">
                                        <div className="label-wrap">
                                            <h4>Phone</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-8 col-lg-8">
                                        <div className="inpt-wrap">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="column"
                                                name="to_phone"
                                                value={credentials.to_phone}
                                                onChange={handleInput}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-lg-12">
                                {/* input row */}
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2">
                                        <div className="label-wrap">
                                            <h4>Address</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-10 col-lg-10">
                                        <div className="inpt-wrap">
                                            <textarea name="to_address" id="comment" cols="30" rows="7" value={credentials.to_address} onChange={handleInput}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                {/* input row */}
                            </div>
                            <div className="col-lg-12 text-end">
                                <div className="form-btn-wrap mb-5 pb-5">
                                    <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                                        <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default LabelAdd;
