// ImageModal.js
import ReactModal from 'react-modal';
import './image.css'; 
import RemoveImg from "../../../images/remove.png";
const ImageModal = ({ isOpen, imageUrl, onClose }) => {
    return (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={onClose}
          contentLabel="Image Modal"
          className="modal-content" // Apply the modal content styles
          overlayClassName="modal-overlay" // Apply the modal overlay styles
        >
          <img
        src={imageUrl}
        alt="Image"
        style={{
          width: '100%', // Set the image width as a percentage of its container (modal)
          height: 'auto', // Automatically adjust the height to maintain the aspect ratio
          maxHeight: '50vh', // Set a maximum height to fit within the viewport
          objectFit: 'contain', // Preserve aspect ratio and fit within the container
        }}
      />
          <button onClick={onClose} className="modal-close">Close</button>
        </ReactModal>
      );
};

export default ImageModal;
