import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import NotificationIcon from "../../../images/icon-notifications.png";
import BackIcon from "../../../assets/icons/icon-back.svg";
import UploadIcon from "../../../images/icon-upload.png";
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import InputMask from "react-input-mask";
function PhoneInput(props) {
  return (
    <InputMask
      mask="+1999 999 9999"
      value={props.value}
      onChange={props.onChange}
      placeholder="+1123 123 1234"
      className="form-control"
    ></InputMask>
  );
}
const Add = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [getRoles, setRoles] = useState([]);
  const [getSite, setSite] = useState([]);
  const [getCompanies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [site, setSites] = useState(true);
  const [company, setCompany] = useState(false);
  const [phone, setPhone] = useState("");
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  useEffect(() => {
    roleData();
    siteData();
    companyData();
  }, []);
  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    role: "",
    site: "",
    company: "",
  });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleRoleInput = (e) => {
    if(e.target.value.toLowerCase()==='company admin'){
      setCompany(true);
      setSites(false);
      setCredentials({
        site: "",
      });
    }else{
      setSites(true);
      setCompany(false);
      setCredentials({
        company: "",
      });
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      const response = await fetch(`${url}/api/auth/createUser`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          name: credentials.name,
          email: credentials.email,
          role: credentials.role,
          site: credentials.site,
          phone: phone,
          company: credentials.company,
        }),
      });
      setIsLoading(false);
      const json = await response.json();
      if (json.success) {
        setCredentials({
          name: "",
          email: "",
          role: "",
          site: "",
          company: "",
        });
        setPhone("");
        setFile(null);
        notification.success({
          message: "Success",
          description: "User Created Successfully",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: "failed to create user",
          duration: 3,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const roleData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/roles/getRoles?role=${isAuthenticated.role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      setRoles(json);
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/sites/list?role=${isAuthenticated.role}&site=${isAuthenticated.site}&company=${isAuthenticated.company_id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      setSite(json.site_data);
    } catch (error) {
      console.error(error);
    }
  };
  const companyData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/company/getCompanies`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setCompanies(json.companies);
    } catch (error) {
      console.error(error);
    }
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setError(null);
        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        setFile(null);
        setError("Invalid file type. Please select an image file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/user/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Create User</h3>
                  <p>Enter the credentials to add new user to the system</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter the precise name of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Stephen"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Email</h4>
                      <p>Enter email address of the user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="email"
                        className="form-control"
                        id="emailadd"
                        placeholder="Email Address"
                        name="email"
                        value={credentials.email}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>User Role</h4>
                      <p>Select the role that the user will play</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="role"
                        value={credentials.role}
                        onChange={handleRoleInput}
                        required
                      >
                        <option value="">Choose Role</option>
                        {getRoles.map((per) => (
                          <option key={per._id} value={per.name}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              {site && (
                <div className="col-lg-6">
                  {/* input row */}
                  <div className="row">
                    <div className="col-xl-5 col-lg-5">
                      <div className="label-wrap">
                        <h4>User Site</h4>
                        <p>Select the site for user</p>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7">
                      <div className="inpt-wrap">
                        <select
                          name="site"
                          value={credentials.site}
                          onChange={handleInput}
                          required
                        >
                          <option value="" selected>
                            Choose Site
                          </option>
                          {getSite.map((per) => (
                            <option key={per._id} value={per._id}>
                              {per.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* input row */}
                </div>
              )}
              {company && (
                <div className="col-lg-6">
                  {/* input row */}
                  <div className="row">
                    <div className="col-xl-5 col-lg-5">
                      <div className="label-wrap">
                        <h4>User Company</h4>
                        <p>Select the company for user</p>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7">
                      <div className="inpt-wrap">
                        <select
                          name="company"
                          value={credentials.company}
                          onChange={handleInput}
                          required
                        >
                          <option value="" selected>
                            Chose Company
                          </option>
                          {getCompanies.map((per) => (
                            <option key={per._id} value={per._id}>
                              {per.full_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* input row */}
                </div>
              )}
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Phone Number</h4>
                      <p>Enter phone number of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <PhoneInput
                        value={phone}
                        onChange={handlePhoneInput}
                      ></PhoneInput>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a href="" className="btn btn-prim btn-transparent w-auto">
                    Reset Form
                  </a>
                  <button
                    type="submit"
                    disabled={isLoading}
                    href=""
                    className="btn btn-prim w-auto ms-5"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{" "}
                    Create User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
