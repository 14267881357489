import React, { useEffect, useState, useRef } from 'react';

const VideoPlayer = ({ videoList }) => {
  const [videoIndex, setVideoIndex] = useState(0);
  const [isImage, setIsImage] = useState(true);
  const videoRef = useRef(null);
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutIdRef.current);
      if (videoRef.current) {
        videoRef.current.removeEventListener('loadedmetadata', handleVideoLoaded);
      }
    };
  }, []);

  useEffect(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    const currentFile = videoList[videoIndex];
    const fileExtension = currentFile.toUpperCase().split('.').pop();

    setIsImage(
      [
        'JPG', 'JPEG', 'PNG', 'GIF', 'TIFF', 'PSD', 'PDF', 'EPS', 'AI', 'INDD', 'RAW'
      ].includes(fileExtension)
    );

    if (isImage) {
      // Show the next item after 3 seconds for images
      timeoutIdRef.current = setTimeout(() => {
        setNextItem();
      }, 3000);
    } else {
      // For videos, wait for the video to load its metadata to get the duration
      if (videoRef.current) {
        videoRef.current.addEventListener('loadedmetadata', handleVideoLoaded);
        videoRef.current.load();
        videoRef.current.play()
          .catch(error => {
            //console.error("Error while playing the video:", error);
          });
      }
    }

    return () => {
      clearTimeout(timeoutIdRef.current);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };
  }, [videoIndex, videoList, isImage]);

  const handleVideoLoaded = () => {
    const videoDuration = videoRef.current.duration;
    // Show the next item after the video duration
    timeoutIdRef.current = setTimeout(() => {
      setNextItem();
    }, videoDuration * 1000); // Convert duration to milliseconds
  };

  const setNextItem = () => {
    if (videoIndex < videoList.length - 1) {
      setVideoIndex((prevIndex) => prevIndex + 1);
    } else {
      setVideoIndex(0);
    }
  };

  const handleOnEnded = () => {
    setNextItem();
  };

  return (
    <div className="fullscreen-video">
      {isImage ? (
        <img src={`/uploads/screen/${videoList[videoIndex]}`} alt="Image" style={{"width":"100%","height":"100%"}}/>
      ) : (
        <video className="fullscreen-video" id="video_player" ref={videoRef} onEnded={handleOnEnded} autoPlay muted>
          <source id="myvideo" src={`/uploads/screen/${videoList[videoIndex]}`} type="video/mp4" style={{"width":"100%",height:"400px"}}/>
        </video>
      )}
    </div>
  );
};

export default VideoPlayer;
