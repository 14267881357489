import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../assets/icons/icon-back.svg'
import { useSelector } from 'react-redux';
import { notification } from "antd";
import styles from './style.module.css';
import { Link, useNavigate, useParams } from "react-router-dom";
const PickupEdit = () => {
    const { id } = useParams();
    let navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [credentials, setCredentials] = useState({ status: ""});
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/pickup/update`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id:id,
          status: credentials.status,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    console.log(json)
    if (json.success) {
      notification.success({
        message: 'Success',
        description: 'Pickup updated Successfully',
        duration: 3
      });
      navigate("/pickup/list")
    } else {
      setIsLoading(false);
      notification.error({
        message: 'Failed',
        description: json.error,
        duration: 3
      });
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return ( 
              <div className="general-dashboard">
                <div className="container-fluid">
                  <div className="dashboard-top-row add-new-top-row">
                    <div className="row">
                      <div className="col-lg-6 my-auto">
                        <div className="user-wrap user-back-wrap">
                          <Link to="/pickup/list">
                            <div className="back-icon">
                              <img src={BackIcon} alt="" />
                            </div>
                          </Link>
                          <div className="user-back-det">
                            <h3>Edit Pickup</h3>
                            <p>
                              Enter information to update pickup
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 text-end"></div>
                    </div>
                  </div>
                  {/* row */}
                  <div className="col-lg-6 col-sm-12">
                  <form onSubmit={formSubmit}>
                    <div className="form-box card-box">
                      <div className="row">
                        <div className="col-lg-12">
                          {/* input row */}
                          <div className="row">
                            <div className="col-xl-3 col-lg-3">
                              <div className="label-wrap">
                                <h4>Change Status</h4>
                                <p>Selected related status</p>
                              </div>
                            </div>
                            <div className="col-xl-9 col-lg-9">
                              <div className="inpt-wrap">
                              <select name="status" value={credentials.status} onChange={handleInput} required  >
                                    <option value="new">New</option>
                                    <option value="cancelled">Cancelled</option>
                                    <option value="completed">Completed</option>
                                  </select>
                              </div>
                            </div>
                          </div>
                          {/* input row */}
                        </div>
                        <div className="col-lg-12 text-end">
                          <div className="form-btn-wrap mb-5 pb-5">
                            <button type="submit" className="btn btn-prim w-auto ms-5" disabled={isLoading}>
                               {isLoading ? 'updating...' : 'Update Pickup'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  </div>
                </div>
              </div>
  );
};
export default PickupEdit;
