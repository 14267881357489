import React, { useState, useEffect } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link, useParams } from "react-router-dom";
import styles from "./style.module.css";
const url = 'ws://54.227.101.242:5001';
const LiveStreaming2 = () => {
  const { id } = useParams();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/rtsp-relay@1.7.0/browser/index.js";
    script.async = true;
    script.onload = () => {
      /* eslint-disable no-undef */
      loadPlayer({
        url: `${url}/api/stream/${id}`, // Replace with your desired channel ID
        canvas: document.getElementById('canvas')
      });
      /* eslint-enable no-undef */
    };
    document.head.appendChild(script);
    return () => {
      // Clean up the script element if the component unmounts
      document.head.removeChild(script);
    };
  }, [IDBIndex]);
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                {/* <Link to="/dashboard">
                            <div className="back-icon">
                              <img src={BackIcon} alt="" />
                            </div>
                          </Link> */}
                <div className="user-back-det">
                  <h3>Live Streaming</h3>
                  <p>View Live Streaming </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="form-box card-box">
          <canvas id="canvas" height={'500px'} width={'9000px'}></canvas>
        </div>
      </div>
    </div>
  )
}
export default LiveStreaming2