import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import ReactPaginate from 'react-paginate';
import VideoOrImage from "./VideoOrImage";
import SearchIcon from "../../../images/search.png";
import FilterIcon from "../../../images/filter.png";
import styles from "./style.module.css";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
const List = () => {
    const url = process.env.REACT_APP_APP_BACK_URL;
    const isAuthenticated = useSelector((state) => state.user);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 8;
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [isDisplay, setIsDisplay] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [credentials, setCredentials] = useState({ name: "" });
    const handleInput = (e) => {
        // Use the callback function to ensure that the state is updated before calling fetchData
        setCredentials((prevCredentials) => {
            const updatedCredentials = { ...prevCredentials, [e.target.name]: e.target.value };
            fetchData(updatedCredentials);
            return updatedCredentials;
        });
    };
    useEffect(() => {
        fetchData(credentials);
    }, [currentPage]);
    const fetchData = async (fetchCredentials) => {
        try {
            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Access-Control-Allow-Origin", "*");
            headers.append("Access-Control-Allow-Credentials", "true");
            headers.append("auth-token", localStorage.getItem("token"));
            setIsLoading(true);
            setIsDisplay(false);
            setIsNotFound(false);
            const response = await fetch(`${url}/api/screen/video/list?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}`, {
                mode: "cors",
                method: "GET",
                headers: headers,
            });
            setIsLoading(false);
            const json = await response.json();
            if (json.content.length > 0) {
                setIsDisplay(true);
                setData(json.content);
            } else {
                setIsNotFound(true);
            }
            setTotalPages(json.totalPages);
        } catch (error) {
            console.error(error);
        }
    };
    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
    };
    const handleResetBtn = () => {
        const updatedCredentials = {
            name: '',
        };
        // Update state and then call fetchData
        setCredentials(updatedCredentials);
        setCurrentPage(1);
        // Call fetchData with the updated credentials
        fetchData(updatedCredentials);
    };
    function toggleFilterSection() {
        setIsFilterVisible(!isFilterVisible);
    }
    const formattedDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };
    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                reverseButtons: true,
            });
            if (result.isConfirmed) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/json');
                headers.append('Access-Control-Allow-Origin', '*');
                headers.append('Access-Control-Allow-Credentials', 'true');
                const response = await fetch(`${url}/api/screen/video/delete/${id}`, {
                    mode: 'cors',
                    method: 'DELETE',
                    headers: headers,
                });
                if (response.ok) {
                    fetchData(credentials); // Refresh the records list after successful deletion
                    Swal.fire(
                        'Deleted!',
                        'media deleted successfully!',
                        'success'
                    );
                } else {
                    Swal.fire(
                        'Error',
                        'An error occurred while deleting the record.',
                        'error'
                    );
                }
            }
        } catch (error) {
            console.log('Error deleting record:', error);
            Swal.fire(
                'Error',
                'An error occurred while deleting the record.',
                'error'
            );
        }
    };
    const [isActive, setIsActive] = useState(false);
    // Function to toggle the active state
    const toggleActive = () => {
        setIsActive(!isActive);
    };
    // Define the CSS classes based on the isActive state
    const divClassName = isActive ? 'sidebar active' : 'sidebar';
    return (
        <div className="general-dashboard">
            <div className="container-fluid">
                <div className="dashboard-top-row">
                    <div className="row">
                        <div className="col-lg-6 my-auto">
                            <div className="user-wrap">
                                <h3>media content</h3>
                                <p>An overview of all the images and videos</p>
                            </div>
                        </div>
                        <div className="col-lg-6 text-end">
                            <div className="filter-right-sec">
                                <div className="search-wrap">
                                    <div className="search-box" onClick={toggleFilterSection}>
                                        <img src={SearchIcon} alt="" />
                                    </div>
                                </div>
                                <Link
                                    to="/media/content/add"
                                    className="btn btn-prim w-auto"
                                >
                                    add media
                                </Link>
                            </div>
                        </div>
                    </div>
                    {isFilterVisible && (
                        <>
                            <motion.div
                                className={`row mt-5 filter-section`}
                                initial={{ opacity: 0 }} // Initial state (hidden)
                                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                                transition={{ duration: 1.2, delay: 0.1 }}
                            >
                                <div className="col-12">
                                    <div className="filter-right-sec filter-wrap-chat">
                                        <div className="search-wrap">
                                            <input type="search" placeholder="search by title" name="name" value={credentials.name} onChange={handleInput} />
                                            <div className="search-box">
                                                <img src={SearchIcon} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </>
                    )}
                </div>
                {/* row */}
                <div className="table-wrap">
                    <div className="row">
                        <div className="col-12">
                            <div className={styles.card_box}>
                                <div className={styles.tables_wrap}>
                                    <div className="table-responsive">
                                        <table className="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th>media</th>
                                                    <th>media type</th>
                                                    <th>format</th>
                                                    <th>media name</th>
                                                    <th>date added</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading && (
                                                    <>
                                                        <tr>
                                                            <td colSpan={5} className="text-center" style={{ background: "transparent" }}>
                                                                <HashLoader
                                                                    color={`#0b0d41`}
                                                                    loading={isLoading}
                                                                    size={40}
                                                                    aria-label="Loading Spinner"
                                                                    data-testid="loader"
                                                                />
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                                {isDisplay &&
                                                    data.map((item, i) => (
                                                        <motion.tr
                                                            key={item._id}
                                                            initial={{ opacity: 0, translateX: -10, translateY: -10 }}
                                                            animate={{ opacity: 1, translateX: 0, translateY: 0 }}
                                                            transition={{ duration: 1.2, delay: i * 0.2 }}
                                                        >
                                                            <VideoOrImage videoName={item.image} />
                                                            <td>{item.description}</td>
                                                            <td>{formattedDate(item.createdAt)}</td>
                                                            <td>
                                                                <Link
                                                                    to={`/media/content/edit/${item._id}`}
                                                                    className="btn btn-edit tb-btn"
                                                                >
                                                                    edit
                                                                </Link>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-delete tb-btn"
                                                                    onClick={() => handleDelete(item._id)}
                                                                >
                                                                    delete
                                                                </button>
                                                            </td>
                                                        </motion.tr>
                                                    ))
                                                }
                                                {isNotFound && (
                                                    <tr>
                                                        <td colSpan={6} className="text-center">
                                                            <p className="text-center text-danger">No data found.</p>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="table-num">
                                        {data.length > 0 && (
                                            <ReactPaginate
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                breakLabel={'...'}
                                                pageCount={totalPages}
                                                forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                                                onPageChange={handlePageChange}
                                                containerClassName={' dig-num'}
                                                pageClassName={'dig-num'}
                                                previousClassName={'num-btns'}
                                                nextClassName={'num-btns'}
                                                disabledClassName={'pagination-disabled'}
                                                activeClassName={'pagination-active'}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default List