import React, {  useState } from "react";
import "../../../locker.css"
import styles from "../dashboard/style.module.css"
import scandetail from "../../../images/scan-detailss.png"
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";


const ScanDetail = () => {


    const [isCollapsed, setIsCollapsed] = useState(false);

   
      const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
      };

  return (
    <div className={styles.heading}>
      <section className={styles.sidebar_wrap}>
        <div className="body-wrap">
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar `}>

                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />
                <div className={styles.body_wrap}>
                  <div className="container-detail-body">
                    <div className="row">
                        <div className="col-md-7">
                        <div className="dropbox-wrap-body">
                        <div className="dropbox-wrap">
                      <div className="accordion mt-3">
                        <div className="accordion-item">
                          <h2 className="accordion-header" >
                            <button
                              className={`accordion-button ${isCollapsed ? '' : 'active'}`} style={{ color:"#fff" , backgroundColor:"#012269"}}
                              type="button"
                              onClick={toggleCollapse}
                              aria-expanded={isCollapsed}
                              aria-controls="collapse1"
                            >
                              63425
                            </button>
                          </h2>
                          <div id="collapse1"
                            className={`accordion-collapse collapse ${isCollapsed ? '' : 'show'}`}
                            aria-labelledby="heading1"
                          >
                            <div className="accordion-body">
                              <div className="accordion-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: 300}} defaultValue={""} />
                                                <label htmlFor="floatingTextarea2">Write Your Message...</label>
                                        </div>
                                        <div className="row mt-3">
                                         <div className="col-12">
                                         <div>
                                            
                                            <input className="form-control" type="file" id="formFileMultiple" multiple />
                                        </div>

                                         </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-4">
                                            <button className="btn btn-prim" type="submit">Add Pic</button>
                                            </div>
                                            <div className="col-md-8">
                                            <button className="btn btn-prim" type="submit">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="scan-selected-imges">
                                            <img src="" alt=""/>
                                            
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="accordion mt-3">
                        <div className="accordion-item">
                          <h2 className="accordion-header" >
                            <button
                              className={`accordion-button ${isCollapsed ? '' : 'active'}`} style={{ color:"#fff" , backgroundColor:"#012269"}}
                              type="button"
                              onClick={toggleCollapse}
                              aria-expanded={isCollapsed}
                              aria-controls="collapse2"
                            >
                              73452
                            </button>
                          </h2>
                          <div id="collapse2"
                            className={`accordion-collapse collapse ${isCollapsed ? '' : 'show'}`}
                            aria-labelledby="heading2"
                          >
                            <div className="accordion-body">
                            <div className="accordion-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: 300}} defaultValue={""} />
                                                <label htmlFor="floatingTextarea2">Write Your Message...</label>
                                        </div>
                                        <div className="row mt-3">
                                         <div className="col-12">
                                         <div>
                                            
                                            <input className="form-control" type="file" id="formFileMultiple" multiple />
                                        </div>

                                         </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-4">
                                            <button className="btn btn-prim" type="submit">Add Pic</button>
                                            </div>
                                            <div className="col-md-8">
                                            <button className="btn btn-prim" type="submit">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="scan-selected-imges">
                                            <img src="" alt=""/>
                                            
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="accordion mt-3">
                        <div className="accordion-item">
                          <h2 className="accordion-header" >
                            <button
                              className={`accordion-button ${isCollapsed ? '' : 'active'}`} style={{ color:"#fff" , backgroundColor:"#012269"}}
                              type="button"
                              onClick={toggleCollapse}
                              aria-expanded={isCollapsed}
                              aria-controls="collapse2"
                            >
                              12047
                            </button>
                          </h2>
                          <div id="collapse3"
                            className={`accordion-collapse collapse ${isCollapsed ? '' : 'show'}`}
                            aria-labelledby="heading3"
                          >
                            <div className="accordion-body">
                            <div className="accordion-details">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: 300}} defaultValue={""} />
                                                <label htmlFor="floatingTextarea2">Write Your Message...</label>
                                        </div>
                                        <div className="row mt-3">
                                         <div className="col-12">
                                         <div>
                                           <input className="form-control" type="file" id="formFileMultiple" multiple />
                                        </div>

                                         </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-4">
                                            <button className="btn btn-prim" type="submit">Add Pic</button>
                                            </div>
                                            <div className="col-md-8">
                                            <button className="btn btn-prim" type="submit">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="scan-selected-imges">
                                            <img src="" alt=""/>
                                            
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                        </div> 

                        <button className="btn btn-prim" type="submit">Finish</button>
                        </div>
                         
                    </div>
                        <div className="col-md-4">
                            <div className="scan-detail-image">
                                <img src={scandetail} alt="scandetail"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ScanDetail
