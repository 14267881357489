import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { useDropzone } from "react-dropzone";
import BackIcon from '../../../assets/icons/icon-back.svg'
import UploadIcon from '../../../images/icon-upload.png'
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import styles from "./style.module.css";
import { useParams } from 'react-router-dom';
import MediaValidator from "./MediaValidator";
const MediaEdit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const [appendedHTML, setAppendedHTML] = useState("");
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [mediaShow, setMediaShow] = useState(false);
  const [credentials, setCredentials] = useState({
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    fetchData();
  }, []
  );
  const formSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("description", credentials.description);
    formData.append("site", isAuthenticated.site);
    if (file) {
      formData.append("file", file);
    } else {
      formData.append("file", "");
    }
    try {
      const response = await fetch(`${url}/api/screen/post/video/update/${id}`, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        setCredentials({ description: "" });
        setFile(null);
        notification.success({
          message: "Success",
          description: "media updated Successfully",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while posting the video.",
        duration: 3,
      });
    }
    setIsLoading(false);
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setMediaShow(false);
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/gif", "video/mp4", "video/webm", "video/ogg"];
      if (allowedTypes.includes(file.type)) {
        setFile(file);
        setError(null);
        // Create a FileReader
        const reader = new FileReader();
        // Add a progress event listener to the FileReader
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentUploaded = Math.round((e.loaded / e.total) * 100);
            setUploadProgress(percentUploaded);
          }
        };
        // Read the file (you can also add other event listeners like 'onload')
        reader.readAsDataURL(file);
      } else {
        setFile(null);
        setError("Invalid file type. Please select an image or video file.");
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/screen/post/video/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      console.log(json);
      if (json.success) {
        setData(json.data);
        credentials.description = json.data.description;
        setMediaShow(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/media/contents">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Edit media</h3>
                  <p>Edit image or video</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>upload media</h4>
                      <p>Upload in photo or video format</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <div className="file-wrap">
                        <input type="file" onChange={handleFileSelect} />
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="file_name">
                            {file ? file.name : 'No file selected'}
                          </span>{" "}
                          <img src={UploadIcon} alt="Upload Icon" />
                        </div>
                      </div>
                      <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>
                        {uploadProgress > 0 && uploadProgress < 100 && (
                          <span>{uploadProgress}% uploaded</span>
                        )}
                      </div>
                      {error && <div style={{ fontSize: '12px', color: 'red', fontStyle: 'italic' }}>{error}</div>}
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>media title</h4>
                      <p>Add the title of media content</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        placeholder="main hallway door footage"
                        name="description"
                        required
                        value={credentials.description}
                        onChange={handleInput}
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              {data && mediaShow && (
                <div className="col-lg-12 mt-5">
                  <MediaValidator videoName={data.image} />
                </div>
              )}
              <div className="col-lg-12 text-end mt-5">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button
                    type="submit"
                    className="btn btn-prim ms-5 w-auto"
                    disabled={isLoading}
                  >
                    {isLoading ? "updating..." : "update media"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default MediaEdit;
