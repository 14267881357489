import React, { useState } from 'react'
import Header from '../partials/Header';
import Sidebar from '../partials/Sidebar';
import styles from "./style.module.css";
const RootLayout = ({ children }) => {
    const [isActive, setIsActive] = useState(false);
    const divClassName = isActive ? "sidebar active" : "sidebar";
    const toggleActive = () => {
        setIsActive(!isActive);
    };
    return (
        <div className={styles.heading}>
            <div className="body-wrap">
                <section className={styles.sidebar_wrap}>
                    <div className="container-fluid px-0">
                        <div className="row">
                            <div className={`col col-sidebar  ${divClassName} `}>
                                <Sidebar />
                            </div>
                            <div className="col col-dashboard">
                                <Header isActive={isActive} toggleActive={toggleActive} />
                                {children}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
export default RootLayout