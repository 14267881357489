import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
// import { SocketProvider } from "./components/templates/Sockets/SocketProvider";
import store from './store';
const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};
store.dispatch({ type: 'SET_INITIAL_STATE', payload: persistedState });
ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>,
)

