import React, { useEffect, useState } from "react";
import styles from './style.module.css';
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { notification } from "antd";
import profileImg from '../../../images/profile1.png';
import avatar from '../../../images/avatar1.png';
import BackIcon from '../../../assets/icons/icon-back.svg'
const Profile = () => {
    const url = process.env.REACT_APP_APP_BACK_URL;
    const [data, setData] = useState([]);
    const isAuthenticated = useSelector((state) => state.user);
    const [profileImage, setProfileImg] = useState(isAuthenticated?.image || avatar);
    const [isActive, setIsActive] = useState(false);
    const toggleActive = () => {
      setIsActive(!isActive);
    };
    // Define the CSS classes based on the isActive state
    const divClassName = isActive ? 'sidebar active' : 'sidebar';
    return (
      <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Profile</h3>
              </div>
            </div>
            <div className="col-lg-6 text-end">
            </div>
          </div>
        </div>
        {/* row */}
        <div className="row">
            <div className="col-lg-5 text-center">
                <img src={profileImg} />
            </div>
            <div className="col-lg-6">
            <div className="form-box card-box text-center mt-3">
                <h6 style={{textTransform:"uppercase"}}>{isAuthenticated && isAuthenticated.name}</h6>
                <img src={profileImage} style={{width:"100px"}} className="rounded-circle"/>
                <div className="row mt-4">
                    <div className="col-6">
                        <div className="inpt-wrap">
                            <input
                            type="text"
                            className="form-control"
                            id="sitec"
                            placeholder="name"
                            name="name"
                            value={isAuthenticated && isAuthenticated.name}
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="inpt-wrap">
                                <input
                                type="text"
                                className="form-control"
                                id="sitec"
                                placeholder="phone"
                                name="phone"
                                value={isAuthenticated && isAuthenticated.phone}
                                readOnly
                                />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="inpt-wrap">
                                <input
                                type="text"
                                className="form-control"
                                id="sitec"
                                placeholder="email"
                                name="email"
                                value={isAuthenticated && isAuthenticated.email}
                                readOnly
                                />
                        </div>
                    </div>
                    <div className="col-12 text-end">
                        <Link to="/profile/edit" className="btn btn-prim w-auto">Edit Profile</Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
      );
}
export default Profile