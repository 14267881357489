import React, { useEffect, useState } from "react";
import styles from './style.module.css';
import SearchIcon from '../../../images/search.png'
import FilterIcon from '../../../images/filter.png'
import pickupImg from '../../../images/pickup-order.png'
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Button, Popconfirm, message } from 'antd';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
import ContentLoader from 'react-content-loader'
import BackIcon from '../../../assets/icons/icon-back.svg'
const PickupDetail = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [isDoorOpen, setDoorOpen] = useState(false);
  const [credentials, setCredentials] = useState({
    _id: "", order_number: "", pickup_code: "", locker_id: "", locker_number: "", locker_relay: "",
    user_name: "", user_email: "", customer_name: "", customer_email: "", site_url: "", status: "", createdAt: "", updatedAt: ""
  });
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/pickup/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      if (json.success) {
        setCredentials({ ...json.pickup });
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  const HandleOpenLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    const response = await fetch(
      `${url}/api/locker/relay/state/update`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: id,
          state: state
        }),
      }
    );
    setIsLockerOpen(false)
    const json = await response.json();
    console.log(json)
    if (json.success) {
      notification.success({
        message: 'Success',
        description: json.message,
        duration: 3
      });
    } else {
      notification.error({
        message: 'Failed',
        description: json.error,
        duration: 3
      });
    }
  }
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Pickup Detail</h3>
              </div>
            </div>
            <div className="col-lg-6 text-end">
            </div>
          </div>
        </div>
        {/* row */}
        <div className="row">
          <div className="col-lg-5 text-start">
            {isLoading ? (
              <div className="col-12 px-4">
                <ContentLoader viewBox="0 0 400 160" height={160} width={400} backgroundColor="#142E98">
                  <circle cx="150" cy="86" r="8" />
                  <circle cx="194" cy="86" r="8" />
                  <circle cx="238" cy="86" r="8" />
                </ContentLoader>
              </div>
            ) : (
              <div className="form-box card-box" style={{ padding: "0px" }}>
                <div className={styles.pickup_order_heading}>
                  <div className="row">
                    <div className="col-6">
                      <div className={styles.pickup_detail_order}>Locker: {credentials.locker_number}</div>
                    </div>
                    <div className="col-6 text-end">
                      <div className={styles.pickup_detail_order}>Status: <span className="badge bg-primary">{credentials.status}</span></div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="row m-2">
                    <div className="col-6">
                      <span className={styles.pickup_label}>Order Number</span>
                      <div className={styles.pickup_detail_code}>{credentials.order_number}</div>
                    </div>
                    <div className="col-6">
                      <span className={styles.pickup_label}>Pickup Order</span>
                      <div className={styles.pickup_detail_code}>{credentials.pickup_code}</div>
                    </div>
                    {credentials.status === "new" &&
                      <>
                        <div className="col-6">
                          <button className="btn btn-prim  mt-3" disabled={isLockerOpen} onClick={() => { HandleOpenLocker(credentials.locker_id) }}>Open Locker</button>
                        </div>
                      </>
                    }
                  </div>
                  <div className={styles.pickup_detail_box}>
                    <div className={styles.pickup_book_by}>booked by</div>
                    <div className={styles.pickup_book_name}>{credentials.user_name}</div>
                    <div className={styles.pickup_book_email}>{credentials.user_email}</div>
                  </div>
                  <div className={styles.pickup_detail_box}>
                    <div className={styles.pickup_book_by}>Customer Detail</div>
                    {credentials.customer_name &&
                      <div className={styles.pickup_book_name}>{credentials.customer_name}</div>
                    }
                    <div className={styles.pickup_book_email}>{credentials.customer_email}</div>
                  </div>
                </div>
              </div>
            )}
            {isNotFound &&
              <div className="form-box card-box text-center mt-3">
                <h6 className="text-danger">Sorry!</h6>
                <h6>Something went wrong</h6>
                <p>Pickup Not Found</p>
              </div>
            }
          </div>
          <div className="col-lg-7 text-end">
            <img src={pickupImg} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PickupDetail;
