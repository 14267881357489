import React, { useEffect, useState } from "react";
import styles from './style.module.css';
import pickupImg from '../../../images/pickupCustomer.png';
import Complete from '../../../images/complete.svg'
import CalcLeft from '../../../images/calc-left.png'
import CalDel from '../../../images/cal-del.png'
import LoginImg from '../../../images/login-img.png'
import finishImg from '../../../images/pickup-finish.png'
import completeImg from '../../../images/complete.png'
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Button, Popconfirm, message } from 'antd';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
import InputMask from 'react-input-mask';
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/Authentication";
function PhoneInput(props) {
    return (
        <InputMask
            mask='+1999 999 9999'
            value={props.value}
            onChange={props.onChange} placeholder="+1123 123 1234" className="form-control">
        </InputMask>
    );
}


const ExternalPickup = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const url = process.env.REACT_APP_APP_BACK_URL;
    const react_base_url = process.env.REACT_APP_BASE_URL;
    const isAuthenticated = useSelector((state) => state.user);

    const [sizes, setSizes] = useState([]);
    const [lockers, setLockers] = useState([]);
    const [orderStep, setOrderStep] = useState(true);
    const [sizeStep, setSizeStep] = useState(false);
    const [lockerStep, setLockerStep] = useState(false);
    const [customerStep, setcustomerStep] = useState(false);
    const [authStep, setAuthStep] = useState(false);
    const [loginStep, setLoginStep] = useState(true);
    const [registerStep, setRegisterStep] = useState(false);
    const [finishStep, setFinishStep] = useState(false);
    const [isSizeSelected, setIsSizeSelected] = useState('');
    const [sizeLoader, setSizeLoader] = useState(true);
    const [isSizeDisplay, setIsSizeDisplay] = useState(false);
    const [sizeNotFound, setSizeNotFound] = useState(false);
    const [isFormSubmit, setIsFormSubmit] = useState(false);
    const [isLoginSubmit, setIsLoginSubmit] = useState(false);
    const [isRegSubmit, setIsRegSubmit] = useState(false);
    const [firstStep, setFirstStep] = useState(true);
    const [secStep, setSecStep] = useState(false);
    const [thirdStep, setThirdStep] = useState(false);
    const [fourStep, setFourStep] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLogin, setIsLogin] = useState('no');
    const [phone, setPhone] = useState('');
    const handlePhoneInput = (e) => { setPhone(e.target.value) };
    const [code, setCode] = useState('');
    const [site, setSite] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');

    const [credentials, setCredentials] = useState({ size_id: "", locker_id: "", customer_email: "" });
    const handleInput = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };
    const [loginCredentials, setLoginCredentials] = useState({ email: "", password: ""});
    const handleLoginInput = (e) => {
        setLoginCredentials({ ...loginCredentials, [e.target.name]: e.target.value });
    };

    const [registerCredentials, setRegisterCredentials] = useState({name:"", email: "", phone:"",password: ""});
    const handleRegInput = (e) => {
        setRegisterCredentials({ ...registerCredentials, [e.target.name]: e.target.value });
    };

    // Function to handle digit button click
    const handleDigitClick = (digit) => {
        setCode((prevCode) => prevCode + digit);
    };

    // Function to handle the delete button click
    const handleDeleteDigit = () => {
        setCode((prevCode) => prevCode.slice(0, -1));
    };

    useEffect(() => {
        sizeData();
    }, []);

    const sizeData = async () => {
        try {
            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Access-Control-Allow-Origin", "*");
            headers.append("Access-Control-Allow-Credentials", "true");
            headers.append("auth-token", localStorage.getItem("token"));
            setSizeLoader(true);
            setIsSizeDisplay(false);
            setSizeNotFound(false);
            const response = await fetch(`${url}/api/locker/size/available`, {
                mode: "cors",
                method: "POST",
                body: JSON.stringify({
                    id: id,
                }),
                headers: headers,
            });
            const json = await response.json();
            console.log(json);
            if (json.success) {
                setIsLogin(json.loginUser);
                setSite(json.site)
                if (json.data.length > 0) {
                    setSizes(json.data);
                    setSizeLoader(false);
                    setIsSizeDisplay(true);
                    setSizeNotFound(false);
                    setFinishStep(false);
                } else {
                    setSizeLoader(false);
                    setIsSizeDisplay(false);
                    setSizeNotFound(true);
                }
            } else {
                setSizeLoader(false);
                setIsSizeDisplay(false);
                setSizeNotFound(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const HandleSelectedOrderNumber = async () => {
        if (code === '') {
            notification.error({
                message: 'Failed',
                description: 'Please enter order number',
                duration: 3
            });
            return;
        } else if (code.length < 2) {
            notification.error({
                message: 'Failed',
                description: 'Please enter at least 3 digit  order number',
                duration: 3
            });
            return;
        } else {
            setOrderStep(false);
            setSizeStep(true);
            setLockerStep(false);
            setcustomerStep(false);
            setFinishStep(false);


            setFirstStep(true);
            setSecStep(true);
            setThirdStep(false);
            setFourStep(false);
        }
    };

    const HandleSelectedSize = async (size_id) => {
        try {
            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Access-Control-Allow-Origin", "*");
            headers.append("Access-Control-Allow-Credentials", "true");
            setIsSizeSelected(size_id);

            const response = await fetch(`${url}/api/locker/available`, {
                mode: "cors",
                method: "POST",
                body: JSON.stringify({
                    size_id: size_id,
                    id: id
                }),
                headers: headers,
            });
            const json = await response.json();
            setIsSizeSelected(false);

            if (json.success) {
                setCredentials((prevCredentials) => ({
                    ...prevCredentials,
                    size_id: size_id,
                }));
                if (json.lockers.length > 0) {
                    setLockers(json.lockers);
                    setOrderStep(false)
                    setSizeStep(false);
                    setLockerStep(true);
                    setcustomerStep(false);
                    setFinishStep(false);


                    setFirstStep(true);
                    setSecStep(true);
                    setThirdStep(true);
                    setFourStep(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const HandleSelectedLocker = async (locker_id) => {

        setOrderStep(false)
        setSizeStep(false);
        setLockerStep(false);
        setFinishStep(false);

        if (isLogin === "no") {
            setAuthStep(true);
            setcustomerStep(false);

        } else {
            setAuthStep(false);
            setcustomerStep(true);
        }

        setFirstStep(true);
        setSecStep(true);
        setThirdStep(true);
        if (isLogin === "no") {
            setFourStep(false);

        } else {
            setFourStep(true);
        }

        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            locker_id: locker_id,
        }));
    };

    const handleAuthSection = (value) => {
        if(value === "register"){
            setRegisterStep(true);
            setLoginStep(false)
        }
        if(value === "login"){
            setRegisterStep(false);
            setLoginStep(true)
        }
    };

    const formSubmit = async (e) => {
        e.preventDefault();
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        setIsFormSubmit(true);
        const response = await fetch(
            `${url}/api/pickup/add`,
            {
                mode: "cors",
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    order_number: code,
                    size_id: credentials.size_id,
                    locker_id: credentials.locker_id,
                    customer_email: credentials.customer_email,
                    customer_phone: phone,
                    base_url: react_base_url
                }),
            }
        );

        const json = await response.json();
        console.log(json);

        setIsFormSubmit(false);

        if (json.success) {
            setOrderStep(false)
            setSizeStep(false);
            setLockerStep(false);
            setcustomerStep(false);
            setFinishStep(true);

            setCredentials({
                size_id: '',
                locker_id: '',
                customer_email: '',
                customer_phone: '',

            });
            setCode('');

            setRedirectUrl(`/pickup/detail/${json.pickup._id}`)
            //setShowSuccessModal(true);
            notification.success({
                message: 'Success',
                description: 'Pickup added Successfully',
                duration: 3
            });

            setTimeout(() => {
                navigate('/pickup/list');
            }, 3000);

        } else {

            notification.error({
                message: 'Failed',
                description: json.error,
                duration: 3
            });
        }
    };
    const loginFormSubmit = async (e) => {
        e.preventDefault();
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");

        setIsLoginSubmit(true);
    
        const response = await fetch(`${url}/api/auth/login`, {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            email: loginCredentials.email,
            password: loginCredentials.password,
            external:"yes"
          }),
        });
        setIsLoginSubmit(false);
    
        const json = await response.json();
        if (json.success) {
          const user = json.user;
          const expirationTime = Math.floor(Date.now() / 1000) + 7200;
          localStorage.setItem("token", json.authToken);
          localStorage.setItem("token-expiration", expirationTime);
          // socket.emit("login", json.user._id);
          dispatch(loginSuccess(json.user));
          setIsLogin("yes")
          setOrderStep(false)
          setSizeStep(false);
          setLockerStep(false);
          setAuthStep(false);
          setcustomerStep(true);
          
          setFirstStep(true);
          setSecStep(true);
          setThirdStep(true);
          setFourStep(true);
  

          notification.success({
            message: "Success",
            description: "Login Successfully",
            duration: 3,
          });

        } else {
          notification.error({
            message: "Failed",
            description: json.error,
            duration: 3,
          });
        }
    };

    const registerFormSubmit = async (e) => {
        e.preventDefault();
          let headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Access-Control-Allow-Origin", "*");
          headers.append("Access-Control-Allow-Credentials", "true");
    
          setIsRegSubmit(true);
          const response = await fetch(`${url}/api/auth/createUser`, {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              name:registerCredentials.name,
              email: registerCredentials.email,
              password: registerCredentials.password,
              site: site,
              status: 'active'
            }),
          });
          setIsRegSubmit(false);
    
        const json = await response.json();
        if (json.success) {
          const user = json.user;
          const expirationTime = Math.floor(Date.now() / 1000) + 7200;
          localStorage.setItem("token", json.authToken);
          localStorage.setItem("token-expiration", expirationTime);
          // socket.emit("login", json.user._id);
          dispatch(loginSuccess(json.user));
          setIsLogin("yes")
         
          setOrderStep(false)
          setSizeStep(false);
          setLockerStep(false);
          setAuthStep(false);
          setcustomerStep(true);
          
          setFirstStep(true);
          setSecStep(true);
          setThirdStep(true);
          setFourStep(true);
         
          notification.success({
            message: "Success",
            description: "Login Successfully",
            duration: 3,
          });
        } else {
          notification.error({
            message: "Failed",
            description: json.error,
            duration: 3,
          });
        }

    
      };

    const HandleCustomerBck = async (locker_id) => {

        setOrderStep(false);
        setSizeStep(false);
        setLockerStep(true);
        setcustomerStep(false);

        setFirstStep(true);
        setSecStep(true);
        setThirdStep(true);
        setFourStep(false);

        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            locker_id: locker_id,
        }));
    };

    const HandleSizeBck = async (locker_id) => {
        setOrderStep(true)
        setSizeStep(false);
        setLockerStep(false);
        setcustomerStep(false);

        setFirstStep(true);
        setSecStep(false);
        setThirdStep(false);
        setFourStep(false);


    };

    const [isActive, setIsActive] = useState(false);
    const toggleActive = () => {
        setIsActive(!isActive);
    };
    // Define the CSS classes based on the isActive state
    const divClassName = isActive ? 'sidebar active' : 'sidebar';

    return (
        <div className={styles.heading}>
            <div className="body-wrap">
                <section className={styles.sidebar_wrap}>
                    <div className="container-fluid px-0">
                        <div className="row">

                            <div className="col col-12">
                                <div className="general-dashboard m-4">
                                    <div className="container-fluid">
                                        <div className="dashboard-top-row">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-12 my-auto">
                                                    <div className="user-wrap">
                                                        <h3>Book A Pickup</h3>
                                                        <p>
                                                            Book your pickup here
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-sm-12 text-center">
                                                    <div className="steps-wrap">
                                                        <div className={`step-cont ${firstStep ? 'active' : ''}`}>
                                                            <span>01</span>
                                                        </div>
                                                        <div className={`step-cont ${secStep ? 'active' : ''}`}>
                                                            <span>02</span>
                                                        </div>
                                                        <div className={`step-cont ${thirdStep ? 'active' : ''}`}>
                                                            <span>03</span>
                                                        </div>
                                                        <div className={`step-cont ${fourStep ? 'active' : ''}`}>
                                                            <span>04</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {orderStep &&
                                            <div className="calc-wrap-main">
                                                <div className="row text-center">
                                                    <div className="col-lg-6 my-auto">
                                                        <img src={CalcLeft} alt="" />
                                                    </div>
                                                    <div className="col-lg-6 my-auto">
                                                        <div className="calc-wrap">
                                                            <div className="cal-screen">
                                                                <input type="text" name="" id="result" value={code} readOnly />
                                                            </div>
                                                            <div className="keys-wrap">
                                                                <div className="row ">
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('1')}>
                                                                            <i>1</i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('2')}>
                                                                            <i>2</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('3')}>
                                                                            <i>3</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('4')}>
                                                                            <i>4</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('5')}>
                                                                            <i>5</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('6')}>
                                                                            <i>6</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('7')}>
                                                                            <i>7</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('8')}>
                                                                            <i>8</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('9')}>
                                                                            <i>9</i> <span>ABC</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="empty-div-cal"></div>
                                                                    </div>

                                                                    <div className="col-4">
                                                                        <button className="btn-key" onClick={() => handleDigitClick('0')}>
                                                                            <i>0</i>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button className="btn-delt" onClick={handleDeleteDigit}>
                                                                            <img src={CalDel} alt="" />{" "}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="cont-btn-wrap">
                                                                <button className="continue-btn" onClick={HandleSelectedOrderNumber}>Continue</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {sizeStep &&
                                            <div className="calc-wrap-main">
                                                <div className="form-box card-box">
                                                    <h4 className="fw-bold">SELECT SIZE FROM AVAILABLE LOCKER</h4>
                                                    <div className="row mt-3">
                                                        {sizeLoader && (
                                                            <>
                                                                <div className="col-12 text-center">
                                                                    <HashLoader
                                                                        color={`#0b0d41`}
                                                                        loading={sizeLoader}
                                                                        size={40}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {isSizeDisplay &&
                                                            sizes.map((item, i) => (
                                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="card" style={{ background: "#EFF1FF", borderRadius: "18px", borderColor: "#EFF1FF" }}>
                                                                        <div className="card-body text-center">
                                                                            <h6>{item.size}</h6>
                                                                            <p>{item.available_lockers} Available</p>
                                                                            <button type="submit" className="btn btn-prim mt-2" disabled={item.available_lockers > 0 || isSizeSelected ? '' : 'disabled'} onClick={() => { HandleSelectedSize(item._id) }}>
                                                                                SELECT
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }

                                                        <div className="col-12 text-end mt-4">
                                                            <button type="button" className="btn btn-prim w-auto mt-2" onClick={HandleSizeBck}>
                                                                Back
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {lockerStep &&
                                            <div className="table-wrap">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className={styles.card_box}>
                                                            <div className={styles.tables_wrap}>
                                                                <div className="table-responsive">
                                                                    <table className="table align-middle">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>#</th>
                                                                                <th className="text-end">Locker</th>
                                                                                <th className="text-end">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {lockers &&
                                                                                lockers.map((item, i) => (
                                                                                    <motion.tr
                                                                                        key={item._id}
                                                                                        initial={{ opacity: 0 }}
                                                                                        animate={{ opacity: 1 }}
                                                                                        transition={{ duration: 1.2, delay: i * 0.1 }}
                                                                                    >
                                                                                        <td>{i + 1}</td>
                                                                                        <td className="text-end">{item.number}</td>

                                                                                        <td className="text-end">
                                                                                            <button type="button" className="btn btn-prim w-auto " onClick={() => { HandleSelectedLocker(item._id) }}>
                                                                                                SELECT
                                                                                            </button>
                                                                                        </td>
                                                                                    </motion.tr>

                                                                                ))
                                                                            }


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {authStep &&
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    {loginStep &&
                                                        <div className="form-box card-box">
                                                            <h2 className="fw-bold text-center">Login</h2>
                                                            <p className="fw-bold text-center">Please Login before book a pickup</p>
                                                            <form onSubmit={loginFormSubmit}>
                                                                <div className="row mt-4">
                                                                    <div className="col-xl-2 col-lg-2">
                                                                        <div className="label-wrap">
                                                                            <h4>Email</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-10 col-lg-10">
                                                                        <div className="inpt-wrap">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="sitec"
                                                                                placeholder="cutomer email address"
                                                                                name="email"
                                                                                value={loginCredentials.email}
                                                                                onChange={handleLoginInput}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-2 col-lg-2">
                                                                        <div className="label-wrap">
                                                                            <h4>Password</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-10 col-lg-10">
                                                                        <div className="inpt-wrap">
                                                                            <input
                                                                                type="password"
                                                                                className="form-control"
                                                                                id="sitec"
                                                                                placeholder="******"
                                                                                name="password"
                                                                                value={loginCredentials.password}
                                                                                onChange={handleLoginInput}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                    </div>
                                                                    <div className="col-6 text-end">
                                                                        <Link className="m-4" onClick={()=>handleAuthSection('register')}>Sign Up</Link> 
                                                                        <button type="submit" className="btn btn-prim w-auto " disabled={isLoginSubmit}>
                                                                            Login
                                                                        </button>
                                                                    </div>


                                                                </div>
                                                            </form>

                                                        </div>
                                                    }
                                                    {registerStep &&
                                                        <div className="form-box card-box">
                                                            <h2 className="fw-bold text-center">Register</h2>
                                                            <p className="fw-bold text-center">Please enter information for register yourself</p>
                                                            <form onSubmit={registerFormSubmit}>
                                                                <div className="row mt-4">
                                                                    <div className="col-xl-2 col-lg-2">
                                                                        <div className="label-wrap">
                                                                            <h4>Name</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-10 col-lg-10">
                                                                        <div className="inpt-wrap">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="sitec"
                                                                                placeholder="name"
                                                                                name="name"
                                                                                value={registerCredentials.name}
                                                                                onChange={handleRegInput}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-xl-2 col-lg-2">
                                                                        <div className="label-wrap">
                                                                            <h4>Email</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-10 col-lg-10">
                                                                        <div className="inpt-wrap">
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="sitec"
                                                                                placeholder="email address"
                                                                                name="email"
                                                                                value={registerCredentials.email}
                                                                                onChange={handleRegInput}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-4">
                                                                    <div className="col-xl-2 col-lg-2">
                                                                        <div className="label-wrap">
                                                                            <h4>Phone No.</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-10 col-lg-10">
                                                                        <div className="inpt-wrap">
                                                                            <PhoneInput
                                                                                name="phone"
                                                                                value={registerCredentials.phone}
                                                                                onChange={handleRegInput}>
                                                                            </PhoneInput>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-2 col-lg-2">
                                                                        <div className="label-wrap">
                                                                            <h4>Password</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xl-10 col-lg-10">
                                                                        <div className="inpt-wrap">
                                                                            <input
                                                                                type="password"
                                                                                className="form-control"
                                                                                id="sitec"
                                                                                placeholder="******"
                                                                                name="password"
                                                                                value={registerCredentials.password}
                                                                                onChange={handleRegInput}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-6">
                                                                    </div>
                                                                    <div className="col-6 text-end">
                                                                        <Link className="m-4" onClick={()=>handleAuthSection('login')}>Login</Link> 
                                                                        <button type="submit" className="btn btn-prim w-auto " disabled={isRegSubmit}>
                                                                            Register
                                                                        </button>
                                                                    </div>


                                                                </div>
                                                            </form>

                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-lg-5 text-end">
                                                    <img src={LoginImg} />
                                                </div>
                                            </div>
                                        }

                                        {customerStep &&
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="form-box card-box">
                                                        <h4 className="fw-bold">ENTER CUSTOMER DETAIL </h4>
                                                        <form onSubmit={formSubmit}>
                                                            <div className="row mt-4">
                                                                <div className="col-xl-2 col-lg-2">
                                                                    <div className="label-wrap">
                                                                        <h4>Email</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-10 col-lg-10">
                                                                    <div className="inpt-wrap">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="sitec"
                                                                            placeholder="cutomer email address"
                                                                            name="customer_email"
                                                                            value={credentials.customer_email}
                                                                            onChange={handleInput}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-xl-2 col-lg-2">
                                                                    <div className="label-wrap">
                                                                        <h4>Phone No.</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-10 col-lg-10">
                                                                    <div className="inpt-wrap">
                                                                        <PhoneInput
                                                                            value={phone}
                                                                            onChange={handlePhoneInput}>
                                                                        </PhoneInput>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 text-end">
                                                                <button type="button" className="btn btn-prim w-auto m-2" disabled={isFormSubmit} onClick={() => { HandleCustomerBck() }}>
                                                                    Back
                                                                </button>
                                                                <button type="submit" className="btn btn-prim w-auto " disabled={isFormSubmit}>
                                                                    {isFormSubmit ? "Processing..." : "Continue"}
                                                                </button>
                                                            </div>
                                                        </form>

                                                    </div>
                                                </div>
                                                <div className="col-lg-5 text-end">
                                                    <img src={pickupImg} />
                                                </div>
                                            </div>
                                        }
                                        {finishStep &&
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="form-box card-box text-center">
                                                        <img src={completeImg} style={{width:"100px"}} />
                                                        <h6>Your locker pickup has been booked successfully</h6>
                                                        <p>an email sent to your customer</p>
                                                        <Link to={redirectUrl}>View pickup detail</Link>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 text-end">
                                                    <img src={finishImg} />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </div>


    );

};

export default ExternalPickup;
