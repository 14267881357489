
import "./App.css";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/auth/Login";
import SignUp from "./components/auth/SignUp";
import ForgotPassword from "./components/auth/ForgotPassword";
import PrivateRoute from "./components/auth/PrivateRoute";
import Dashboard from "./components/template/dashboard/Dashboard";
import LiveStreaming2 from "./components/template/dashboard/LiveStreaming2";
import QrcodeEdit from "./components/template/SIteQRCodes/QrcodeEdit";
import UserList from './components/template/User/UserList';
import AddUser from './components/template/User/Add';
import UserEdit from "./components/template/User/UserEdit";
import AddSite from './components/template/Site/Add';
import Site from './components/template/Site/Site';
import EditSite from './components/template/Site/Edit';
import AddTpes from "./components/template/QRCodeType/AddTpes";
import TypesList from "./components/template/QRCodeType/TypesList";
import EditTypes from "./components/template/QRCodeType/EditTypes";
import AddLockerBank from "./components/template/LockerBank/Add";
import LockerBankList from "./components/template/LockerBank/List";
import EditLockerBank from "./components/template/LockerBank/Edit";
import AddLocker from "./components/template/Lockers/Add";
import LockerList from "./components/template/Lockers/List";
import EditLocker from "./components/template/Lockers/Edit";
import LockerDetail from "./components/template/Lockers/LockerDetail";
import PostVideo from "./components/template/content/Add";
import MediaEdit from "./components/template/content/MediaEdit";
import VideoList from "./components/template/content/List";
import AddSiteQrCodes from "./components/template/SIteQRCodes/Add";
import SiteQrCodesList from "./components/template/SIteQRCodes/List";
import ScreenView from "./components/template/screens/ScreenView";
import ScreenDetail from "./components/template/screens/ScreenDetail";
import AddScreen from "./components/template/screens/AddScreen";
import VideoCall from "./components/template/screens/VideoCall";
import Containers from "./components/template/containers/Containers";
import Pricing from "./components/template/pricing/List";
import Booking from "./components/template/booking/Booking";
import List from "./components/template/booking/List";
import DropOff from "./components/template/externalDropOff/Step1";
import UpdateDropOff from "./components/template/externalDropOff/Step2";
import SuplyLocker from "./components/template/externalDropOff/SuplyLocker";
import InternalSuccess from "./components/template/externalDropOff/InternalSuccess";
import ScanContainer from "./components/template/externalDropOff/ScanContainer";
import ContainerScan from "./components/template/externalDropOff/ContainerScan";
import ContainersList from "./components/template/containers/ContainersList";
import ContainersDetails from "./components/template/containers/ContainerDetails";
import ContainerEditScan from "./components/template/containers/ContainerEditScan";
import LockerPricing from "./components/template/pricing/Pricing"
import EditBooking from "./components/template/booking/EditBooking";
import BookingDetails from "./components/template/booking/BookingDetails";
import PaymentList from "./components/template/payment/List";
import ScanDetail from "./components/template/containers/ScanDetail";
import EditContainer from "./components/template/containers/EditContainer";
import DropOffBookingList from "./components/template/drop-off-booking/List";
import EditDropOffBooking from "./components/template/drop-off-booking/EditDropOffBooking";
import DropOffBookingDetail from "./components/template/drop-off-booking/DropOffBookingDetail";
import Label from "./components/template/ocr_labels/Label";
import ExternalPickup from "./components/template/pickup/ExternalPickup";
import PickupList from "./components/template/pickup/PickupList";
import PickupDetail from "./components/template/pickup/PickupDetail";
import PickupEdit from "./components/template/pickup/PickupEdit";
import PickupOrder from "./components/template/pickup/PickupOrder";
import PickupOrderDetail from "./components/template/pickup/PickOrderDetail";
import Profile from "./components/template/profile/Profile";
import EditProfile from "./components/template/profile/EditProfile";
import LabelList from "./components/template/shippingLabel/LabelList";
import LabelAdd from "./components/template/shippingLabel/LabelAdd";
import LabelEdit from "./components/template/shippingLabel/LabelEdit";
import ShippingTrack from "./components/template/shippingLabel/ShippingTrack";
import LockerEdit from "./components/template/Lockers/LockerEdit";

import EditCompany from "./components/template/company/Edit";
import CompanyList from "./components/template/company/List";
import CompanyAdd from "./components/template/company/Add";
import EditInventory from "./components/template/inventory/Edit";
import InventoryList from "./components/template/inventory/List";
import InventoryAdd from "./components/template/inventory/Add";
import RequestedProduct from "./components/template/inventory/RequestedProduct";

import AssignLocker from "./components/template/LockerBank/AssignLocker";
import AvailableProductList from "./components/template/products/AvailableProductList";
import AvailableRequested from "./components/template/products/AvailableRequested";
import UserRequestedProduct from "./components/template/products/RequestedProduct";
import OnOrderProducts from "./components/template/products/OnOrderProducts";
import LockersHistory from "./components/template/products/LockersHistory";

const stripePromise = loadStripe('pk_test_51Kz28VJ02vlPsvqy3qqGNCDF35q0So64eWCrVSlqMPUuTlaheNWS14EYwkK4Y55rzJ6vKPbudQ7I9HOnd8Hnpoun00sfcQ6LAe');
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/user/add" element={<AddUser />} />
            <Route exact path="/user/edit/:id" element={<UserEdit />} />
            <Route exact path="/user/list" element={<UserList />} />
            <Route exact path="/site/list" element={<Site />} />
            <Route exact path="/add/site" element={<AddSite />} />
            <Route exact path="/edit/site/:id" element={<EditSite />} />
            <Route exact path="/qr/type/add" element={<AddTpes />} />
            <Route exact path="/qr/type/list" element={<TypesList />} />
            <Route exact path="/edit/qr/type/:id" element={<EditTypes />} />
            <Route exact path="/locker/bank/add" element={<AddLockerBank />} />
            <Route exact path="/locker/bank" element={<LockerBankList />} />
            <Route exact path="/edit/locker/bank/:id" element={<EditLockerBank />} />
            <Route exact path="/locker/:id" element={<LockerDetail />} />
            <Route exact path="/locker/add" element={<AddLocker />} />
            <Route exact path="/lockers" element={<LockerList />} />
            <Route exact path="/edit/locker/:id" element={<EditLocker />} />
            <Route exact path="/media/content/add" element={<PostVideo />} />
            <Route exact path="/media/contents" element={<VideoList />} />
            <Route exact path="/media/content/edit/:id" element={<MediaEdit />} />
            <Route exact path="/add/site/qr/codes" element={<AddSiteQrCodes />} />
            <Route exact path="/site/qr/codes/list" element={<SiteQrCodesList />} />
            <Route exact path="/screen/view/:id" element={<ScreenDetail />} />
            <Route exact path="/video/call" element={<VideoCall />} />
            <Route exact path="/configure/screen/:id" element={<AddScreen />} />
            <Route exact path="/view/containers/:id" element={<Containers />} />
            <Route exact path="/current/pricing" element={<Pricing />} />
            <Route exact path="/payment" element={<Elements stripe={stripePromise}><Booking /></Elements>} />
            <Route exact path="/booking/list" element={<List />} />
            <Route exact path="/ext/drop-off/:id" element={<DropOff />} />
            <Route exact path="/update/drop-off/:id" element={<Elements stripe={stripePromise}><UpdateDropOff /></Elements>} />
            <Route exact path="/dropoff/supply/int/:id" element={<SuplyLocker />} />
            <Route exact path="/dropoff/int/:id" element={<InternalSuccess />} />
            <Route exact path="/container/scan/:bookingId" element={<ContainerScan />} />
            <Route exact path="/container/list/:id" element={<ContainersList />} />
            <Route exact path="/container/details/:id" element={<ContainersDetails />} />
            <Route exact path="/container/edit/:id" element={<ContainerEditScan />} />
            <Route exact path="/external/pickup/add/:id" element={<ExternalPickup />} />
            <Route exact path="/pickup/list" element={<PickupList />} />
            <Route exact path="/pickup/detail/:id" element={<PickupDetail />} />
            <Route exact path="/pickup/edit/:id" element={<PickupEdit />} />
            <Route exact path="/pickup/order" element={<PickupOrder />} />
            <Route exact path="/pickup/order/:id" element={<PickupOrderDetail />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/profile/edit" element={<EditProfile />} />
            <Route exact path="/shipping/label/list" element={<LabelList />} />
            <Route exact path="/shipping/label/edit/:id" element={<LabelEdit />} />
            <Route exact path="/live/shipping/tracking" element={<ShippingTrack />} />
            <Route exact path="/forgot/password" element={<ForgotPassword />} />
            <Route exact path="/add/site/qr/codes" element={<AddSiteQrCodes />} />
            <Route exact path="/site/qr/codes/list" element={<SiteQrCodesList />} />
            <Route exact path="/screen/view/:id" element={<ScreenDetail />} />
            <Route exact path="/video/call/:id" element={<VideoCall />} />
            <Route exact path="/view/containers/:id" element={<Containers />} />
            <Route exact path="/current/pricing" element={<Pricing />} />
            <Route exact path="/pricing" element={<LockerPricing />} />
            <Route exact path="/booking/add" element={<Elements stripe={stripePromise}><Booking /></Elements>} />
            <Route exact path="/booking/list" element={<List />} />
            <Route exact path="/edit/booking/:id" element={<EditBooking />} />
            <Route exact path="/booking/details/:id" element={<BookingDetails />} />
            <Route exact path="/payment" element={<Elements stripe={stripePromise}><Booking /></Elements>} />
            <Route exact path="/ext/drop-off/:id" element={<DropOff />} />
            <Route exact path="/update/drop-off/:id" element={<Elements stripe={stripePromise}><UpdateDropOff /></Elements>} />
            <Route exact path="/container/edit/:id" element={<ContainerEditScan />} />
            <Route exact path="/container/item/edit/:id" element={<EditContainer />} />
            <Route exact path="/scan" element={<ScanContainer />} />
            <Route exact path="/containerDetails" element={<ScanDetail />} />
            <Route exact path="/payment/list" element={<PaymentList />} />
            <Route exact path="/drop/off/booking/list" element={<DropOffBookingList />} />
            <Route exact path="/edit/drop/off/booking/:id" element={<EditDropOffBooking />} />
            <Route exact path="/drop/off/booking/details/:id" element={<DropOffBookingDetail />} />
            <Route exact path="/label" element={<Label />} />
            <Route exact path="/locker/edit/:id" element={<LockerEdit />} />
            <Route exact path="/shipping/label/add" element={<LabelAdd />} />
            <Route exact path="/streaming/:id" element={<LiveStreaming2 />} />
            <Route exact path="/edit/site/qr/codes/:id" element={<QrcodeEdit />} />
             {/* company routes */}
             <Route exact path="/company/list" element={<CompanyList />} />
             <Route exact path="/add/company" element={<CompanyAdd />} />
             <Route exact path="/edit/company/:id" element={<EditCompany />} />
              {/* inventory routes */}
              <Route exact path="/product/list" element={<InventoryList />} />
             <Route exact path="/add/product" element={<InventoryAdd />} />
             <Route exact path="/edit/product/:id" element={<EditInventory />} />
             <Route exact path="/requested/product/list" element={<RequestedProduct />} />
             
             {/* locker bank */}
             <Route exact path="/assign/locker/bank/:id" element={<AssignLocker />} />
             {/* list of all available products */}
             <Route exact path="/all/available/products/:id" element={<AvailableProductList />} />
             <Route exact path="/all/requested/products/:id" element={<AvailableRequested />} />
             <Route exact path="/user/requested/product/:id" element={<UserRequestedProduct />} />
             <Route exact path="/on/products/product/:id" element={<OnOrderProducts />} />
             <Route exact path="/lockers/history/details/:id" element={<LockersHistory />} />
             
          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default App;
