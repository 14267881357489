import React, { useEffect, useRef, useState } from "react";
// import { CopyToClipboard } from "react-copy-to-clipboard"
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import NotificationIcon from "../../../images/icon-notifications.png";
import BackIcon from '../../../assets/icons/icon-back.svg'
import LargeScreenIcon from "../../../images/large-switch.png";
import DecVideCall from "../../../images/dec-video-call.png";
import MuteSwitch from "../../../images/mute-switch.png";
import UnmuteSwitch from "../../../images/mike.png";
import UnmuteVideo from "../../../images/muteVideo.png";

import RcvrImg from "../../../images/reciver-img.png";
import ShadowImg from "../../../images/shadow-video.png";
import SwitchCamera from "../../../images/switch-camera.png";
import SwtchVideo from "../../../images/vid-switch.png";
import PersonalImg from "../../../images/personal-img.png";
import Peer from "simple-peer";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setConnection } from "../../Redux/Authentication";
import { notification } from "antd";
import io from "socket.io-client";

const url = process.env.REACT_APP_APP_BACK_URL;

const socket = io.connect(url);
function VideoCall() {
  const myButtonRef = useRef(null);
  const remoteButtonRef = useRef(null);

  const location = useLocation();
  const dispatch = useDispatch();
  const connection = useSelector((state) => state.connection);
  const queryParams = new URLSearchParams(location.search);
  const remote = queryParams.get("admin");
  const { roomId, id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [me, setMe] = useState("");
  const [stream, setStream] = useState(null);
  const [userstream, setUserStream] = useState();
  const [receivingCall, setReceivingCall] = useState(false);
  const [caller, setCaller] = useState("");
  const [callerSignal, setCallerSignal] = useState();
  const [callAccepted, setCallAccepted] = useState(false);
  const [idToCall, setIdToCall] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [callDeclined, setcallDeclined] = useState(false);
  const [name, setName] = useState("");
  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef(null);
  const [videoCallUser, setVideoCallUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const senderId = '';
  const [messageInput, setMessageInput] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const countRef = useRef(newMessage);
  const [isAudioMuted, setIsAudioMuted] = useState(true);
  const [audioTrack, setAudioTrack] = useState(null);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [videoTrack, setVideoTrack] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  let navigate = useNavigate();
  const handleGoBack = () => {
    window.history.back(); // This will go back to the previous page in the browser's history.
  };
  useEffect(() => {
    async function setStreamFun() {
      try {
        const strm = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        const audioTrack = strm.getAudioTracks()[0];

        // Mute the audio track by default
        audioTrack.enabled = true;
        const videoTrack = strm.getVideoTracks()[0];

        // Mute the video track by default
        videoTrack.enabled = true;

        setStream(strm);
        setAudioTrack(audioTrack);
        setVideoTrack(videoTrack);
        if (myVideo.current) {
          myVideo.current.srcObject = strm;
        }

        socket.emit("callUser2", {
          caller: senderId + "1a",
        });
        socket.on("connectCall", (data) => {
          const delayInMilliseconds = 5000; // 10 seconds
          // Update elapsed time every second
          let intervalId = "";
          const timeoutId = setTimeout(() => {
            if (myButtonRef.current) {
              myButtonRef.current.click();
            }
          }, delayInMilliseconds);

          // Make sure to clear the timeout if the component unmounts
          return () => {
            clearTimeout(timeoutId);
          };
        });
        socket.on("callUser1", (data) => {
          setReceivingCall(true);
          setCaller(data.from);
          setName(data.name);
          setCallerSignal(data.signal);

          const delayInMilliseconds = 5000; // 10 seconds

          const timeoutId = setTimeout(() => {
            if (remoteButtonRef.current) {
              remoteButtonRef.current.click();
            }
          }, delayInMilliseconds);

          // Make sure to clear the timeout if the component unmounts
          return () => clearTimeout(timeoutId);
        });
        socket.on("destroyConnection", (data) => {
          console.log("destroy connection");
          destroyConnection();
        });
        // window.addEventListener('beforeunload', (event) => {
        //   // Save call-related information to persistent storage
        //   localStorage.setItem('callInfo', JSON.stringify({

        //     stream,
        //     myVideo,
        //     userVideo,
        //     connectionRef,
        //     // Add other relevant data
        //   }));
        // });
        let newConnection;

        // if (connection) {
        //   // Re-establish connection if it exists
        //   newConnection = connection;
        // } else {
        //   // Initialize a new connection
        //   newConnection = new Peer({
        //     initiator: true,
        //     trickle: false,
        //     stream: stream,
        //   });

        //   dispatch(setConnection(newConnection)); // Store the connection in Redux
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    setStreamFun();

    socket.on("updateChatMessages", (data) => {
      showNewMessageBox(data.senderId);
    });
    socket.on("pageRefesh", (data) => {
      localStorage.removeItem("streamDone");
      window.location.reload();
    });

    if (localStorage.getItem("streamDone")) {
      socket.emit("pageHardRefesh", {
        caller: id + "1a",
      });
      // Reload the page
    }
    // Function to add the event listener

    getUserMessages(id);
    return () => {
      localStorage.removeItem("streamDone");
      changeStatusAvailable();
      if (stream) {
        // Release camera permissions when the component unmounts
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [senderId, id]);
  useEffect(() => {
    if (remote) {
      socket.emit("recieverOnline", {
        caller: id + "1a",
      });
    }
  }, []);
  useEffect(() => {
    // Start the timer when the call is accepted
    if (callAccepted && !callEnded) {
      const intervalId = setInterval(updateElapsedTime, 1000); // Update every second
      changeStatus();
      // Make sure to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [callAccepted, callEnded]);
  const callUser = async (roomId) => {
    try {
      // Create a promise that resolves when the stream is set
      const streamPromise = new Promise((resolve, reject) => {
        const peer = new Peer({
          initiator: true,
          trickle: false,
          stream: stream,
        });
        peer.on("signal", (data) => {
          socket.emit("callUser", {
            caller: roomId + "1a",
            remote: remote,
            signalData: data,
            name: "imran",
          });
        });

        peer.on("stream", (stream) => {
          setUserStream(stream);
          if (userVideo.current) {
            userVideo.current.srcObject = stream;
          }
          resolve(); // Resolve the promise when the stream is set
        });

        socket.on("callAccepted", (signal) => {
          setCallAccepted(true);
          peer.signal(signal);
        });

        connectionRef.current = peer;
        localStorage.setItem("streamDone", true);
      });

      // Wait for the stream to be set before continuing
      await streamPromise;

      // Continue with other functionality that depends on the stream
      // ...
    } catch (error) {
      // Handle any errors that may occur during the process
      console.error("Error in callUser:", error);
    }
  };

  const answerCall = (roomId) => {
    setCallAccepted(true);
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream: stream,
    });
    peer.on("signal", (data) => {
      socket.emit("answerCall", { signal: data, to: roomId + "1a" });
    });
    peer.on("stream", (strme) => {
      setUserStream(strme);
      if (userVideo.current) {
        // Set the srcObject of the video element
        userVideo.current.srcObject = strme;
      }
    });
    peer.signal(callerSignal);
    connectionRef.current = peer;
    localStorage.setItem("streamDone", true);
  };
  const handleSendNotification = (userId, message) => {
    // Send the notification to the server via WebSocket
    socket.emit("sendNotification", { userId, message });
  };
  const leaveCall = () => {
    socket.emit("destroyCallConnection", {
      caller: id + "1a",
    });
    setCallEnded(true);
    connectionRef.current.destroy();
    navigate("/chats");
    changeStatusAvailable();
  };
  const destroyConnection = () => {
    setCallEnded(true);
    setcallDeclined(true);
    changeStatusAvailable();
    // Pause the video playback
    if (userVideo.current) {
      userVideo.current.pause();
    }

    // Set the video element's srcObject to null
    if (userVideo.current) {
      userVideo.current.srcObject = null;
    }

    // Set the userVideo ref to null
    userVideo.current = null;
    connectionRef.current.destroy();
    const delayInMilliseconds = 5000; // 10 seconds
    // Update elapsed time every second
    const timeoutId = setTimeout(() => {
      navigate("/chats");
    }, delayInMilliseconds);

    // Make sure to clear the timeout if the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
    // navigate('/chats');
  };
  const toggleAudioMute = () => {
    if (audioTrack) {
      audioTrack.enabled = !isAudioMuted;
      setIsAudioMuted(!isAudioMuted);
    }
  };
  const toggleVideoMute = () => {
    if (videoTrack) {
      videoTrack.enabled = !isVideoMuted;
      setIsVideoMuted(!isVideoMuted);
    }
  };
  function updateElapsedTime() {
    setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
  }

  const getUserMessages = async (id) => {
    try {
      setNewMessage(id);
      countRef.current = id;
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(`${url}/api/chat/message/list/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setVideoCallUser(id);
      setIsLoading(false);

      const json = await response.json();

      if (json.success) {
        setMessages(json.chats);
        setSelectedUser(json.selectedUser);
        setShowMessageBox(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/chat/message/send`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        message: messageInput,
        sender_id: senderId,
        receiver_id: selectedUser._id,
      }),
    });
    setIsLoading(false);

    const json = await response.json();
    if (json.success) {
      setMessageInput("");
      getUserMessages(selectedUser._id);
      const receiverId = selectedUser._id;
      socket.emit("updateReciverChat", { senderId });
      socket.emit("sendMessageNotification", { receiverId });
      notification.success({
        message: "Success",
        description: "Message sent Successfully",
        duration: 3,
      });
    } else {
      notification.success({
        message: "Failed",
        description: "failed to send message",
        duration: 3,
      });
    }
  };
  const showNewMessageBox = async (id) => {
    if (countRef.current === id) {
      getUserMessages(id);
    }
  };
  const changeStatus = async (e) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    const response = await fetch(`${url}/api/auth/user/update/chat_status/${senderId}`, {
      mode: "cors",
      method: "GET",
      headers: headers,
    });
  };
  const changeStatusAvailable = async (e) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    const response = await fetch(
      `${url}/api/auth/user/update/chat_status/available/${senderId}`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );
  };

  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";

  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">

              <div className="col col-12">

                <div className="general-dashboard mt-4">
                  <div className="container-fluid">
                    <div className="dashboard-top-row add-new-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap user-back-wrap">
                            <Link to="/chats">
                              <div className="back-icon">
                                <img onClick={handleGoBack} src={BackIcon} alt="" />
                              </div>
                            </Link>
                            <div className="user-back-det">
                              <h3>Video call</h3>

                              <p>
                                You’re now talking to{" "}
                                <b>
                                  {" "}
                                  {selectedUser && selectedUser !== ""
                                    ? selectedUser.name
                                    : "no user"}
                                </b>
                                {/* {
                                  stream &&(
                                    <> */}
                                <a
                                  ref={myButtonRef}
                                  onClick={() => callUser(id)}
                                ></a>
                                <a
                                  ref={remoteButtonRef}
                                  onClick={() => answerCall(id)}
                                ></a>
                                {/* </>
                                  )
                                } */}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end"></div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="row">
                      <div className="col-12">
                        <div className="video-screen-sec">
                          <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col col-8">
                              <div className="vide-sec-whole-wrap">
                                <div className="vide-img-wrap">
                                  <img
                                    src={ShadowImg}
                                    alt=""
                                    className="shadow-img"
                                  />

                                  {!callEnded ? (
                                    <video
                                      ref={userVideo}
                                      autoPlay
                                      playsInline
                                    />
                                  ) : (
                                    <video autoPlay playsInline />
                                  )}
                                </div>
                                <div className="top-bar-call-vid">
                                  <div className="call-time">
                                    <p>{`${Math.floor(elapsedTime / 60)}:${(
                                      elapsedTime % 60
                                    )
                                      .toString()
                                      .padStart(2, "0")}`}</p>
                                  </div>
                                  <div className="small-screen">
                                    <video
                                      className="persona-img"
                                      muted
                                      ref={myVideo}
                                      autoPlay
                                    />
                                  </div>
                                </div>
                                <div className="bottom-bar-call">
                                  {!callAccepted && (
                                    <h5>Call connecting ...</h5>
                                  )}
                                  {callDeclined && (
                                    <h5>
                                      Call declined by{" "}
                                      <b>
                                        {" "}
                                        {selectedUser && selectedUser !== ""
                                          ? selectedUser.name
                                          : "no user"}
                                      </b>
                                    </h5>
                                  )}
                                </div>
                              </div>
                              <div className="bottom-bar-call seperat-row">
                                {callAccepted && !callEnded ? (
                                  <div className="icons-wrap-end-call">
                                    {/* <div className="icon-call">
                                      <img src={SwtchVideo} alt="" />
                                    </div> */}
                                    <div
                                      className="icon-call"
                                      onClick={toggleVideoMute}
                                    >
                                      {isVideoMuted ? (
                                        <img
                                          src={SwtchVideo}
                                          alt="Unmute Video"
                                        />
                                      ) : (
                                        <img
                                          src={UnmuteVideo}
                                          alt="Mute Video"
                                        />
                                      )}
                                    </div>
                                    {/* <div className="icon-call">
                                      <img src={MuteSwitch} alt="" />
                                    </div> */}

                                    <div className="icon-call dec-call-video">
                                      <img
                                        src={DecVideCall}
                                        alt=""
                                        onClick={leaveCall}
                                      />
                                    </div>

                                    <div
                                      className="icon-call"
                                      onClick={toggleAudioMute}
                                    >
                                      {isAudioMuted ? (
                                        <img src={UnmuteSwitch} alt="" />
                                      ) : (
                                        <img src={MuteSwitch} alt="" />
                                      )}
                                    </div>
                                    {/* <div className="icon-call">
                                      <img src={SwitchCamera} alt="" />
                                    </div>
                                    <div className="icon-call">
                                      <img src={LargeScreenIcon} alt="" />
                                    </div> */}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="col-lg-2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default VideoCall;
