import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../Redux/Authentication";
import { notification } from "antd";
import io from "socket.io-client";
import styles from "./style.module.css";
import logo from "../../images/logo.svg";
import login_hero from "../../images/login-hero.jpg";
// const url = process.env.REACT_APP_APP_BACK_URL;
// const socket = io.connect(url);
const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const url = process.env.REACT_APP_APP_BACK_URL;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");

    setIsLoading(true);

    const response = await fetch(`${url}/api/auth/login`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        email: credentials.email,
        password: credentials.password,
        base_url: base_url
      }),
    });
    setIsLoading(false);

    const json = await response.json();
    if (json.success) {
      const user = json.user;
      const expirationTime = Math.floor(Date.now() / 1000) + 7200;
      localStorage.setItem("token", json.authToken);
      localStorage.setItem("token-expiration", expirationTime);
      // socket.emit("login", json.user._id);
      dispatch(loginSuccess(json.user));
      if (user.role === "tenant") {
        navigate("/tenant/appointment/list");
      } else if (user.role === "user") {
        const booking = localStorage.getItem("booking");
        localStorage.removeItem("booking");
        if (booking) {
          navigate(`/update/drop-off/${booking}`);
        } else {
          navigate(`/booking/add`);
        }

      } else {
        navigate("/dashboard");
      }
      notification.success({
        message: "Success",
        description: "Login Successfully",
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };

  return (
    <div className={styles.heading}>
      <section className={styles.sign_sec}>
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 my-auto">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8 p-0 mob-2">
                  <div className={styles.sign_hero_wrap}>
                    <img src={login_hero} alt="logo hero" />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-8 p-0 text-center mob-1">
                  <div className={styles.cont_form_wrap}>
                    <div className={styles.signup_wrap}>
                      <div className={styles.logo_wrap}>
                        <img src={logo} alt="logo" />
                      </div>
                      <h1>Welcome Back</h1>
                      <form className="form-wrap" onSubmit={formSubmit}>
                        <div className="row justify-content-center">
                          <div className="col-xl-12 col-lg-12">
                            <div className="row">
                              <div className="col-xl-6 col-lg-8 col-6 ">
                                <div className="mb-3">
                                  <label htmlFor="email" className="form-label">
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={credentials.email}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                              <div className="col-xl-6 col-lg-8 col-6 ">
                                <div className="mb-3">
                                  <label htmlFor="pass" className="form-label">
                                    password
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    value={credentials.password}
                                    onChange={handleInput}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <button type="submit" className="btn btn-prim">login</button> */}
                            <button type="submit" className="btn btn-prim" disabled={isLoading}>
                              <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Login
                            </button>
                            <div className="row">
                              <div className="col-xl-6 col-lg-8 col-6 text-start">
                                <Link to="/signup">Sign Up</Link>
                              </div>
                              <div className="col-xl-6 col-lg-8 col-6 text-end">
                                <Link to="/forgot/password">
                                  Forgot password
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
