import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../assets/icons/icon-back.svg'
import styles from './style.module.css'
import { useSelector } from 'react-redux';
import { notification } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
const AssignLocker = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    getSiteUser();
  }, []);
  const [credentials, setCredentials] = useState({ user: "" });
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/l_bank/assignLockerBank`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          user: credentials.user,
          l_bank:id,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    if (json.success) {
        setCredentials({
            user: "",
          });
      notification.success({
        message: 'Success',
        description: 'Locker Bank Assigned Successfully',
        duration: 3
      });
    } else {
      notification.error({
        message: 'Failed',
        description: json.error,
        duration: 3
      });
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  const getSiteUser = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/l_bank/getLockerBankUsers/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      
        console.log(json);
        setData(json);
      
      
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/locker/bank">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Assign Locker Bank</h3>
                  <p>
                    Enter the credentials to assign bank to site
                    users
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-12">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <div className="label-wrap">
                      <h4>Users</h4>
                      <p>Select user assign to assign locker bank</p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <div className="inpt-wrap">
                      <select
                        name="user"
                        value={credentials.user}
                        onChange={handleInput}
                        required
                      >
                        <option value="" selected>
                          Choose User
                        </option>
                        {data.map((per) => (
                        <option
                          key={per._id}
                          value={per._id}
                        >
                          {per.name}
                        </option>
                       ))} 
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i> Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AssignLocker;
