import React, { useEffect, useState, useRef } from 'react';
import VideoPlayer from './VideoPlayer';
import { Link, useParams, useNavigate } from 'react-router-dom';
import "../../../locker.css"
import styles from "../dashboard/style.module.css";
import { Button, Modal, notification } from 'antd';
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/Authentication";
import io from "socket.io-client";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);


const ScreenDetail = () => {
    const { id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [screenFiles, setScreenFiles] = useState([]);
    const [screenQrcodes, setScreenQrcodes] = useState([]);
    const url = process.env.REACT_APP_APP_BACK_URL;
    const [isLoading, setIsLoading] = useState(false);
    const videoRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({});
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [pin, setPin] = useState('');
    const [error, setError] = useState(false);
    const formRef = useRef();

    const fetchData = async () => {
        try {
            let headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("Access-Control-Allow-Origin", "*");
            headers.append("Access-Control-Allow-Credentials", "true");
            headers.append("auth-token", localStorage.getItem("token"));
            setIsLoading(true);
            const response = await fetch(`${url}/api/screen/view/${id}`, {
                mode: "cors",
                method: "GET",
                headers: headers,
            });
            setIsLoading(false);

            const json = await response.json();
            if (json.success) {
                setScreenFiles(json.files);
                setScreenQrcodes(json.qrTypes);
                setUser(json.user);
            }
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [id]);

    const showModal = () => {
        setOpen(true);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    const handleChange = (e) => {
        setError(false);
        setPin(e.target.value);
    };

    const handleOk = async () => {

        if (pin === '') {
            setError(true);
            return
        }

        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");
        headers.append("auth-token", localStorage.getItem("token"));
        setConfirmLoading(true);
        const response = await fetch(`${url}/api/l_bank/pin/login`, {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                pin: pin,
                id: id

            })
        });
        setConfirmLoading(false);

        const json = await response.json();
        if (json.success) {
            const user = json.user;
            const expirationTime = Math.floor(Date.now() / 1000) + 7200;
            localStorage.setItem("token", json.authToken);
            localStorage.setItem("token-expiration", expirationTime);
            dispatch(loginSuccess(json.user));
            navigate(json.url)

            notification.success({
                message: "Success",
                description: "Login Successfully",
                duration: 3,
            });

        } else {

            notification.error({
                message: "Failed",
                description: json.error,
                duration: 3,
            });
        }
    };

    const videoChat = async () => {
        const userId = user._id;
        const senderId = id;
        socket.emit("sendNotification", { userId, senderId });
        navigate(`/video/call/${senderId}`);

    }


    return (

        // <div className="col col-dashboard">

        <div className={styles.body_wrap}>
            <div className="container-detail-video m-0 ">
                <div className="video-slider">
                    {screenFiles.length > 0 &&
                        <VideoPlayer videoList={screenFiles} />
                    }
                </div>
                {/* <div className="image-slider">
                  <img src={background} alt="bg"/>
                  </div> */}
                <div className="scanner-button-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="scanner-img-body ">
                                {screenQrcodes.length > 0 &&
                                    screenQrcodes.map((qr, index) => (
                                        <div className="start-to-scan">
                                            <img src={`/${qr.image}`} alt="startscan" />
                                            <p>{qr.name}</p>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                        <div className="col-md-6 text-end">
                            <div className='scan-button' id="scanner-buttons">
                                <button className="btn btn-prim " onClick={videoChat}>Video Chat</button><br />
                                <button className="btn btn-prim " type="button" onClick={showModal}>Admin Login</button>
                            </div>
                        </div>

                        <Modal title="Enter Pin" open={open} confirmLoading={confirmLoading}
                            onOk={handleOk} onCancel={handleCancel} >

                            <div className='col-12'>
                                <div className="inpt-wrap">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="sitec"
                                        placeholder="pin code"
                                        name="pin"
                                        value={pin}
                                        onChange={handleChange}
                                        required
                                    />
                                    {error &&
                                        <p className='text-danger' >This field is required</p>
                                    }

                                </div>
                            </div>

                        </Modal>

                    </div>
                </div>
            </div>
        </div>
        // </div>

    )
}

export default ScreenDetail
