import React, { useEffect, useState } from "react";
import styles from './style.module.css';
import SearchIcon from '../../../images/search.png'
import FilterIcon from '../../../images/filter.png'
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Button, Popconfirm, message } from 'antd';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
const LabelList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState([]);
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [isImgDownloading, setIsImgDownloading] = useState({});
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [itemLoadingStates, setItemLoadingStates] = useState({});
  const [doorLoadingStates, setDoorLoadingStates] = useState({});
  const [credentials, setCredentials] = useState({ name: "" });
  const handleInput = (e) => {
    setCurrentPage(1);
    setCredentials((prevCredentials) => {
      const updatedCredentials = { ...prevCredentials, [e.target.name]: e.target.value };
      fetchData(updatedCredentials);
      return updatedCredentials;
    });
  };
  useEffect(() => {
    fetchData(credentials);
  }, [currentPage]);
  const fetchData = async (fetchCredentials) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/shipping/label/list?page=${currentPage}&limit=${itemsPerPage}&name=${fetchCredentials.name}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      console.log(json);
      if (json.labels.length > 0) {
        setIsDisplay(true);
        setData(json.labels);
      } else {
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);
    } catch (error) {
      console.error(error);
    }
  };
  const generateShippingLabelPDF = async (id) => {
    try {
      setIsDownloading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: true,
      }));
      const response = await fetch(`${url}/api/shipping/pdf/download/${id}?base_url=${base_url}`, {
        mode: "cors",
        method: "GET",
      });
      setIsDownloading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
      if (response.ok) {
        const blob = await response.blob();
        // Create a blob object and initiate the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'shipping-label.pdf';
        a.click();
      } else {
        console.error('Error generating PDF');
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const generateShippingLabelImg = async (id) => {
    try {
      setIsImgDownloading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: true,
      }));
      const response = await fetch(`${url}/api/shipping/img/download/${id}`, {
        mode: "cors",
        method: "GET",
      });
      setIsImgDownloading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
      if (response.ok) {
        const blob = await response.blob();
        // Create a blob object and initiate the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'shipping-label.png';
        a.click();
      } else {
        console.error('Error generating PDF');
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  const handleResetBtn = () => {
    const updatedCredentials = {
      name: '',
    };
    // Update state and then call fetchData
    setCredentials(updatedCredentials);
    setCurrentPage(1);
    // Call fetchData with the updated credentials
    fetchData(updatedCredentials);
  };
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');
        const response = await fetch(`${url}/api/shipping/label/delete/${id}`, {
          mode: 'cors',
          method: 'DELETE',
          headers: headers,
        });
        if (response.ok) {
          const json = await response.json();
          if (json.success) {
            fetchData(credentials);
            Swal.fire(
              'Deleted!',
              'Label deleted successfully!',
              'success'
            );
          } else {
            Swal.fire(
              'Error',
              json.error,
              'error'
            );
          }
        } else {
          Swal.fire(
            'Error',
            'An error occurred while deleting the record.',
            'error'
          );
        }
      }
    } catch (error) {
      console.log('Error deleting record:', error);
      Swal.fire(
        'Error',
        'An error occurred while deleting the record.',
        'error'
      );
    }
  };
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const formattedDate = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formatted = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
    return formatted;
  };
  function toggleFilterSection() {
    setIsFilterVisible(!isFilterVisible);
  }
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap">
                <h3>Shipping Labels</h3>
                <p>
                  List of all the Label added in the system
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="filter-right-sec">
                <div className="filter-wrap">
                  <div className="filter-box" onClick={toggleFilterSection}>
                    <img src={FilterIcon} alt="" />
                  </div>
                </div>
                <Link to="/shipping/label/add" className='btn btn-prim w-auto'>Add Shipping Label</Link>
              </div>
            </div>
          </div>
          {isFilterVisible && (
            <>
              <motion.div
                className={`row mt-5 filter-section`}
                initial={{ opacity: 0 }} // Initial state (hidden)
                animate={{ opacity: isFilterVisible ? 1 : 0 }} // Animate opacity based on isFilterVisible
                transition={{ duration: 1.2, delay: 0.1 }}
              >
                <div className="col-12">
                  <div className="filter-right-sec filter-wrap-chat">
                    <div className="search-wrap">
                      <input type="search" placeholder="shipping name" name="name" value={credentials.name} onChange={handleInput} />
                      <div className="search-box">
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Link
                      to="" onClick={handleResetBtn}
                      className="btn btn-prim w-auto"
                    >
                      RESET
                    </Link>
                  </div>
                </div>
              </motion.div>
            </>
          )}
        </div>
        {/* row */}
        <div className="table-wrap">
          <div className="row">
            <div className="col-12">
              <div className={styles.card_box}>
                <div className={styles.tables_wrap}>
                  <div className="table-responsive">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>SHIPPING NAME</th>
                          <th>TO NAME</th>
                          <th>FROM NAME </th>
                          <th>TO ADDRESS</th>
                          <th>FROM ADDRESS</th>
                          <th>SHIPPING DATE</th>
                          <th>BARCODE</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && (
                          <>
                            <tr>
                              <td colSpan={8} className="text-center" style={{ background: "transparent" }}>
                                <HashLoader
                                  color={`#0b0d41`}
                                  loading={isLoading}
                                  size={40}
                                  aria-label="Loading Spinner"
                                  data-testid="loader"
                                />
                              </td>
                            </tr>
                          </>
                        )}
                        {isDisplay &&
                          data.map((item, i) => (
                            <motion.tr
                              key={item._id}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 1.2, delay: i * 0.1 }}
                            >
                              <td>{i + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.to_name}</td>
                              <td>{item.from_name}</td>
                              <td>{item.to_address}</td>
                              <td>{item.from_address}</td>
                              <td>
                                {item.date ? (
                                  formattedDate(item.date)
                                ) : (
                                  <>Nill</>
                                )}
                              </td>
                              <td>
                                <img src={item.barcode} style={{ width: "150px" }} />
                              </td>
                              <td className="text-end">
                                <div className="btn-group btn-group" role="group">
                                  <Link to={`/shipping/label/edit/${item._id}`} className="btn btn-warning" ><i className="fa fa-edit" style={{ fontSize: "20px" }}></i></Link>
                                  <button className="btn btn-danger " onClick={() => handleDelete(item._id)}><i className="fa fa-trash" style={{ fontSize: "16px" }}></i></button>
                                  <button className="btn btn-success "
                                    onClick={(e) => { e.preventDefault(); generateShippingLabelPDF(item._id); }}
                                    disabled={isDownloading[item._id]}>
                                    {isDownloading[item._id] ? (
                                      <i className="fa fa-spinner fa-spin" style={{ fontSize: "20px" }}></i>
                                    ) : (
                                      <i className="fa fa-file-pdf-o" style={{ fontSize: "20px" }}></i>
                                    )}
                                  </button>
                                  <button className="btn btn-primary "
                                    onClick={(e) => { e.preventDefault(); generateShippingLabelImg(item._id); }}
                                    disabled={isImgDownloading[item._id]}>
                                    {isImgDownloading[item._id] ? (
                                      <i className="fa fa-spinner fa-spin" style={{ fontSize: "20px" }}></i>
                                    ) : (
                                      <i className="fa fa-download" style={{ fontSize: "20px" }}></i>
                                    )}
                                  </button>
                                </div>
                              </td>
                            </motion.tr>
                          ))
                        }
                        {isNotFound && (
                          <tr>
                            <td colSpan={9} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="table-num">
                    {data.length > 0 && (
                      <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={totalPages}
                        forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                        onPageChange={handlePageChange}
                        containerClassName={' dig-num'}
                        pageClassName={'dig-num'}
                        previousClassName={'num-btns'}
                        nextClassName={'num-btns'}
                        disabledClassName={'pagination-disabled'}
                        activeClassName={'pagination-active'}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LabelList;
