import React from 'react'
import "../../../locker.css"
import styles from "../dashboard/style.module.css"
import qrscannerr from "../../../images/qrcode-payment.png"
import qrscannerr2 from "../../../images/qrcode-pay2.png"

const ScanContainer = () => {
  return (
      <div className={styles.heading}>
          <div className={styles.body_wrap}>
            <div className='row p-5'>
              <div className='col-md-6'>
                <div className='scan-container'>
                   <div className='qr-heading'>
                        <h4>Scan Container QR</h4>
                    </div>

                    <div className='scan-body'>
                      <div className='body-text'>
                       <p>Scan Container QR For Drop Off</p>
                       <div className='scan-imge mb-3 mt-4'>
                         <img src={qrscannerr} alt=''/>
                       </div>
                       <div className='scan-button mb-4'>
                       <button className="btn btn-prim" type="submit">Start Scan</button>
                       <button className="btn btn-prim" type="submit">Scan An Image File</button>
                       </div>
                       <p>Remaining Container : 0</p>
                       <p>Scan Container : 03</p>

                      </div>
                    </div>
                </div>
              </div>
              <div className='col-md-6 '>
                 <div className='scan-imge2 mt-5 '>
                 <img src={qrscannerr2} alt='qrscanner'/>
                 </div>
                
              </div>
            </div>
          </div>
      </div>
  )
}

export default ScanContainer
