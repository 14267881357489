import React, { useEffect, useState } from "react";
import styles from './style.module.css';
import SearchIcon from "../../../assets/icons/search.svg";
import FilterIcon from '../../../assets/icons/icon-filter.svg'
import pickupImg from '../../../images/pickup2.png'
import Sidebar from "../../partials/Sidebar";
import Header from "../../partials/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Button, Popconfirm, message } from 'antd';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
import ContentLoader from 'react-content-loader'
import BackIcon from '../../../assets/icons/icon-back.svg'
const PickupOrderDetail = () => {
  let navigate = useNavigate();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const { id } = useParams();
  const react_base_url = process.env.REACT_APP_BASE_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLockerOpen, setIsLockerOpen] = useState(false);
  const [credentials, setCredentials] = useState({
    _id: "", order_number: "", pickup_code: "",locker_id:"", locker_number: "", locker_relay: "",
    user_name: "", user_email: "", customer_name: "", customer_email: "", site_url:"", status: "", createdAt: "", updatedAt: ""
  });
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      setIsNotFound(false);
      const response = await fetch(`${url}/api/pickup/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      if (json.success) {
        setCredentials({ ...json.pickup });
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleConfirmPickup = async () => {
    try {
      
      const result = await Swal.fire({
        title: 'Please confirm',
        text: "You have pickup the order",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, I confirm',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      });
  
      if (result.isConfirmed) {
  
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');
  
        const response = await fetch(`${url}/api/pickup/confirm`, {
          mode: 'cors',
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            id: id,
            base_url:react_base_url
          }),
        });
  
        if (response.ok) {
        const json = await response.json();
        if(json.success){
          fetchData();
          Swal.fire(
            'Completed',
            'Pickup has been completed',
            'success'
          );
          navigate('/pickup/list')
        }else{
          Swal.fire(
            'Error',
            json.error,
            'error'
          );
        }
          
        } else {
          Swal.fire(
            'Error',
            'An error occurred while deleting the record.',
            'error'
          );
        }
      }
    } catch (error) {
      console.log('Error deleting record:', error);
      Swal.fire(
        'Error',
        'An error occurred while deleting the record.',
        'error'
      );
    }
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  const HandleOpenLocker = async (id)=>{
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLockerOpen(true);
    const response = await fetch(
      `${url}/api/locker/relay/state/update`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id:id,
          state:state
        }),
      }
    );
 
    setIsLockerOpen(false)
    const json = await response.json();
    console.log(json)
    if (json.success) {
      notification.success({
        message: 'Success',
        description: json.message,
        duration: 3
      });
    } else {
      notification.error({
        message: 'Failed',
        description: json.error,
        duration: 3
      });
    }
  }
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Pickup Order</h3>
                          </div>
                        </div>
                        <div className="col-lg-6 text-end">
                        </div>
                      </div>
                    </div>
                    {/* row */}
                    <div className="row">
                      <div className="col-lg-5 text-start">
                        <div className="form-box card-box" style={{ padding: "0px" }}>
                        <div className={`text-center ${styles.pickup_order_heading2}`}>
                                <span className={styles.pickup_label2}>Pickup Code</span>
                                <div className={styles.pickup_detail_order2}>{credentials.pickup_code}</div>
                        </div>
                        <div className="text-center">
                            <div className="row m-2">
                                <div className="col-12">
                                    <span className={styles.pickup_label}>Please open door, pickup your order 
                                        <br/> & <br/> 
                                    confirm pickup.</span>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-prim btn-transparent w-auto  mt-3" disabled={isLockerOpen} onClick={() => {HandleOpenLocker(credentials.locker_id)}}>Open Locker</button>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-prim w-auto  mt-3 mb-5" onClick={handleConfirmPickup}>Confirm Pickup</button>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                      <div className="col-lg-7 text-end">
                        <img src={pickupImg} style={{ width: "100%", height: "100%" }} />
                      </div>
                    </div>
                  </div>
                </div>
  );
};
export default PickupOrderDetail;
