import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import SearchIcon from "../../../assets/icons/search.svg";
import FilterIcon from "../../../assets/icons/icon-filter.svg";
import NextIcon from "../../../images/next.png";
import PreviousIcon from "../../../images/prev.png";
import QrIconTable from "../../../images/icon-qr-tb.png";
import styles from "./style.module.css";
import ProfileImg from "../../../images/profile.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Button, Popconfirm, message } from "antd";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";

const Pricing = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [getLockerS, setLockerSize] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [items, setItems] = useState([
    { _id: "",size:"" ,hourly: "", daily: "", monthly: "" },
    // Add more items as needed
  ]);
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);

      const response = await fetch(`${url}/api/locker/getLockerSize`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.locker_size.length > 0) {
        setIsDisplay(true);
        setData(json.locker_size);
        setItems(json.locker_size);
      } else {
        setIsNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        setConfirmLoading(true);

        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Credentials", "true");

        const response = await fetch(`${url}/api/type/qr/type/delete/${id}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        });

        if (response.ok) {
          setConfirmLoading(false);
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire("Deleted!", "Qrcode type deleted successfully!", "success");
        } else {
          setConfirmLoading(false);
          Swal.fire(
            "Error",
            "An error occurred while deleting the record.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log("Error deleting record:", error);
      setConfirmLoading(false);
      Swal.fire(
        "Error",
        "An error occurred while deleting the record.",
        "error"
      );
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };
  //   const handleInput = (e) => {
  //     console.log(e.target.value);
  //     // setCredentials({ ...credentials, [e.target.name]: e.target.value });
  //   };
  
  return (
    <div className={styles.heading}>
      <div className="body-wrap">
        <section className={styles.sidebar_wrap}>
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col col-sidebar">
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />
                <div className="general-dashboard">
                  <div className="container-fluid">
                    <div className="dashboard-top-row">
                      <div className="row">
                        <div className="col-lg-6 my-auto">
                          <div className="user-wrap">
                            <h3>Current Pricing</h3>
                            <p>List of all the sizes and its price</p>
                          </div>
                        </div>
                      
                     
                    </div>
                    </div>
                    {/* row */}
                    <div className="table-wrap">
                      <div className="row">
                        <div className="col-12">
                          <div className={styles.card_box}>
                            <div className={styles.tables_wrap}>
                              <div className="table-responsive">
                                <table className="table align-middle">
                                  <thead>
                                    <tr>
                                      <th>locker size</th>
                                      <th>per hour($)</th>
                                      <th>per day($)</th>
                                      <th>per month($)</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {isLoading && (
                                      <>
                                        <tr>
                                          <td
                                            colSpan={6}
                                            className="text-center"
                                            style={{
                                              background: "transparent",
                                            }}
                                          >
                                            <HashLoader
                                              color={`#0b0d41`}
                                              loading={isLoading}
                                              size={40}
                                              aria-label="Loading Spinner"
                                              data-testid="loader"
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                    {isDisplay &&
                                      items.map((item, i) => (
                                        <motion.tr
                                          key={item._id}
                                          initial={{ opacity: 0 }}
                                          animate={{ opacity: 1 }}
                                          transition={{
                                            duration: 1.2,
                                            delay: i * 0.1,
                                          }}
                                        >
                                          <td>
                                            <div className="usr_det_tb">
                                              {/* <div className="img_wrap_qr_tb">
                                                <img className="not_prof_img" src={QrIconTable} alt="" />
                                              </div> */}
                                              <h6><span> {item.size}</span></h6>
                                            </div>
                                          </td>
                                          <td>
                                            {" "}
                                           <h6> {item.hourly}</h6>
                                          </td>
                                          <td>
                                            {" "}
                                            <h6> {item.daily}</h6>
                                          </td>
                                          <td>
                                          <h6>{item.monthly}</h6>{" "}
                                          </td>
                                        </motion.tr>
                                      ))}
                                    {isNotFound && (
                                      <tr>
                                        <td colSpan={3} className="text-center">
                                          No Data Found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-lg-12">
                        <p>
                            <a data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                <i className="fa fa-info text-info"></i> View Lockers Size Chart
                            </a>
                        </p>
                        <div className="collapse" id="collapseExample">
                            <div className="card card-body">
                                <div className="table-responsive">
                                    <table className="table table-sm">
                                        <tr>
                                            <td>S</td>
                                            <td>M</td>
                                            <td>L</td>
                                            <td>XL</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="size-container bg-info" style={{height: '125px',width:'100%'}}>
                                                    <div className="centered">
                                                        <h5>Small</h5>
                                                        <small><b>29H x 29W X 12D</b></small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="size-container bg-info" style={{height: '185px',width:'100%'}}>
                                                    <div className="centered">
                                                        <h4>Medium</h4>
                                                        <small><b>28H x 32W X 18D</b></small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="size-container bg-info" style={{height: '275px',width:'100%'}}>
                                                    <div className="centered">
                                                        <h4>Large</h4>
                                                        <small><b>72H x 30W X 18D</b></small>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="size-container bg-info" style={{height: '325px',width:'100%'}}>
                                                    <div className="centered">
                                                        <h4>Ex Large</h4>
                                                        <small><b>46"H x 72W X 18D</b></small>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pricing;
