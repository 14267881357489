import React, { useState, useEffect } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Html5QrcodeScanner } from "html5-qrcode";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
import qrscannerr from "../../../images/qrcode-payment.png";
import qrscannerr2 from "../../../images/qrcode-pay2.png";
const ContainerScan = () => {
  let navigate = useNavigate();
  const [scanResult, setScanResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const isAuthenticated = useSelector((state) => state.user);
  const id = isAuthenticated.site;
  const [isScanner, setIsScanner] = useState(false);
  const [qrcode, isQrcode] = useState("");
  const [siteName, isSiteName] = useState("");
  const [appointmentIds, setAppointmentIds] = useState([]);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [scannerInitialized, setScannerInitialized] = useState(false);
  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const [siteData, setSiteData] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [totalContainer, setTotalContainer] = useState(0);
  const [scanedContainer, setScanedContainer] = useState(0);
  const { bookingId } = useParams();
  let scanner;
  useEffect(() => {
    let interval;
    const handleKeyDown = (evt) => {
      if (interval) clearInterval(interval);
      if (evt.code === "Enter") {
        if (barcode) handleBarcode(barcode);
        setBarcode("");
        return;
      }
      if (evt.key !== "Shift")
        setBarcode((prevBarcode) => prevBarcode + evt.key);
      interval = setInterval(() => setBarcode(""), 20);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode]);
  useEffect(() => {
    fetchData();
    // containersScan('65269985b046ed871fde764a')
    if (!scannerInitialized) {
      scanner = new Html5QrcodeScanner("reader", {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
      });
      scanner.render(success, error);
      setScannerInitialized(true);
    }
    function success(result) {
      scanner.clear();
      setIsLoading(true);
      containersScan(result);
    }
    function error(err) {
      console.warn(err);
    }
  }, [id, scannerInitialized]);
  useEffect(() => {
    if (authenticationFailed) {
      // Reinitialize the scanner if authentication fails
      setScannerInitialized(false);
      setAuthenticationFailed(false);
    }
  }, [authenticationFailed]);
  // const appointmentAuthentication =  (result) => {
  //   const currentId = result.toString();
  //   setIsLoading(false);
  //   const foundAppointment = appointmentIds.some(appointment => appointment._id === currentId);
  //   if (foundAppointment) {
  //     console.log("app found");
  //     navigate(`/appointment/detail/page/${currentId}`);
  //   } else {
  //     console.log('appointment not found');
  //     notification.error({
  //       message: 'Not Found',
  //       description: 'Appointment Not Found',
  //       duration: 3
  //     });
  //     setAuthenticationFailed(true);
  //   }
  // };
  const handleBarcode = async (scannedBarcode) => {
    // Handle the scanned barcode data here
    try {
      const siteId = id;
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/appointments/info/${scannedBarcode}/${siteId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
          },
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error(`Failed to fetch site with id ${scannedBarcode}`);
      }
      const json = await response.json();
      if (json.success) {
        navigate(`/appointment/detail/page/${scannedBarcode}`);
      } else {
        notification.error({
          message: "Not Found",
          description: json.error,
          duration: 3,
        });
        // setAuthenticationFailed(true);
      }
    } catch (error) {
      console.error(error);
    }
    // You can perform any further processing or API requests here
  };
  const containersScan = async (result) => {
    try {
      const containerId = result.toString();
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/booking/scanContainers/${bookingId}/${containerId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
          },
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error(`Failed to fetch site with id `);
      }
      const json = await response.json();
      if (json.success) {
        if (json.available === "yes") {
          setTotalContainer(json.total_containers);
          setScanedContainer(json.scanned_containers);
          setScannerInitialized(false);
        } else {
          navigate(`/container/details/${bookingId}`);
          // navigate(`/container/list/${bookingId}`);
        }
      } else {
        notification.error({
          message: "Not Found",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(
        `${url}/api/booking/getScanerCount/${bookingId}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      const json = await response.json();
      if (json.success) {
        setTotalContainer(json.total_containers);
        setScanedContainer(json.scanned_containers);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const readerStyle = {
    width: "500px",
    position: "relative",
    padding: "0px",
  };
  const qrShade = {
    position: "absolute",
    borderWidth: "62px 124px",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.48)",
    boxSizing: "border-box",
    inset: "0px",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className={styles.body_wrap}>
      <div className="row ">
        <div className="col-md-7">
          <div className="scan-container">
            <div className="qr-heading">
              <h4>Scan Container QR</h4>
            </div>
            <div className="scan-body">
              <div className="body-text">
                <p>Scan Container QR For Drop Off</p>
                <div className="scan-imge mb-3 mt-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="scanning-section">
                        {isLoading ? (
                          <div className="text-center">
                            <HashLoader
                              color={`#0b0d41`}
                              loading={isLoading}
                              size={40}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                        ) : (
                          <div
                            id="reader"
                            style={readerStyle}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p>Remaining Container : {totalContainer}</p>
                <p>Scaned Container : {scanedContainer}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 ">
          <div className="scan-imge2 mt-5 ">
            <img src={qrscannerr2} alt="qrscanner" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContainerScan;
