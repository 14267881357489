import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { useSelector } from "react-redux";
import { notification } from "antd";
import styles from "./style.module.css";
import BackIcon from '../../../assets/icons/icon-back.svg'
import Select from 'react-select';
import { Link, useNavigate } from "react-router-dom";
const Add = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const url = process.env.REACT_APP_APP_BACK_URL;
  const front_url = process.env.REACT_APP_BASE_URL;
  const [appendedHTML, setAppendedHTML] = useState("");
  useEffect(() => {
    getTypes();
  }, []);
  const [qrTypes, setQrTypes] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [wifi, setwifi] = useState(false);
  const [wayfinder, setwayfinder] = useState(false);
  const [def, setdefault] = useState(true);
  const [qr_types, set_qr_types] = useState();
  const [credentials, setCredentials] = useState({
    name: "",
    qr_type: "",
    text: "",
    tenants: "",
    ssid: "",
    password: "",
    encryption: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleQrTypes = (e) => {
    const selectedText = e.target.options[e.target.selectedIndex].text;
    set_qr_types(selectedText.toLowerCase());
    if (e.target.name === 'qr_type' && selectedText.toLowerCase() === 'wifi') {
      setwifi(true);
      setwayfinder(false);
      setdefault(false);
    } else if (e.target.name === 'qr_type' && selectedText.toLowerCase() === 'other') {
    } else if (e.target.name === 'qr_type') {
      setwifi(false);
      setwayfinder(false);
      setdefault(true);
    }
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  }
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(`${url}/api/type/createSiteQR`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        name: credentials.name,
        qr_types_name: qr_types,
        site_id: isAuthenticated.site,
        qr_type: credentials.qr_type,
        text: credentials.text,
        tenants: selectedTenants,
        ssid: credentials.ssid,
        url: front_url,
        password: credentials.password,
        encryption: credentials.encryption,
      }),
    });
    setIsLoading(false);
    const json = await response.json();
    console.log(json);
    if (json.success) {
      setCredentials({
        name: "",
        qr_type: "",
        text: "",
        tenants: "",
        ssid: "",
        password: "",
        encryption: "",
        qr_types_name: ""
      });
      notification.success({
        message: "Success",
        description: "Site QR Created Successfully",
        duration: 3,
      });
    } else {
      setIsLoading(false);
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const getTypes = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/type/qr/types`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      setQrTypes(json);
    } catch (error) {
      console.error(error);
    }
  };
  const iocnStyle = {
    display: isLoading ? 'inline-block' : 'none',
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/site/qr/codes/list">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Create Site Qr Code</h3>
                  <p>
                    List of all the sites qrcode in the system
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Name</h4>
                      <p>Enter the precise name of user</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="fuserName"
                        placeholder="Enter name of type"
                        name="name"
                        value={credentials.name}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Site QR Type</h4>
                      <p>Select site qr type</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select
                        name="qr_type"
                        required
                        value={credentials.qr_type}
                        onChange={handleQrTypes}
                      >
                        <option value="">Choose QR Types</option>
                        {qrTypes.map((per) => (
                          <option data-id={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              {def &&
                <>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>QR Text</h4>
                          <p>enter text for qrcode</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <textarea
                            className="form-control"
                            id="text"
                            name="text"
                            placeholder="Enter text for qr"
                            value={credentials.text}
                            onChange={handleInput}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                </>
              }
              {wifi &&
                <>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>SSID</h4>
                          <p>enter ssid for wifi network</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            name="ssid"
                            placeholder="Enter SSID"
                            className="form-control"
                            value={credentials.ssid}
                            onChange={handleInput}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Password</h4>
                          <p>enter password for wifi network</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <input
                            type="text"
                            name="password"
                            placeholder="Enter Wifi Password"
                            className="form-control"
                            value={credentials.password}
                            onChange={handleInput}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                  <div className="col-lg-6">
                    {/* input row */}
                    <div className="row">
                      <div className="col-xl-5 col-lg-5">
                        <div className="label-wrap">
                          <h4>Encryption: </h4>
                          <p>enter Encryption:  for wifi network</p>
                        </div>
                      </div>
                      <div className="col-xl-7 col-lg-7">
                        <div className="inpt-wrap">
                          <select
                            name="encryption"
                            value={credentials.encryption}
                            onChange={handleInput}
                          >
                            <option value="">Select Encryption</option>
                            <option value="WPA">WPA/WPA2/WPA3</option>
                            <option value="WEP">WEP</option>
                            <option value="no_password">None</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* input row */}
                  </div>
                </>
              }
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <a
                    href=""
                    className="btn btn-prim btn-transparent w-auto"
                  >
                    Reset Form
                  </a>
                  <button type="submit" disabled={isLoading} href="" className="btn btn-prim w-auto ms-5">
                    <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>{isLoading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Add;
