import React from "react";
import QrIconTable from "../../../images/icon-qr-tb.png";
import VideoIcon from "../../../images/icon-vid.png";

const VideoOrImage = ({ videoName }) => {
  const cleanedVideoName = videoName.replace(/[^\w.]/g, "").toUpperCase();

  const isValidVideo =
    cleanedVideoName.includes(".MP4") ||
    cleanedVideoName.includes(".MPV") ||
    cleanedVideoName.includes(".WMV") ||
    cleanedVideoName.includes(".FLV") ||
    cleanedVideoName.includes(".AVI") ||
    cleanedVideoName.includes(".AVCHD") ||
    cleanedVideoName.includes(".WEBM") ||
    cleanedVideoName.includes(".MKV");

  const fileExtension = cleanedVideoName.split('.').pop().toUpperCase();
  const fileType = isValidVideo ? 'Video' : 'Image';

  return (
    <>
      <td>
        {isValidVideo ? (
          <div className="usr_det_tb">
            <div className="media_bg video_bg">
              <img
                className="not_prof_img"
                src={VideoIcon}
                alt=""
              />
            </div>
          </div>
        ) : (
                  // <img src={`/uploads/screen/${videoName}`} alt="Preview" width="150"  height="120" />

          <div className="usr_det_tb">
            <div className="media_bg media_img_bg">
              <img
                className="not_prof_img"
                src={`/uploads/screen/${videoName}`}
                alt=""
              />
            </div>
          </div>
        )}
      </td>
      <td>{fileType}</td>
      <td>{fileExtension}</td>
    </>
  );
};

export default VideoOrImage;
