import React, { useEffect, useCallback, useState } from "react";
import Modal from "./Modal";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import DateDisplay from "../template/date/DateDisplay";
import NotificationIcon from "../../assets/icons/icon-notifications.svg";
import ProfileImg from "../../images/profile.png";
import DeclineIcon from "../../images/decline-call.png";
import AttendVoiceIcon from "../../images/attend-voice.png";
import AttendVideoIcon from "../../images/attend-video.png";
import ProfileImgReciever from "../../images/profile-reciever.png";
import HamburgerIcon from "../../assets/icons/icon-menubar.svg";
// const url = process.env.REACT_APP_APP_BACK_URL;
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
// import { useSocket } from "../templates/Sockets/SocketProvider";
const Header = (props) => {
  // const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [room, setRoom] = useState(null);
  const [sender, setSender] = useState(null);
  const [getNotification, setNotification] = useState([]);
  const [result, setResult] = useState("");

  const dispatch = useDispatch();
  const [msg, setMsg] = "";
  const isAuthenticated = useSelector((state) => state.user);
  const imageUrl = isAuthenticated.image;
  const [notificationData, setNotificationData] = useState([]);
 
  let subtitle;
 
  //   try {
  //     let headers = new Headers();
  //     headers.append("Content-Type", "application/json");
  //     headers.append("Access-Control-Allow-Origin", "*");
  //     headers.append("Access-Control-Allow-Credentials", "true");
  //     const response = await fetch(`${url}/api/video/chat/notifications/${isAuthenticated._id}`, {
  //       mode: "cors",
  //       method: "GET",
  //       headers: headers,
  //     });
  //     const json = await response.json();
  //     if(json.success){
  //       setNotification(json.notification);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }
  function joinRoom() {
    setIsOpen(false);
    navigate(`/video/call/${sender}/?admin=true`);
  }
  const handleLogout = async (e) => {
    const id = localStorage.getItem("token");
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", id);
    const response = await fetch(
      `${url}/api/auth/user/update/chatStatusOffline`,
      {
        mode: "cors",
        method: "GET",
        headers: headers,
      }
    );
  };

  return (
    <div>
      <header>
        <div className="row">
          <div className="col-lg-6">
            <div className="wel-not text-capitalize">
              <h1>Welcome Back {isAuthenticated.name}!</h1>
             
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="prof-sett">
              <div className="mob_btn_hambrgr" onClick={props.toggleActive}>
                <img src={HamburgerIcon} alt="" />
              </div>
              <div className="dropdown">
                <button
                  className="btn dropdown-toggle"
                  type="button"
                  id="dropdownNot"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    position: "relative",
                    ...(notificationData.length > 0 && {
                      paddingRight: "15px", // Adjust the padding to accommodate the indicator
                    }),
                  }}
                >
                  <img src={NotificationIcon} alt="" />
                  {notificationData.length > 0 && (
                    <span
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "10px",
                        height: "10px",
                        backgroundColor: "red",
                        borderRadius: "50%",
                      }}
                    ></span>
                  )}
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownNot">
                  <li className="notification_heads">
                    <div className="not_head_wrap">
                      <h3 className="not_head">notifications</h3>
                    </div>
                    <div className="not_num">
                      <span className="not_num_icon">
                        {notificationData.length}
                      </span>
                    </div>
                  </li>
                  {notificationData &&
                    notificationData.map((notification) => (
                      <li
                        className="dropdown-item"
                       
                      >
                        <ul className="not_ul">
                          <li className="not_list">
                            <div className="not_prof_wrap">
                              <img
                                className="not_prof_img"
                                src={ProfileImg}
                                alt=""
                              />
                            </div>
                            <div className="not_text_wrap">
                              <p className="not_cont_text">
                                <b>{notification.title}</b>
                                <br /> {notification.message}
                              </p>
                            </div>
                            <div className="not_time_wrap">
                              <span className="not_time_text">
                                <DateDisplay date={notification.createdAt} />
                              </span>
                            </div>
                          </li>
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="prof-wrap">
                <div className="det-prof">
                  <div className="name-wrap">
                    <h5 className="mb-0 text-capitalize">{isAuthenticated.name}</h5>
                    <p>{isAuthenticated.email}</p>
                  </div>
                </div>
                <div className="prof-img">
                  {isAuthenticated.image ? (
                    <Link to="/profile">
                      <div className="img-profile-wrap">
                        <img
                          src={isAuthenticated.image}
                          style={{
                            height: "40px",
                            width: "40px",
                            borderRadius: "5px",
                            marginBottom: "10px",
                          }}
                        />
                      </div>
                    </Link>
                  ) : (
                    <Link to="/profile">
                      <img src={ProfileImg} alt="" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Incoming video call
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}
          ></button>
        </div>
        <div className="modal-body">
          Somenone is inviting you for a video call..
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" onClick={joinRoom}>
            Join
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeModal}
          >
            Decline
          </button>
        </div>
      </Modal> */}
      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <div className="modal-body">
          <div className="row h-100">
            <div className="col-12 mt-auto">
              <div className="incoming-call-sec">
                <h3 className="text-center">Inviting you for a video chat</h3>
                <div className="call-detail-bar">
                  <div className="call-options-wrap">
                    <div className="icons-wrap-options">
                      <div className="icon-dec">
                        <img src={DeclineIcon} alt="" onClick={closeModal} />
                      </div>
                      <div className="icon-att-voice">
                        <img src={AttendVoiceIcon} alt="" onClick={joinRoom} />
                      </div>

                      <div className="icon-att-vid">
                        <img src={AttendVideoIcon} alt="" onClick={joinRoom} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Header;
