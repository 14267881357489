import React, { useEffect, useState } from "react";
import styles from "./style.module.css";

import "../../../locker.css"


import dataupload from "../../../images/upload-data.png";
import dropoff1 from "../../../images/dropoff1.png";
import dropoff2 from "../../../images/dropoff2.png";
import dropoff3 from "../../../images/dropoff3.png";
import dropoff4 from "../../../images/dropoff44.png";
import dropoff5 from "../../../images/dropoff5.png";
import { Link } from "react-router-dom";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Button, Popconfirm, message } from "antd";
import HashLoader from "react-spinners/ClipLoader";
// import Images from "../modal/Images";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import ProgressBar from "./ProgressBar";
import { notification } from "antd";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
const Step2 = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user);
  const role = isAuthenticated.role;
  const site = isAuthenticated.site;
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [step, setStep] = useState(true);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [getDrofOffBooking, setDrofOffBooking] = useState({});
  const [getTotal, setTotal] = useState({});
  const [getTotalContainer, setTotalContainer] = useState("1");
  const [getContainer_type, setContainer_type] = useState(null);
  const [getTotalAmount, setTotalAmount] = useState(null);
  const [getTotalDays, setTotalDays] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  // useEffect(() => {
  //   fetchData();
  // }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/booking/getDropBooking/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setTotal(json.drop_off);
        setDrofOffBooking(json.drop_off);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const containerCount = async (value) => {
    setContainer_type(value);
    setStep(false);
    setStep1(true);
  };
  // plus containers
  const add = async () => {
    setTotalContainer(parseInt(getTotalContainer) + 1);
  };
  // subtract containers
  const subtract = async () => {
    if (getTotalContainer > 1) {
      setTotalContainer(getTotalContainer - 1);
    }
  };
  const checkContainerPrice = async () => {
    const locker_bank = localStorage.getItem("locker_bank");
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    const response = await fetch(`${url}/api/booking/processBooking`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        container_type: getContainer_type,
        site: site,
        total_container: getTotalContainer,
        booking_id: id,
        locker_bank: locker_bank,
      }),
    });
    const json = await response.json();

    if (json.success) {
      setTotalAmount(json.data);
      setTotalDays(json.duration);
      setStep1(false);
      setStep2(true);
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to include it in your HTML file.
      return;
    }

    // Create a token or handle payment using the PaymentMethod.
    const result = await stripe.createToken(elements.getElement(CardElement));
    payment(result.token);
    if (result.error) {
      setError(result.error.message);
    } else {
      // Send the token to your server or handle it as needed.
      // You can call a function like stripeTokenHandler(result.token);
    }
  };
  const payment = async (token) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    headers.append("auth-token", localStorage.getItem("token"));
    const response = await fetch(
      `${url}/api/booking/drop_off/payment/create`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          stripeToken: token.id,
          total_amount:getTotalAmount,
          container_type:getContainer_type,
          total_boxes:getTotalContainer,
          id: id,
        }),
      }
    );
    const json = await response.json();
    if (json.success) {
      setIsLoading(false);
        notification.success({
          message: "Success",
          description: "Payment Created  Successfully",
          duration: 3,
        });
        if (getContainer_type.toLowerCase() === 'need_container') {
          
          navigate(`/dropoff/supply/int/${json.id}`) ;
      } else {
        navigate(`/dropoff/int/${json.id}`) ;
      }
      } else {
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
 }
 const iocnStyle = {
  display: isLoading ? "inline-block" : "none",
};

  // // Define the CSS classes based on the isActive state
  // const divClassName = isActive ? 'sidebar active' : 'sidebar';

  return (
    <div className={styles.heading}>
      <section className={styles.sidebar_wrap}>
        <div className="body-wrap">
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />
                <div className={styles.body_wrap}>
                  <div className="row m-2"></div>

                  <div className="progress-barr">{/* <ProgressBar /> */}</div>

                  <div className="containerr-main ">
                    <div className="container-dataa text-center">
                      {step && (
                        <>
                          <div className="image-container">
                            <img src={dropoff3} alt="Your Image" />
                          </div>
                          <div className="button-container">
                            <button
                              className="btn btn-prim"
                              onClick={() => containerCount("need_container")}
                            >
                              I Need a Container
                            </button>
                            <button
                              className="btn btn-prim"
                              onClick={() =>
                                containerCount("not_need_container")
                              }
                            >
                              I Have a Container
                            </button>
                          </div>
                        </>
                      )}
                      {step1 && (
                        <>
                          <div className="image-container">
                            <img src={dropoff4} alt="Your Image" />
                          </div>
                          <div className="button-container">
                            <h2>Select Container</h2>
                            <div className="counter-button">
                              <button
                                className="btn btn-prim"
                                onClick={subtract}
                              >
                                <i
                                  className="fa fa-minus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <h3
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                  marginTop: "8px",
                                }}
                              >
                                {getTotalContainer}
                              </h3>
                              <button className="btn btn-prim" onClick={add}>
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>

                            <button
                              className="btn btn-prim"
                              onClick={checkContainerPrice}
                            >
                              Checkout
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {step2 && (
                    <div className="checkout-scr">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="container-box">
                            <div className="pay-amount">
                              <h6>Amount To Pay</h6>
                              <h1>${getTotalAmount}</h1>
                              <p>
                                Total {getTotalContainer} Boxes {getTotalDays}{" "}
                                Days Storage
                              </p>
                            </div>

                            <div className="card-info">
                              <p>Enter Your Credit Card Info To Pay</p>
                              <form className="row " onSubmit={handleSubmit}>
                                {/* <div className="col-12">
                                <label for="inputnum4" class="form-label">
                                  Card Number
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="inputnum4"
                                />
                              </div>
                              <div className="col-md-8 mt-3">
                                <label for="inputnum4" class="form-label">
                                  Expire Date
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="inputnum4"
                                />
                              </div>
                              <div className="col-md-4 mt-3">
                                <label for="inputnum4" class="form-label">
                                  CVV
                                </label>
                                <input
                                  type="number"
                                  class="form-control"
                                  id="inputnum4"
                                />
                              </div> */}
                                <CardElement
                                  id="card-element"
                                  options={{
                                    style: {
                                      base: {
                                        fontSize: "16px",
                                        color: "#424770",
                                        "::placeholder": {
                                          color: "#aab7c4",
                                        },
                                      },
                                      invalid: {
                                        color: "#9e2146",
                                      },
                                    },
                                  }}
                                />
                                <div className="col-12 mt-3">
                                  <button
                                    className="btn btn-prim"
                                    type="submit"
                                    disabled={isLoading}
                                  >
                                    Book Now
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="payment-imagee">
                        <img src={dropoff5} alt="Your Image" />
                        </div>
                          
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Step2;
