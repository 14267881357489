import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { notification } from "antd";
import { Button, Popconfirm, message } from "antd";
import styles from "./style.module.css";
import SearchIcon from "../../../assets/icons/search.svg";
import FilterIcon from "../../../assets/icons/icon-filter.svg";
import InputMask from "react-input-mask";
import HashLoader from "react-spinners/ClipLoader";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import Images from "../modal/Images";
const ContainersList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const { id } = useParams();
  const isAuthenticated = useSelector((state) => state.user);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const [credentials, setCredentials] = useState({
    number: "",
  });
  const [phone, setPhone] = useState("");
  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const [searchStatus, setSearchStatus] = useState("all");
  const [filters, setFilters] = useState({ status: "all", date: "", name: "" });
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/container/getContainers/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.containers.length > 0) {
        setData(json.containers);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const iocnStyle = {
    display: isSubmit ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row">
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-12">
                {/* input row */}
                {data.length > 0
                  && data.map((item, key) => (
                    <>
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <h5>{item.unique_number}</h5>
                        </div>
                      </div>
                    </>
                  ))}
                {/* input row */}
              </div>
              <div className="col-lg-12 text-center">
                <div className="form-btn-wrap ">
                  <Link
                    to={`/container/details/${id}`}
                    disabled={isLoading}
                    className="btn btn-prim w-auto ms-5"
                  >
                    <i
                      className="fa fa-spinner fa-spin"
                      style={iocnStyle}
                    ></i>{" "}
                    CONFIRM CONTAINER NUMBERS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContainersList;
