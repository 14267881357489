import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import "./myown.css";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
// import Images from "../modal/Images";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "../../../locker.css";
import { notification } from "antd";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);
const InternalSuccess = () => {
  const navigate = useNavigate();
  const [getDrofOffBooking, setDrofOffBooking] = useState({});
  const [barcode, setBarcode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  let scanner;

  useEffect(() => {
    socket.emit("login", id);

    socket.on("lockerOpens", (data) => {
      navigate("/dashboard");
    });
    let interval;
    const handleKeyDown = (evt) => {
      if (interval) clearInterval(interval);
      if (evt.code === "Enter") {
        if (barcode) handleBarcode(barcode);
        setBarcode("");
        return;
      }

      if (evt.key !== "Shift")
        setBarcode((prevBarcode) => prevBarcode + evt.key);

      interval = setInterval(() => setBarcode(""), 20);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode]);
  useEffect(() => {
    fetchData();
    //    handleBarcode('652fcf23393869050433e3fd')
  }, []);

  // const [isActive, setIsActive] = useState(false);

  // // Function to toggle the active state
  // const toggleActive = () => {
  //   setIsActive(!isActive);
  // };

  // // Define the CSS classes based on the isActive state
  // const divClassName = isActive ? 'sidebar active' : 'sidebar';
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");

      const response = await fetch(`${url}/api/booking/getDropBooking/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setDrofOffBooking(json.drop_off);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleBarcode = async (scannedBarcode) => {
    // Handle the scanned barcode data here
    try {
      const locker_bank = localStorage.getItem("locker_bank");
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/booking/scanDropOff`, {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          id: scannedBarcode,
          locker_bank: locker_bank,
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch site with id ${scannedBarcode}`);
      }

      const json = await response.json();
      if (json.success) {
        notification.success({
          message: "Success",
          description: json.message,
          duration: 3,
        });
        socket.emit("lockerOpen", id);
      } else {
        notification.error({
          message: "Failed",
          description: json.error,
          duration: 3,
        });
      }
    } catch (error) {
      console.error(error);
    }
    // You can perform any further processing or API requests here
  };
  return (
    <div className={styles.heading}>
      <section className={styles.sidebar_wrap}>
        <div className="body-wrap">
          <div className="container-fluid px-0">
            <div className="row">
              <div className={`col col-sidebar `}>
                <Sidebar />
              </div>
              <div className="col col-dashboard">
                <Header />

                <div className={styles.body_wrap}>
                  <div className="main-body">
                    <div className="qr-heading">
                      <h4>Scan Qr Code</h4>
                    </div>
                    <div className="body-container">
                      <div className="row">
                        <div className="col-12">
                          <p>
                            {" "}
                            SCAN THIS QR AT BARCODE SCANNER TO OPEN LOCKER TO
                            PLACE YOUR ITEMS IN
                          </p>
                        </div>
                        <div className="col-12 text-center mt-3">
                          <img
                            src={`/${getDrofOffBooking.qr_code}`}
                            alt="Your Image"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InternalSuccess;
