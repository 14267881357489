import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HashLoader from "react-spinners/ClipLoader";
import "sweetalert2/dist/sweetalert2.min.css";
import io from "socket.io-client";
import { notification } from "antd";
const url = process.env.REACT_APP_APP_BACK_URL;
const socket = io.connect(url);

const Dashboard = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const role = isAuthenticated.role;
  const site = isAuthenticated.site;
  const id = isAuthenticated._id;
  

  const [availableProductList, setAvailableProduct] = useState([]);
  const [availableRequestProductList, setAvailableRequestProduct] = useState(
    []
  );
  const [getSite, setSite] = useState("");
  const [onOrderProduct, setOnOrderProduct] = useState([]);
  const [request, setRequest] = useState();
  const [requestedProduct, setRequestedProduct] = useState([]);
  const [getSelectedRquestedProduct, setSelectedRquestedProduct] = useState([]);
  const [getLockerHistory, setLockerHistory] = useState([]);
  const [getSiteList, setSiteList] = useState([]);
  const [availableNotFound, setAvailableNotFound] = useState(false);
  const [requestedNotFound, setRequestedNotFound] = useState(false);
  const [onOrderdNotFound, setOnorderdNotFound] = useState(false);
  const [requestedSelectedNotFound, setRequestedSelectedNotFound] =
    useState(false);
  const [lockerHistoryNotFound, setLockerHistoryNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isDisplay1, setIsDisplay1] = useState(false);
  const [lockerCount, setLockerCount] = useState([]);
  const [lockerSizes, setLockerSizes] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [bookingNotFound, setBookingNotFound] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingDisplay, setBookingDisplay] = useState(false);
  const [credentials, setCredentials] = useState({ name: "" });
  const [credentials1, setCredentials1] = useState({ name: "" });
  const [seletedProduct, setSeletedProduct] = useState({ product: "" });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [totalProduct, setTotalProduct] = useState(0);
  const [productQuantities, setProductQuantities] = useState({});
  const [totalProduct1, setTotalProduct1] = useState(0);
  const [productQuantities1, setProductQuantities1] = useState({});
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    socket.emit("user_login", isAuthenticated._id);
    socket.on("requestedProduct", (data) => {
      onOrderProductList(data.site);
      requestedProductData(data.site);
     });
     socket.on("requestedProductCompany", (data) => {
      handleSite(data.site);
     });
     
  }, [isAuthenticated]);
  useEffect(() => {
    if (role.toLowerCase() === "company admin") {
      siteList();
    } else {
      onOrderProductList(site);
      requestedProductData(site);
      lockerHistory(site);
      availableProduct(credentials, site);
      availableRequestedProduct(credentials1, site);
    }
  }, [site, role]);

  
  // handle site
  const handleSite = async (site) => {
    setSite(site);
    setIsActive(site);
    onOrderProductList(site);
    requestedProductData(site);
    lockerHistory(site);
    availableProduct(credentials, site);
  };
  //List of all site
  const siteList = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/sites/companySiteList?company=${
          isAuthenticated.company_id ? isAuthenticated.company_id : ""
        }`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.site_data.length > 0) {
        handleSite(json.site_data[0]._id);
        setSiteList(json.site_data);
      } else {
        setSiteList(json.site_data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const availableProduct = async (fetchCredentials, site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/getAvailableInventoryList?site=${site}&role=${role}&name=${fetchCredentials.name}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      if (json.inventory.length > 0) {
        setAvailableNotFound(false);
        setAvailableProduct(json.inventory);
      } else {
        setAvailableProduct([]);
        setAvailableNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const availableRequestedProduct = async (fetchCredentials, site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/getAvailableInventoryList?site=${site}&role=${role}&name=${fetchCredentials.name}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setRequestedNotFound(false);
        setAvailableRequestProduct(json.inventory);
      } else {
        setAvailableRequestProduct([]);
        setRequestedNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onOrderProductList = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading2(true);
      const response = await fetch(
        `${url}/api/inventory/onOrderProductList?site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.onOrderData.length > 0) {
        setIsLoading2(false);
        setOnorderdNotFound(false);
        setIsDisplay(true);
        setOnOrderProduct(json.onOrderData);
      } else {
        setIsDisplay(false);
        setIsLoading2(false);
        setOnOrderProduct([]);
        setOnorderdNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const requestedProductData = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/requestedProductData?site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setRequestedSelectedNotFound(false);
        setRequestedProduct(json.inventory);
        getRquestedProduct(json.inventory[0]._id);
      } else {
        setRequest("");
        setSelectedRquestedProduct([]);
        setRequestedSelectedNotFound(true);
        setRequestedProduct([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // locker history

  const lockerHistory = async (site) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading3(true);
      const response = await fetch(
        `${url}/api/inventory/lockerHistory?site=${site}&role=${role}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();
      setIsLoading3(false);
      if (json.lockerHistory.length > 0) {
        setLockerHistoryNotFound(false);
        setIsDisplay1(true);
        setLockerHistory(json.lockerHistory);
      } else {
        setIsDisplay1(false);
        setLockerHistoryNotFound(true);
        setLockerHistory([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // handle requested product
  const handleRquestedProduct = async (e) => {
    getRquestedProduct(e.target.value);
  };
  // get requested product list
  const getRquestedProduct = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/selectedRequest/${id}`,
        {
          mode: "cors",
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const json = await response.json();

      if (json.inventory.length > 0) {
        setRequest(id);
        setRequestedSelectedNotFound(false);
        setSelectedRquestedProduct(json.inventory);
      } else {
        setRequest("");
        setSelectedRquestedProduct(json.inventory);
        setRequestedSelectedNotFound(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleIncrement = (id) => {
    const updatedQuantities = { ...productQuantities };

    updatedQuantities[id] = (updatedQuantities[id] || 0) + 1;
    setProductQuantities(updatedQuantities);

    updateTotalProduct(updatedQuantities);
  };

  const handleDecrement = (id) => {
    const updatedQuantities = { ...productQuantities };
    updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
    setProductQuantities(updatedQuantities);
    updateTotalProduct(updatedQuantities);
  };

  const updateTotalProduct = (quantities) => {
    const total = Object.values(quantities).reduce(
      (acc, val) => acc + Math.abs(val),
      0
    );
    setTotalProduct(total);
  };

  const handleIncrement1 = (id) => {
    const updatedQuantities = { ...productQuantities1 };

    updatedQuantities[id] = (updatedQuantities[id] || 0) + 1;
    setProductQuantities1(updatedQuantities);

    updateTotalProduct1(updatedQuantities);
  };

  const handleDecrement1 = (id) => {
    const updatedQuantities = { ...productQuantities1 };
    if (updatedQuantities[id] > 0) {
      updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
      setProductQuantities1(updatedQuantities);
      updateTotalProduct1(updatedQuantities);
    }
  };
  const handleIncrement2 = (id, quantity) => {
    const updatedQuantities = { ...productQuantities1 };

    updatedQuantities[id] =
      (updatedQuantities[id] || parseInt(quantity, 10)) + 1;
    setProductQuantities1(updatedQuantities);

    updateTotalProduct1(updatedQuantities);
  };

  const handleDecrement2 = (id) => {
    const updatedQuantities = { ...productQuantities1 };
    if (updatedQuantities[id] > 0) {
      updatedQuantities[id] = (updatedQuantities[id] || 0) - 1;
      setProductQuantities1(updatedQuantities);
      updateTotalProduct1(updatedQuantities);
    }
  };
  const updateTotalProduct1 = (quantities) => {
    const total = Object.values(quantities).reduce(
      (acc, val) => acc + Math.abs(val),
      0
    );
    setTotalProduct1(total);
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading(true);
      const response = await fetch(
        `${url}/api/inventory/updateAvailableQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            productQuantities,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        setIsLoading(false);
        setProductQuantities("");
        setTotalProduct("");
        availableProduct(credentials, getSite ? getSite : site);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const handleRequestedProduct = async (e) => {
    try {
      e.preventDefault();
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      setIsLoading1(true);
      const response = await fetch(
        `${url}/api/inventory/requestProductQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            productQuantities1,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        const admin=data.admin;
        const companyAdmin=data.company_admin;
        socket.emit("sendAdmin", { admin,site });
        socket.emit("sendCompanyAdmin", { companyAdmin,site });
        setIsLoading1(false);
        setProductQuantities1("");
        setTotalProduct1("");
        onOrderProductList();
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  // order requested product
  const orderRquestedProduct = async (id, quantity) => {
    const updatedQuantities = { ...productQuantities1 };
    const quantities = updatedQuantities[id] ? updatedQuantities[id] : quantity;
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(
        `${url}/api/inventory/orderRequestProductQuantity`,
        {
          mode: "cors",
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            id: id,
            quantity: quantities,
          }),
        }
      );
      const data = await response.json();
      if (data.success) {
        requestedProductData(site);
        notification.success({
          message: "Success",
          description: data.message,
          duration: 3,
        });
      } else {
        notification.error({
          message: "Failed",
          description: data.error,
          duration: 3,
        });
      }
      // Reset form or handle success as needed
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const HandleOpenLocker = async (id) => {
    let state = "2";
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    // setIsLockerOpen(true);
    const response = await fetch(`${url}/api/locker/relay/state/update`, {
      mode: "cors",
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        id: id,
        state: state,
      }),
    });
    // setIsLockerOpen(false);
    const json = await response.json();
    if (json.success) {
      notification.success({
        message: "Success",
        description: json.message,
        duration: 3,
      });
    } else {
      notification.error({
        message: "Failed",
        description: json.error,
        duration: 3,
      });
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const iocnStyle1 = {
    display: isLoading1 ? "inline-block" : "none",
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const handleInput = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      availableProduct(updatedCredentials, site);
      return updatedCredentials;
    });
  };
  const handleInput1 = (e) => {
    // Use the callback function to ensure that the state is updated before calling fetchData
    setCredentials1((prevCredentials) => {
      const updatedCredentials = {
        ...prevCredentials,
        [e.target.name]: e.target.value,
      };
      availableRequestedProduct(updatedCredentials, site);
      return updatedCredentials;
    });
  };
  return (
    <div className={styles.body_wrap}>
      {/* <div className="dashboard-content m-2">
                    <h3>
                      Dashboard
                    </h3>
                    <div className="dashboard-content-wrap">
                      <p>HELLO TESTER|</p>
                      <div className="text-wrap">Admin</div>
                      <p>|DEMO LOCKERS|</p>
                    </div>
                  </div> */}
      <div className="dashboard-content m-2">
        <div className="dashboard-content-wrap">
          {role.toLowerCase() === "company admin" &&
            getSiteList.length > 0 &&
            getSiteList.map((item, key) => (
              <>
                <div
                  className={`text-wrap site-list-text text-capitalize ${
                    isActive === item._id ? "active" : ""
                  }`}
                  onClick={() => handleSite(item._id)}
                >
                  {item.name}
                </div>
              </>
            ))}
        </div>
      </div>
      {/* {isAuthenticated.role.toLowerCase() === "site user" && (
        <> */}
      <div className="main-containerbox m-3">
        <div className="data-box">
          <div className="top-heading-data">
            <div className="row">
              <div className="col-md-5">
                <h5 className="mt-2">Available Product</h5>
                <p className="description-text">
                  Select product quantities and click open locker to take out
                </p>
              </div>
              <div className="col-md-2 total-row">
                <p className="total-text text-center">Total</p>
                <p className="total-counter">
                  {totalProduct ? totalProduct : "0"}
                </p>
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control search-box"
                  id="fuserName"
                  name="name"
                  placeholder="Search.."
                  value={credentials.name}
                  onChange={handleInput}
                />
              </div>
              <div className="col-md-2">
                <button
                  onClick={handleSubmit}
                  disabled={isLoading}
                  className="btn btn-prim"
                >
                  <i className="fa fa-spinner fa-spin" style={iocnStyle}></i>
                  Open Locker
                </button>
              </div>
            </div>
          </div>
          {availableProductList.length === 4 && (
            <div className="row text-end">
              <Link
                to={`/all/available/products/${getSite ? getSite : site}`}
                className=" mt-2"
              >
                View All
              </Link>
            </div>
          )}
          <div className="booked-container-details mt-3">
            {availableProductList.length > 0 &&
              availableProductList.map((item, key) => (
                <>
                  <div className="booked-container">
                    <div className="booked-data">
                      <h5>{item.name}</h5>
                      <p>{item.quantity}</p>
                      <p className="product-counter">
                        {productQuantities[item._id]
                          ? productQuantities[item._id]
                          : "0"}
                      </p>

                      <div className="row">
                        <div className="col-md-12">
                          <button
                            onClick={() => handleDecrement(item._id)}
                            className="btn btn-danger btn-decrement"
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            <i className="fa fa-minus" aria-hidden="true"></i>
                          </button>

                          <button
                            onClick={() => handleIncrement(item._id)}
                            className="btn btn-primary btn-increment"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
          {availableNotFound && (
            <div className="row ">
              <div className="col-12">
                <p className="text-center text-danger">No data found.</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {role.toLowerCase() === "site user" && (
        <div className="main-containerbox m-3">
          <div className="data-box">
            <div className="top-heading-data">
              <div className="row">
                <div className="col-md-5">
                  <h5 className="mt-2">Requested Product</h5>
                  <p className="description-text">
                    Select product quantity and click button to request the
                    product
                  </p>
                </div>
                <div className="col-md-2 total-row">
                  <p className="total-text text-center">Total:</p>
                  <p className="total-counter">
                    {totalProduct1 ? totalProduct1 : "0"}
                  </p>
                </div>
                <div className="col-md-3">
                  <input
                    type="text"
                    className="form-control search-box"
                    id="fuserName"
                    name="name"
                    onChange={handleInput1}
                    placeholder="Search.."
                    value={credentials1.name}
                  />
                </div>
                <div className="col-md-2">
                  <button
                    onClick={handleRequestedProduct}
                    disabled={isLoading1}
                    className="btn btn-prim"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle1}></i>
                    Request Product
                  </button>
                </div>
              </div>
            </div>
            {availableRequestProductList.length === 4 && (
              <div className="row text-end">
                <Link to={`/all/requested/products/${site}`} className=" mt-2">
                  View All
                </Link>
              </div>
            )}
            <div className="booked-container-details mt-3">
              {availableRequestProductList.length > 0 &&
                availableRequestProductList.map((item, key) => (
                  <>
                    <div className="booked-container">
                      <div className="booked-data">
                        <h5>{item.name}</h5>
                        <p>{item.quantity}</p>
                        <p className="product-counter">
                          {productQuantities1[item._id]
                            ? productQuantities1[item._id]
                            : "0"}
                        </p>

                        <div className="row">
                          <div className="col-md-12">
                            <button
                              onClick={() => handleDecrement1(item._id)}
                              className="btn btn-danger btn-decrement"
                              style={{
                                marginRight: "10px",
                              }}
                            >
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            </button>

                            <button
                              onClick={() => handleIncrement1(item._id)}
                              className="btn btn-primary btn-increment"
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {requestedNotFound && (
              <div className="row ">
                <div className="col-12">
                  <p className="text-center text-danger">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {role.toLowerCase() !== "site user" && (
        <div className="main-containerbox m-3">
          <div className="data-box">
            <div className="top-heading-data">
              <div className="row">
                <div className="col-md-5">
                  <h5 className="mt-2">Requested Product</h5>
                  <p className="description-text">
                    Select product quantity and click button to request the
                    product
                  </p>
                </div>
                <div className="col-md-5 total-row">
                  <p className="total-text">Rquested By</p>
                  <div className="col-xl-9 col-lg-9">
                    <div className="inpt-wrap select-box ">
                      <select
                        className="text-capitalize"
                        name="product"
                        onChange={handleRquestedProduct}
                      >
                        {requestedProduct.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.u_name} (Req-{per.req_no})
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <button
                    onClick={handleRequestedProduct}
                    disabled={isLoading1}
                    className="btn btn-prim"
                  >
                    <i className="fa fa-spinner fa-spin" style={iocnStyle1}></i>
                    Request Product
                  </button>
                </div>
              </div>
            </div>
            {getSelectedRquestedProduct.length > 4 && (
              <div className="row text-end">
                <Link
                  to={`/user/requested/product/${request}`}
                  className=" mt-2"
                >
                  View All
                </Link>
              </div>
            )}
            <div className="booked-container-details mt-3">
              {getSelectedRquestedProduct.length > 0 &&
                getSelectedRquestedProduct.map((item, key) => (
                  <>
                    <div className="booked-container">
                      <div className="booked-data">
                        <h5>{item.p_name}</h5>
                        <p>{item.quantity}</p>

                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "0px",
                            }}
                          >
                            <button
                              onClick={() => handleDecrement2(item._id)}
                              className="btn btn-danger btn-decrement"
                              style={{ marginRight: "10px" }}
                            >
                              <i className="fa fa-minus" aria-hidden="true"></i>
                            </button>
                            <p className="product-counter">
                              {productQuantities1[item._id]
                                ? productQuantities1[item._id]
                                : item.quantity}
                            </p>
                            <button
                              onClick={() =>
                                handleIncrement2(item._id, item.quantity)
                              }
                              className="btn btn-primary btn-increment"
                              style={{ marginLeft: "10px" }}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            orderRquestedProduct(item._id, item.quantity)
                          }
                          className="btn btn-prim "
                        >
                          Order
                        </button>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {requestedSelectedNotFound && (
              <div className="row ">
                <div className="col-12">
                  <p className="text-center text-danger">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="container-wrap m-3">
        <div className="container-text">
          <h5>On Order</h5>
          <p className="description-text">
            List the orders request by the site admins
          </p>
          {onOrderProduct.length > 4 && (
            <div className="row text-end">
              <Link to={`/on/products/product/${getSite ? getSite : site}`}>
                View All
              </Link>
            </div>
          )}
          <div className="dashboard-table-view">
            <div className={styles.tables_wrap}>
              <div className="table-responsive">
                <table
                  cellPadding="10px"
                  className="table align-middle dashboard-table"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Product Name</th>
                      <th>QTY</th>
                      <th>Requested By</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading2 && (
                      <>
                        <tr>
                          <td
                            colSpan={6}
                            className="text-center"
                            style={{
                              background: "transparent",
                            }}
                          >
                            <HashLoader
                              color={`#0b0d41`}
                              loading={isLoading2}
                              size={40}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </td>
                        </tr>
                      </>
                    )}
                    {isDisplay &&
                      onOrderProduct.map((item, key) => (
                        <tr
                          className="row-data "
                          style={{ marginBottom: "10px" }}
                        >
                          <td></td>
                          <td className="text-capitalize">{item.name}</td>
                          <td>{item.quantity}</td>
                          <td className="text-capitalize">{item.user_name}</td>
                          <td>{formattedDate(item.date)}</td>
                          <td>
                            {item.status && item.status !== "" && (
                              <>
                                {item.status === "open" && (
                                  <span className="status success">OPEN</span>
                                )}
                                {item.status === "close" && (
                                  <span className="status error">CLOSE</span>
                                )}
                                {item.status === "order" && (
                                  <span className="status warning">ORDER</span>
                                )}
                              </>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    {onOrderdNotFound && (
                      <tr>
                        <td colSpan={6} className="text-center">
                          <p className="text-center text-danger">
                            No data found.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {role.toLowerCase() !== "site user" && (
        <div className="container-wrap m-3">
          <div className="container-text">
            <h5>Locker History</h5>
            <p className="description-text">
              Locker opening reason and history
            </p>
            {getLockerHistory.length > 4 && (
              <div className="row text-end">
                <Link
                  to={`/lockers/history/details/${getSite ? getSite : site}`}
                >
                  View All
                </Link>
              </div>
            )}
            <div className="dashboard-table-view">
              <div className={styles.tables_wrap}>
                <div className="table-responsive">
                  <table
                    cellPadding="10px"
                    className="table align-middle dashboard-table"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>User Name</th>
                        <th>Product</th>
                        <th>Site</th>
                        <th>Locker Number</th>
                        <th>QTY</th>
                        <th>Opening Reason</th>
                        <th>Opening Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading3 && (
                        <>
                          <tr>
                            <td
                              colSpan={6}
                              className="text-center"
                              style={{
                                background: "transparent",
                              }}
                            >
                              <HashLoader
                                color={`#0b0d41`}
                                loading={isLoading3}
                                size={40}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </td>
                          </tr>
                        </>
                      )}
                      {isDisplay1 &&
                        getLockerHistory.map((item, key) => (
                          <tr
                            className="row-data "
                            style={{ marginBottom: "10px" }}
                          >
                            <td></td>
                            <td className="text-capitalize">
                              {item.user_name}
                            </td>
                            <td className="text-capitalize">{item.name}</td>
                            <td className="text-capitalize">{item.s_name}</td>
                            <td className="text-capitalize">{item.locker}</td>
                            <td>{item.quantity}</td>
                            <td>{item.reason}</td>
                            <td>{formattedDate(item.date)}</td>
                            <td></td>
                          </tr>
                        ))}
                      {lockerHistoryNotFound && (
                        <tr>
                          <td colSpan={8} className="text-center">
                            <p className="text-center text-danger">
                              No data found.
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* </>
      )} */}
      {isAuthenticated.role.toLowerCase() == "site usersss" && (
        <>
          <div className="row mt-4">
            <div className="col-md-8">
              <div className="row">
                {/* col-3 */}
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Occupied <br />
                        Lockers
                      </p>
                      <h3 className={styles.card_head}>
                        {lockerCount?.occupied}
                      </h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                {/* col-3 */}
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Available <br /> Lockers
                      </p>
                      <h3 className={styles.card_head}>
                        {lockerCount?.available}
                      </h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Inactive <br /> Lockers
                      </p>
                      <h3 className={styles.card_head}>
                        {lockerCount?.inactive}
                      </h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className={styles.card_wrap}>
                    <div className={styles.card_cont}>
                      <p className={styles.card_text}>
                        Total <br />
                        Locker
                      </p>
                      <h3 className={styles.card_head}>{lockerCount?.total}</h3>
                    </div>
                    <div className={styles.card_icon}>
                      <img src="" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-4">
                          <img src={qrcodeImage} alt="qrimeg" id="qr-codepic" />
                        </div> */}
          </div>
          <div className="container-wrap mt-4">
            <div className="container-text">
              <h5>Container Added</h5>
            </div>
            {bookingLoading && (
              <div className="col-12 text-center">
                <HashLoader
                  color={`#0b0d41`}
                  loading={bookingLoading}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            {bookingDisplay && (
              <div className="dropbox-wrap">
                {bookings.map((item, i) => (
                  <div className="accordion mt-3" key={item._id}>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className={`accordion-button ${
                            isCollapsed ? "" : "active"
                          }`}
                          style={{ color: "#012267" }}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${item._id}`}
                          aria-expanded={true}
                          aria-controls={`collapseOne${item._id}`}
                        >
                          Booking #{i + 1}
                        </button>
                      </h2>
                      <div
                        id={`collapseOne${item._id}`}
                        className={`accordion-collapse collapse ${
                          isCollapsed === i ? "show" : ""
                        }`}
                        aria-labelledby={`heading${i}`}
                      >
                        <div className="accordion-body">
                          <div className="table-responsive">
                            <table className="table table-sm  table-hover mb-0 text-center">
                              <tbody>
                                <tr>
                                  <td>
                                    <h6>
                                      <b>Quantity</b>
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      <b>Item</b>
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      <b>User</b>
                                    </h6>
                                  </td>
                                  <td>
                                    <h6>
                                      <b>Location</b>
                                    </h6>
                                  </td>
                                </tr>
                                <tr>
                                  <td>{item.containers}</td>
                                  <td>
                                    <h6>Container</h6>
                                  </td>
                                  <td>
                                    <h6>{item.username}</h6>
                                  </td>
                                  <td>
                                    <h6>{item.sitename}</h6>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {bookingNotFound && (
              <div className="col-12 text-center">
                <p className="text-danger">No Data Found!</p>
              </div>
            )}
          </div>
          <div className="container-wrap">
            <div className="container-text mb-5">
              <h5>Lockers Statistic</h5>
            </div>
            <div className="container-fluided">
              {lockerSizes.length > 0 &&
                lockerSizes.map((item, i) => (
                  <div className="container-det">
                    <div className="container-fluid-text">
                      <h5>{item.size} Size Lockers</h5>
                      <h6>${item.hourly}/HOUR</h6>
                      <p>(Rented {item.hourly_booking} time)</p>
                      <h6>${item.daily}/DAILY</h6>
                      <p>(Rented {item.daily_booking} time)</p>
                      <h6>${item.monthly}/MONTHLY</h6>
                      <p>(Rented {item.monthly_booking} time)</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default Dashboard;
