import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../assets/icons/icon-back.svg'
import UploadIcon from '../../../images/icon-upload.png'
import { useSelector } from 'react-redux';
import { notification } from "antd";
import styles from './style.module.css';
import { Link, useNavigate, useParams } from "react-router-dom";
const LockerEdit = () => {
  const isAuthenticated = useSelector((state) => state.user);
  const { id } = useParams();
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [credentials, setCredentials] = useState({ number: "", size: "", row: "", column: "", site: "", relay: "", comments: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [getSite, setSite] = useState([]);
  const handleInput = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Access-Control-Allow-Credentials", "true");
    setIsLoading(true);
    const response = await fetch(
      `${url}/api/locker/update/${id}`,
      {
        mode: "cors",
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          number: credentials.number,
          size: credentials.size,
          site: credentials.site,
          row: credentials.row,
          column: credentials.column,
          relay: credentials.relay,
          comment: credentials.comments,
        }),
      }
    );
    setIsLoading(false);
    const json = await response.json();
    console.log(json)
    if (json.success) {
      notification.success({
        message: 'Success',
        description: 'Locked added Successfully',
        duration: 3
      });
    } else {
      setIsLoading(false);
      notification.error({
        message: 'Failed',
        description: json.error,
        duration: 3
      });
    }
  };
  useEffect(() => {
    getLocker(id);
  }, [id]);
  useEffect(() => {
    sizeData();
    siteData();
  }, []);
  const getLocker = async (id) => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      const response = await fetch(`${url}/api/locker/${id}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        console.log(json.locker);
        setCredentials({ ...json.locker });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const sizeData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/locker/size/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSizes(json.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const siteData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      headers.append("auth-token", localStorage.getItem("token"));
      const response = await fetch(`${url}/api/sites/list`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      const json = await response.json();
      if (json.success) {
        setSite(json.site_data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? 'sidebar active' : 'sidebar';
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <Link to="/lockers">
                  <div className="back-icon">
                    <img src={BackIcon} alt="" />
                  </div>
                </Link>
                <div className="user-back-det">
                  <h3>Edit Locker</h3>
                  <p>
                    Enter information to edit locker
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <form onSubmit={formSubmit}>
          <div className="form-box card-box">
            <div className="row">
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Locker No</h4>
                      <p>Enter the locker number</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="text"
                        className="form-control"
                        id="sitec"
                        placeholder="LS-001"
                        name="number"
                        value={credentials.number}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Size</h4>
                      <p>Select size for the locker</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="size" value={credentials.size} onChange={handleInput} required  >
                        <option value="">Choose size</option>
                        {sizes.map((per) => (
                          <option key={per._id} value={per._id} style={{ textTransform: "capitalize" }}>
                            {per.size}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>row</h4>
                      <p>Enter number of rows for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="sitec"
                        placeholder=""
                        name="row"
                        value={credentials.row}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Column</h4>
                      <p>Enter number of column for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="sitec"
                        placeholder=""
                        name="column"
                        value={credentials.column}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4> site</h4>
                      <p>Select the site for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <select name="site_id" value={credentials.site} onChange={handleInput} required>
                        <option value="" selected>Choose Site</option>
                        {getSite.map((per) => (
                          <option key={per._id} value={per._id}>
                            {per.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Relay</h4>
                      <p>Enter relay for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <input
                        type="number"
                        className="form-control"
                        id="sitec"
                        placeholder=""
                        name="relay"
                        value={credentials.relay}
                        onChange={handleInput}
                        required
                      />
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-6">
                {/* input row */}
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="label-wrap">
                      <h4>Comment</h4>
                      <p>Enter comment for locker</p>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7">
                    <div className="inpt-wrap">
                      <textarea name="comments" id="" cols="30" rows="7" value={credentials.comments} onChange={handleInput}>
                      </textarea>
                    </div>
                  </div>
                </div>
                {/* input row */}
              </div>
              <div className="col-lg-12 text-end">
                <div className="form-btn-wrap mb-5 pb-5">
                  <button type="submit" className="btn btn-prim w-auto ms-5" disabled={isLoading}>
                    {isLoading ? 'updating...' : 'Update Locker'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LockerEdit;
