import React, { useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import BackIcon from '../../../assets/icons/icon-back.svg'
import styles from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";
import { notification } from "antd";
function Label() {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [file, setFile] = useState(null);
  const [fromToData, setFromToData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(true);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  const handleUpload = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      formData.append("file", file);
      setIsLoading(true);
      const response = await fetch(`${url}/api/ocr_labels/uploadLabelOcr`, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json.success) {
        setForm(false);
        setIsLoading(true);
        setFromToData(json.html);
      } else {
        setForm(true);
        setIsLoading(false);
        notification.error({
          message: 'Failed',
          description: json.error,
          duration: 3
        });
      }
      // Handle the response from the server (contains "From" and "To" data)
    } catch (error) {
      console.error(error);
    }
  };
  const iocnStyle = {
    display: isLoading ? "inline-block" : "none",
  };
  const [isActive, setIsActive] = useState(false);
  // Function to toggle the active state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // Define the CSS classes based on the isActive state
  const divClassName = isActive ? "sidebar active" : "sidebar";
  return (
    <div className="general-dashboard">
      <div className="container-fluid">
        <div className="dashboard-top-row add-new-top-row">
          <div className="row">
            <div className="col-lg-6 my-auto">
              <div className="user-wrap user-back-wrap">
                <div className="user-back-det">
                  <h3>Upload Shipping</h3>
                  <p>Upload the shipping label image</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end"></div>
          </div>
        </div>
        {/* row */}
        <div className="form-box card-box">
          {form ? (
            <form onSubmit={handleUpload}>
              <div className="row">
                <div className="col-lg-12">
                  {/* input row */}
                  <div className="row">
                    <div className="col-xl-4 col-lg-4">
                      <div className="label-wrap">
                        <h4>image</h4>
                        <p>Select shipping label image</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                      <div className="inpt-wrap">
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleFileChange}
                          placeholder="Enter QR code name.."
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {/* input row */}
                </div>
                <div className="col-lg-12 text-end">
                  <div className="form-btn-wrap mb-5 pb-5">
                    <button
                      type="submit"
                      disabled={isLoading}
                      href=""
                      className="btn btn-prim w-auto ms-5"
                    >
                      <i
                        className="fa fa-spinner fa-spin"
                        style={iocnStyle}
                      ></i>{" "}
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="form-btn-wrap mb-5 pb-5">
                  {fromToData != "" ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: fromToData,
                      }}
                    />
                  ) : (
                    <HashLoader
                      color={`#0b0d41`}
                      loading={isLoading}
                      size={40}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Label;
