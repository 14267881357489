import { Link, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/Authentication";
import styles from "./style.module.css";
import IconUser from "../../assets/icons/icon-user.svg";
import IconDashboard from "../../assets/icons/icon-dashboard.svg";
import IconAdmins from "../../assets/icons/icon-admins.svg";
import IconAppoint from "../../assets/icons/icon-appoint.svg";
import IconCmera from "../../assets/icons/icon-camera.svg";
import IconChat from "../../assets/icons/icon-chat.svg";
import IconDoors from "../../assets/icons/icon-doors.svg";
import IconEmployees from "../../assets/icons/icon-employess.svg";
import IconFinder from "../../assets/icons/icon-finder.svg";
import IconMedia from "../../assets/icons/icon-media.png";
import IconQR from "../../assets/icons/icon-qr.svg";
import IconRoute from "../../assets/icons/icon-route.svg";
import IconScn from "../../assets/icons/icon-scan.svg";
import IconScreens from "../../assets/icons/icon-screens.svg";
import IconSite from "../../assets/icons/icon-site.svg";
import IconSiteQR from "../../assets/icons/icon-site-qr.svg";
import IconShippingLabel from "../../assets/icons/icon-shipping.svg";
import IconLockers from "../../assets/icons/icon-lockers.svg";
import IconLogout from "../../assets/icons/logout.svg";
import IconContent from "../../assets/icons/icon-content.svg";
import IconDropOff from "../../assets/icons/icon-dropoff-booking.svg";
import IconPayement from "../../assets/icons/icon-payement.svg";
import IconBookingList from "../../assets/icons/icon-bookinglist.svg";
import IconLockersBank from "../../assets/icons/icon-lockerbank.svg";
import IconPickup from "../../assets/icons/icon-pickups.svg";
import IconPickupOrder from "../../assets/icons/icon-pickuporder.svg";
import Injection from "../../assets/icons/icon-injection.svg";

import Logo from "../../images/logo.svg";

import { NavLink } from "react-router-dom";
import "./partials.css";
const Sidebar = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user);
  // const imageUrl = "/assets/images/logo/logo-fast-lobby-blue.png";
  // const imageUrl1 = "/assets/images/logo/icon-fast-lobby.png";
  const handleUserLogout = async (e) => {
    const id = localStorage.getItem("token");
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/");
  };
  useEffect(() => {
    // Clean up the event listener when the component unmounts
  }, []);
  return (
    <div>
      <section className="sidebar_wrap">
        <div className="logo-wrap text-center">
          <img src={Logo} alt="logo" className="logo-sidebar-wrap" />
        </div>
        <ul>
          <li>
            <NavLink to="/dashboard">
              <img src={IconDashboard} alt="icon" />
              <span>Dashboard</span>
            </NavLink>
          </li>
          {(isAuthenticated.role.toLowerCase() === "site admin" ||
            isAuthenticated.role.toLowerCase() === "super admin" ||
            isAuthenticated.role.toLowerCase() === "company admin") && (
            <li>
              <NavLink to="/user/list">
                <img src={IconUser} alt="icon" />
                <span>Users</span>
              </NavLink>
            </li>
          )}
          {isAuthenticated.role.toLowerCase() === "super admin" && (
            <>
              <li>
                <NavLink to="/site/list">
                  <img src={IconSite} alt="icon" />
                  <span>Sites</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/company/list">
                  <img src={IconDoors} alt="icon" />
                  <span>Company</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/qr/type/list">
                  <img src={IconQR} alt="icon" />
                  <span>QR code types</span>
                </NavLink>
              </li>
            </>
          )}

          <li>
            <NavLink to="/lockers">
              <img src={IconLockers} alt="icon" />
              <span>Lockers</span>
            </NavLink>
          </li>

          {(isAuthenticated.role.toLowerCase() === "site admin" ||
            isAuthenticated.role.toLowerCase() === "company admin") && (
            <>
              <li>
                <NavLink to="/locker/bank">
                  <img src={IconLockersBank} alt="icon" />
                  <span>Locker Bank</span>
                </NavLink>
              </li>
            </>
          )}
          {isAuthenticated.role.toLowerCase() !== "company admin" && (
            <>
              {/* <li>
                <NavLink to="/requested/product/list">
                  <img src={Injection} alt="icon" />
                  <span>Requested Products</span>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/product/list">
                  <img src={Injection} alt="icon" />
                  <span>Products</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/shipping/label/list">
                  <img src={IconShippingLabel} alt="icon" />
                  <span>Shipping Labels</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/live/shipping/tracking">
                  <img src={IconShippingLabel} alt="icon" />
                  <span>Shipping tracking</span>
                </NavLink>
              </li>
            </>
          )}
          {isAuthenticated.role.toLowerCase() === "user" && (
            <>
              <li>
                <NavLink to="/pricing">
                  <img src={IconDoors} alt="icon" />
                  <span>pricing</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/booking/add">
                  <img src={IconScn} alt="icon" />
                  <span>Book A Locker</span>
                </NavLink>
              </li>
            </>
          )}
          {/* 
          <li>
            <NavLink to="/booking/list" exact>
              <img src={IconBookingList} alt="icon" />
              <span>
                {isAuthenticated.role.toLowerCase() === "user" ? "Your" : ""}{" "}
                booking list
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/payment/list" exact>
              <img src={IconPayement} alt="icon" />
              <span>
                {isAuthenticated.role.toLowerCase() === "user" ? "Your" : ""}{" "}
                payment list
              </span>
            </NavLink>
          </li> */}

          {isAuthenticated.role.toLowerCase() === "site admin" && (
            <>
              <li>
                <NavLink to="/site/qr/codes/list">
                  <img src={IconSiteQR} alt="icon" />
                  <span>Site QR Codes</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/label">
                  <img src={IconFinder} alt="icon" />
                  <span>Label OCR</span>
                </NavLink>
              </li>
            </>
          )}
          {(isAuthenticated.role.toLowerCase() === "site admin" ||
            isAuthenticated.role.toLowerCase() === "company admin") && (
            <>
              <li>
                <NavLink to="/media/contents" exact>
                  <img src={IconMedia} alt="icon" />
                  <span>Media Content</span>
                </NavLink>
              </li>
            </>
          )}
          <li>
            <Link onClick={handleUserLogout} exact>
              <img src={IconLogout} alt="icon" />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Sidebar;
