import React, { useEffect, useState } from "react";
import Header from "../../partials/Header";
import Sidebar from "../../partials/Sidebar";
import SearchIcon from "../../../assets/icons/search.svg";
import FilterIcon from '../../../images/filter.png'
import NextIcon from '../../../images/next.png'
import PreviousIcon from '../../../images/prev.png'
import QrIconTable from '../../../images/icon-qr-tb.png'
import styles from './style.module.css'
import ProfileImg from '../../../images/profile.png'
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Button, Popconfirm, message } from 'antd';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { motion } from "framer-motion";
import HashLoader from "react-spinners/ClipLoader";

const TypesList = () => {
  const url = process.env.REACT_APP_APP_BACK_URL;
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({});
  const isAuthenticated = useSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);



  useEffect(() => {
    fetchData();
  }, [currentPage]);
  const fetchData = async () => {
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Access-Control-Allow-Origin", "*");
      headers.append("Access-Control-Allow-Credentials", "true");
      setIsLoading(true);
      setIsDisplay(false);
      setIsNotFound(false);

      const response = await fetch(`${url}/api/company/getCompanyList?page=${currentPage}&limit=${itemsPerPage}`, {
        mode: "cors",
        method: "GET",
        headers: headers,
      });
      setIsLoading(false);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      if(json.companies.length > 0 ){
        setIsDisplay(true);
        setData(json.companies);

      }else{
        setIsNotFound(true);
      }
      setTotalPages(json.totalPages);

    } catch (error) {
      console.error(error);
    }
  };



  const handleDelete = async (id) => {
    try {

      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel',
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        setConfirmLoading(true);

        let headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Access-Control-Allow-Credentials', 'true');

        const response = await fetch(`${url}/api/company/delete/${id}`, {
          mode: 'cors',
          method: 'DELETE',
          headers: headers,
        });

        if (response.ok) {
          setConfirmLoading(false);
          fetchData(); // Refresh the records list after successful deletion
          Swal.fire(
            'Deleted!',
            'Company deleted successfully!',
            'success'
          );
        } else {
          setConfirmLoading(false);
          Swal.fire(
            'Error',
            'An error occurred while deleting the record.',
            'error'
          );
        }
      }
    } catch (error) {
      console.log('Error deleting record:', error);
      setConfirmLoading(false);
      Swal.fire(
        'Error',
        'An error occurred while deleting the record.',
        'error'
      );
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1); // react-paginate uses zero-based indexing
  };
  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };



  return (
    <div className="general-dashboard">
    <div className="container-fluid">
      <div className="dashboard-top-row">
        <div className="row">
          <div className="col-lg-6 my-auto">
            <div className="user-wrap">
              <h3>Companies</h3>
              <p>List of all the companies in the system</p>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="filter-right-sec">
              <Link to="/add/company" className='btn btn-prim w-auto'>Add New Company</Link>
            </div>
          </div>
        </div>
      </div>
      {/* row */}
      <div className="table-wrap">
        <div className="row">
          <div className="col-12">
            <div className={styles.card_box}>
              <div className={styles.tables_wrap}>
                <div className="table-responsive">
                  <table className="table align-middle">
                    <thead>
                      <tr>
                        <th>Short Name</th>
                        <th>Full Name</th>
                        <th>created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <>
                          <tr>
                            <td colSpan={6} className="text-center" style={{background:"transparent"}}>
                            <HashLoader
                              color={`#0b0d41`}
                              loading={isLoading}
                              size={40}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                            </td>
                          </tr>
                        </>
                      )}
                      {isDisplay &&
                        data.map((item, i) => (
                          <motion.tr
                            key={item._id}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 1.2, delay: i * 0.1 }}
                          >
                            <td>
                              <div className="usr_det_tb">
                                
                                <span> {item.short_name}</span>
                              </div>
                            </td>
                            <td>
                              <div className="usr_det_tb">
                              
                                <span> {item.full_name}</span>
                              </div>
                            </td>
                            <td>{formattedDate(item.createdAt)}</td>
                            <td className="text-end">
                              <Link to={`/edit/company/${item._id}`}>
                                <button type="button" className="btn btn-edit tb-btn">
                                  Edit
                                </button>
                              </Link>
                              <button type="button" className="btn btn-delete tb-btn" onClick={() => handleDelete(item._id)}>
                                Remove
                              </button>
                            </td>
                          </motion.tr>

                        ))
                      }
                      {isNotFound && (
                          <tr>
                            <td colSpan={3} className="text-center">No Data Found</td>
                          </tr>
                        )
                      }

                    </tbody>
                  </table>
                </div>
                <div className="table-num">
                  {data.length > 0 && (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      breakLabel={'...'}
                      pageCount={totalPages}
                      forcePage={currentPage - 1} // react-paginate uses zero-based indexing
                      onPageChange={handlePageChange}
                      containerClassName={' dig-num'}
                      pageClassName={'dig-num'}
                      previousClassName={'num-btns'}
                      nextClassName={'num-btns'}
                      disabledClassName={'pagination-disabled'}
                      activeClassName={'pagination-active'}
                    />
                  )}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default TypesList;
